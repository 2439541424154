import { createContext, useEffect, useState } from "react"
import ensureVarIsArray from "../util/ensureVarIsArray"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { toast } from "react-toastify"
import useAxios from "../hooks/useAxios"
import { useNavigate } from "react-router-dom"

export const VenueContext = createContext<VenueContextTypes>({
	addStage: () => null,
	removeStage: () => null,
	isLoading: false,
	selectedStage: null,
	selectedVenue: null,
	setSelectedStage: () => null,
	setSelectedVenue: () => null,
	stageSelectorData: [],
	venues: [],
	userCanViewVenues: true,
})

const LOCAL_STORAGE_SELECTED_VENUE_KEY = "mv-selected-venue"
const LOCAL_STORAGE_SELECTED_STAGE_KEY = "mv-selected-stage"

export default function VenueContextProvider({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element {
	const [selectedVenue, setSelectedVenue] = useState<Venue | null>(null)
	const [selectedStage, setSelectedStage] = useState<Stage | null>(null)
	const { token } = localStorage
	const axios = useAxios()
	const { t } = useTranslation()
	const navigate = useNavigate()
	const addStage = (stage: Stage) => {
		if (!selectedVenue) {
			return
		}
		setSelectedVenue({
			...selectedVenue,
			stages: [...selectedVenue.stages, stage].sort(((a: Stage, b: Stage) => a.name.toString().localeCompare(b.name.toString())), // need to sort this aphabetically
		)})
	}
	const removeStage = (stageId: string) => {
		if (!selectedVenue) {
			return
		}

		setSelectedVenue({
			...selectedVenue,
			stages: selectedVenue.stages.filter(s => s.id !== stageId),
		})
	}

	// Refetch when logging in to make sure token is present
	const { isLoading, data = { data: { data: [] } } } = useQuery(
		["venues", token],
		async () => {
			return await axios.get("/venues/v1/venues")
		},
		{
			onError: (error: string) => {
				toast.error(t("errorTryAgain"))
			},
			enabled: !!token,
		},
	)

	const venues: Venue[] = (data?.data?.data || [])
		.map((v: Venue) => ({
			...v,
			stages: v.stages.sort((a: Stage, b: Stage) => a.name.toString().localeCompare(b.name.toString())),
			contacts: ensureVarIsArray(v.contacts),
			previousNames: ensureVarIsArray(v.previousNames),
			urls: ensureVarIsArray(v.urls),
			notes: ensureVarIsArray(v.notes),
		}))
		.sort((a: Venue, b: Venue) => a.name.toString().localeCompare(b.name.toString()))

	//set initial selectedVenue | selectedStage
	useEffect(() => {
		if (!venues.length) {
			return
		}

		//check for a stored selected venue + stage id
		const previouslySelectedVenueID = localStorage.getItem(LOCAL_STORAGE_SELECTED_VENUE_KEY)
		const previouslySelectedStageID = localStorage.getItem(LOCAL_STORAGE_SELECTED_STAGE_KEY)

		if (previouslySelectedVenueID && previouslySelectedStageID) {
			const newSelectedVenue = venues.find(v => v.id === previouslySelectedVenueID)

			if (newSelectedVenue) {
				const newSelectedStage = newSelectedVenue?.stages.find(
					s => s.id === previouslySelectedStageID,
				)

				if (newSelectedStage) {
					setSelectedVenue(newSelectedVenue)
					setSelectedStage(newSelectedStage)
					return
				}
			}
		}

		//or use first available venue + stage if available
		const firstVenue = venues[0]
		if (firstVenue.id && firstVenue.stages?.length > 0) {
			setSelectedVenue(firstVenue)
			setSelectedStage(firstVenue.stages[0])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [venues.length])

	const stageSelectorData = venues.reduce((acc: any, venue: Venue) => {
		venue.stages.forEach(stage => {
			acc.push({
				...stage,
				name: `${venue.name} - ${stage.name}`,
			})
		})
		return acc
	}, [] as SelectorData[])

	//temporary workaround while we await user.permissions
	//might make sense to leave here and set to something like userCanViewVenues = user.permissions.venues?.includes("view")
	const userCanViewVenues = venues.length > 0

	//if a user lands on this page with no associated venues then send them home
	useEffect(() => {
		if (
			!isLoading &&
			!userCanViewVenues &&
			["/staff-crew", "/production", "/venueStage"].some(page =>
				window.location.href.includes(page),
			)
		) {
			navigate("/")
		}
	}, [isLoading, userCanViewVenues, navigate])

	useEffect(() => {
		if (selectedStage) {
			localStorage.setItem(LOCAL_STORAGE_SELECTED_VENUE_KEY, selectedVenue?.id || "")
			localStorage.setItem(LOCAL_STORAGE_SELECTED_STAGE_KEY, selectedStage.id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStage])

	return (
		<VenueContext.Provider
			value={{
				addStage,
				removeStage,
				isLoading,
				selectedStage,
				selectedVenue,
				setSelectedStage,
				setSelectedVenue,
				stageSelectorData,
				venues,
				userCanViewVenues,
			}}
		>
			{children}
		</VenueContext.Provider>
	)
}
