import { FormLabel } from "@mui/material"
import { TextareaAutosizeProps } from "@mui/material/TextareaAutosize"
import TextArea from "../atoms/TextArea"
import styled from "styled-components"

interface IProps extends TextareaAutosizeProps {
	label?: string
}

const TextAreaWithLabel: React.FC<IProps> = ({ label = "", ...props }) => (
	<StyledTextAreaContainer>
		{!!label && <FormLabel>{label}</FormLabel>}
		<TextArea {...props} />
	</StyledTextAreaContainer>
)

const StyledTextAreaContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
	& label {
		color: ${({ theme }) => theme.text};
		font-size: ${({ theme }) => theme.shrunkLabelFontSize};
	}
`

export default TextAreaWithLabel
