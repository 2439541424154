import HoverSvg from "../components/atoms/HoverSvg"

const Production = ({ color, size }: IconTypes) => {
	const height = size * 1.33333
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 23.5C0 22.7031 0.65625 22 1.5 22H4.03125C4.64062 20.6875 5.95312 19.75 7.5 19.75C9 19.75 10.3594 20.6875 10.9219 22H22.5C23.2969 22 24 22.7031 24 23.5C24 24.3438 23.2969 25 22.5 25H10.9219C10.3594 26.3594 9 27.25 7.5 27.25C5.95312 27.25 4.64062 26.3594 4.03125 25H1.5C0.65625 25 0 24.3438 0 23.5ZM6 23.5C6 24.3438 6.65625 25 7.5 25C8.29688 25 9 24.3438 9 23.5C9 22.7031 8.29688 22 7.5 22C6.65625 22 6 22.7031 6 23.5ZM15 16C15 16.8438 15.6562 17.5 16.5 17.5C17.2969 17.5 18 16.8438 18 16C18 15.2031 17.2969 14.5 16.5 14.5C15.6562 14.5 15 15.2031 15 16ZM16.5 12.25C18 12.25 19.3594 13.1875 19.9219 14.5H22.5C23.2969 14.5 24 15.2031 24 16C24 16.8438 23.2969 17.5 22.5 17.5H19.9219C19.3594 18.8594 18 19.75 16.5 19.75C14.9531 19.75 13.6406 18.8594 13.0312 17.5H1.5C0.65625 17.5 0 16.8438 0 16C0 15.2031 0.65625 14.5 1.5 14.5H13.0312C13.6406 13.1875 14.9531 12.25 16.5 12.25ZM9 10C9.79688 10 10.5 9.34375 10.5 8.5C10.5 7.70312 9.79688 7 9 7C8.15625 7 7.5 7.70312 7.5 8.5C7.5 9.34375 8.15625 10 9 10ZM12.4219 7H22.5C23.2969 7 24 7.70312 24 8.5C24 9.34375 23.2969 10 22.5 10H12.4219C11.8594 11.3594 10.5 12.25 9 12.25C7.45312 12.25 6.14062 11.3594 5.53125 10H1.5C0.65625 10 0 9.34375 0 8.5C0 7.70312 0.65625 7 1.5 7H5.53125C6.14062 5.6875 7.45312 4.75 9 4.75C10.5 4.75 11.8594 5.6875 12.4219 7Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Production)
