import { useState, useRef } from "react"
import { closestCenter, DndContext, DragOverlay, PointerSensor, useSensor } from "@dnd-kit/core"
import { SortableContext, arrayMove, rectSortingStrategy } from "@dnd-kit/sortable"
import { SortableGridItem } from "./GridItem"

// adapted from https://codesandbox.io/s/react-dnd-kit-swapping-test-forked-imkxc?file=/src/Grid/Grid.jsx

export default function Grid({
	className,
	items,
	setItems,
	unsortableItems,
	afterSort,
	Component = SortableGridItem,
}) {
	const sensors = [useSensor(PointerSensor)]
	const [activeIndex, setActiveIndex] = useState(null)
	const containerRef = useRef(null)
	return (
		<div className={className} ref={containerRef}>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragStart={({ active, ...props }) => {
					const index = items.findIndex(item => item.id === active.id)
					setActiveIndex(index)
				}}
				onDragEnd={({ active, over, ...props }) => {
					if (active && over && active.id !== over?.id) {
						setItems(itms => {
							const activeIndex = itms.findIndex(item => item.id === active.id)
							const overIndex = itms.findIndex(item => item.id === over.id)
							
							return arrayMove(itms, activeIndex, overIndex).map((a, order) => ({
								...a,
								metadata: { ...a.metadata, order },
							}))
						})
						afterSort()
					}
					setActiveIndex(null)
				}}
				onDragCancel={() => setActiveIndex(null)}
			>
				{unsortableItems}
				<SortableContext items={items.map(item => item.id)} strategy={rectSortingStrategy}>
					{items.map((item, itemIndex) => (
						<Component
							key={item.id}
							item={item}
							// styling for card in list that is actively being dragged
							styles={activeIndex === itemIndex ? { opacity: "0.5" } : {}}
						/>
					))}
				</SortableContext>
				<DragOverlay>
					{activeIndex != null ? <Component item={items[activeIndex]} /> : null}
				</DragOverlay>
			</DndContext>
		</div>
	)
}
