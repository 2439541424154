import HoverSvg from "../components/atoms/HoverSvg"

const PdfFile = ({ color, size }: IconTypes) => {
	const height = size
	const width = size * 1.03125

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 32 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 4.44556C0 2.25806 1.75 0.445557 4 0.445557H14V8.44556C14 9.57056 14.875 10.4456 16 10.4456H24V19.4456H11C8.75 19.4456 7 21.2581 7 23.4456V32.4456H4C1.75 32.4456 0 30.6956 0 28.4456V4.44556ZM24 8.44556H16V0.445557L24 8.44556ZM11 22.4456H13C14.875 22.4456 16.5 24.0706 16.5 25.9456C16.5 27.8831 14.875 29.4456 13 29.4456H12V31.4456C12 32.0081 11.5 32.4456 11 32.4456C10.4375 32.4456 10 32.0081 10 31.4456V28.4456V23.4456C10 22.9456 10.4375 22.4456 11 22.4456ZM13 27.4456C13.8125 27.4456 14.5 26.8206 14.5 25.9456C14.5 25.1331 13.8125 24.4456 13 24.4456H12V27.4456H13ZM19 22.4456H21C22.625 22.4456 24 23.8206 24 25.4456V29.4456C24 31.1331 22.625 32.4456 21 32.4456H19C18.4375 32.4456 18 32.0081 18 31.4456V23.4456C18 22.9456 18.4375 22.4456 19 22.4456ZM21 30.4456C21.5 30.4456 22 30.0081 22 29.4456V25.4456C22 24.9456 21.5 24.4456 21 24.4456H20V30.4456H21ZM26 23.4456C26 22.9456 26.4375 22.4456 27 22.4456H30C30.5 22.4456 31 22.9456 31 23.4456C31 24.0081 30.5 24.4456 30 24.4456H28V26.4456H30C30.5 26.4456 31 26.9456 31 27.4456C31 28.0081 30.5 28.4456 30 28.4456H28V31.4456C28 32.0081 27.5 32.4456 27 32.4456C26.4375 32.4456 26 32.0081 26 31.4456V27.4456V23.4456Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(PdfFile)
