const statesAndTerritories = [
	"AL",
	"AK",
	"AZ",
	"AR",
	"CA",
	"CO",
	"CT",
	"DE",
	"FL",
	"GA",
	"HI",
	"ID",
	"IL",
	"IN",
	"IA",
	"KS",
	"KY",
	"LA",
	"ME",
	"MD",
	"MA",
	"MI",
	"MN",
	"MS",
	"MO",
	"MT",
	"NE",
	"NV",
	"NH",
	"NJ",
	"NM",
	"NY",
	"NC",
	"ND",
	"OH",
	"OK",
	"OR",
	"PA",
	"RI",
	"SC",
	"SD",
	"TN",
	"TX",
	"UT",
	"VT",
	"VA",
	"WA",
	"WV",
	"WI",
	"WY",
	"AS",
	"GU",
	"MP",
	"PR",
	"VI",
	"UM",
	"DC",
	"AB",
	"BC",
	"MB",
	"NB",
	"NL",
	"NT",
	"NS",
	"NU",
	"ON",
	"PE",
	"QC",
	"SK",
	"YT",
	"ACT",
	"NSW",
	"NT",
	"QLD",
	"SA",
	"TAS",
	"VIC",
	"WA",
	"BW",
	"BY",
	"BE",
	"BB",
	"HB",
	"HH",
	"HE",
	"NI",
	"MV",
	"NW",
	"RP",
	"SL",
	"SN",
	"ST",
	"SH",
	"TH",
	"B",
	"K",
	"NO",
	"OO",
	"S",
	"ST",
	"T",
	"V",
	"W",
	"AN",
	"AR",
	"AS",
	"IB",
	"PV",
	"CN",
	"CB",
	"CL",
	"CM",
	"CT",
	"EX",
	"GA",
	"RI",
	"MD",
	"MC",
	"NC",
	"VC",
	"ZH",
	"BE",
	"LU",
	"UR",
	"SZ",
	"OW",
	"NW",
	"GL",
	"ZG",
	"FR",
	"SO",
	"BS",
	"BL",
	"SH",
	"AR",
	"AI",
	"SG",
	"GR",
	"AG",
	"TG",
	"TI",
	"VD",
	"VS",
	"NE",
	"GE",
	"JU",
	"65",
	"23",
	"75",
	"77",
	"78",
	"72",
	"45",
	"36",
	"62",
	"42",
	"25",
	"57",
	"67",
	"21",
	"88",
	"82",
	"52",
	"32",
	"55",
	"34"
]

export default statesAndTerritories
