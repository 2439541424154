import { FC, useState, useEffect } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import styled from "styled-components"
import mediaQuery from "../../../util/mediaQuery"
import FormField from "../../atoms/FormField"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import useAxios from "../../../hooks/useAxios"
import compare from "../../../util/compare"
import useConfirmClose from "../../../hooks/useConfirmClose"
import useVenues from "../../../hooks/useVenues"
import Card from "../../atoms/Card"
import Button from "../../atoms/Button"
import { isEntryInvalid } from "../../../util/formValidation"

interface StageComponentTypes {
	setDeletedId: (deletedId: string) => void
	stage: Stage
	stages: Array<Stage>
}

const Stage: FC<StageComponentTypes> = ({ setDeletedId, stage, stages }) => {
	const [stageName, setStageName] = useState(stage.name)
	const [isEqual, setIsEqual] = useState(true)
	const { t } = useTranslation()
	const queryClient = useQueryClient()
	const axios = useAxios()
	const { shouldPreventClose, setShouldPreventClose } = useConfirmClose()

	useEffect(() => {
		const compareEqual = compare({ name: stage.name || false }, { name: stageName })
		setIsEqual(compareEqual)
	}, [stage.name, stageName])

	useEffect(() => {
		if (!isEqual && !shouldPreventClose) {
			setShouldPreventClose(true)
		}
	}, [isEqual, setShouldPreventClose, shouldPreventClose])
	const { selectedVenue } = useVenues()

	const handleStageUpdate = async () => {
		return await axios.patch(`/venues/v1/venue-stages/${stage.id}`, {
			name: stageName,
		})
	}

	const { mutate } = useMutation(handleStageUpdate, {
		onError: () => {
			toast.error(t("tryAgain"))
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["venues"])
			toast.success(t("completed"))
			setIsEqual(true)
			setShouldPreventClose(false)
		},
	})

	return (
		<StageCard data-cy="venueStage-Card-Container">
			<FormField
				data-cy={`venueStage-Card-StageName-${stageName}`}
				error={isEntryInvalid(stageName)}
				variant="standard"
				label={t("stageName")}
				id={`stageName${stage.id}`}
				fullWidth
				required
				value={stageName}
				onChange={e => setStageName(e.target.value)}
			/>
			<BottomContainer>
				<ButtonContainer>
					<Button
						data-cy={`venueStage-Card-UpdateStageButton-${stageName}`}
						variant="contained"
						disabled={isEqual || isEntryInvalid(stageName)}
						onClick={() => mutate()}
					>
						{t("update")}
					</Button>
					{selectedVenue!.stages.length > 1 ? (
						<DeleteButtonContainer>
							<Button data-cy={`venueStage-Card-DeleteStageButton-${stageName}`} onClick={() => setDeletedId(stage.id)}>{t("delete")}</Button>
						</DeleteButtonContainer>
					) : null}
				</ButtonContainer>
			</BottomContainer>
		</StageCard>
	)
}

const StageCard = styled(Card)`
	padding: 1rem 3rem 2rem !important;
	margin-bottom: 3rem;
`
const BottomContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;

	${mediaQuery("md")`
		flex-direction: column;
	`}
`
const ButtonContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;

	${mediaQuery("md")`
		margin-top: 2rem;
	`}
`
const DeleteButtonContainer = styled.div`
	margin-left: 2rem;
`

export default Stage
