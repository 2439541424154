import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Button from "../atoms/Button"
import Card from "../atoms/Card"
import { useState, useEffect } from "react"
import mediaQuery from "../../util/mediaQuery"
import { toast } from "react-toastify"
import AddStageModal from "../organisms/stages/AddStageModal"
import DeleteStageModal from "../organisms/stages/DeleteStageModal"
import useVenues from "../../hooks/useVenues"
import Stage from "../organisms/stages/Stage"

const Stages = () => {
	const { t } = useTranslation()
	const [showAddModal, setShowAddModal] = useState(false)
	const [deletedId, setDeletedId] = useState("")
	const [newStageName, setNewStageName] = useState("")
	const { selectedVenue, setSelectedStage, addStage, removeStage } = useVenues()
	const [stages, setStages] = useState(selectedVenue?.stages ?? [])

	const handleAddStage = (newStage: any) => {
		setShowAddModal(false)
		setNewStageName("")
		setStages([...stages, newStage])
		addStage(newStage)
		toast.success(t("addStageSuccess"))
	}

	const handleDelete = () => {
		setDeletedId("")
		// Filter out the deleted stage
		const filteredStages = stages.filter(stage => stage.id !== deletedId)
		setStages(filteredStages)
		toast.success(t("deleteStageSuccess"))
		removeStage(deletedId)
		// Handle if the deleted stage was the selected stage
		if (filteredStages.length > 0) {
			setSelectedStage(filteredStages[0])
		}
	}

	// Re-render the stages when a new venue is selected from dropdown
	useEffect(() => {
		setStages(selectedVenue?.stages ?? [])
	}, [selectedVenue])

	const RenderVenueStages = () => {
		if (stages.length) {
			return stages.map(stage => (
				<Stage stages={stages} stage={stage} setDeletedId={setDeletedId} key={stage.id} />
			))
		} else {
			return (
				<NoStagesContainer>
					<p>{t("none")}</p>
				</NoStagesContainer>
			)
		}
	}

	return (
		<Container>
			{RenderVenueStages()}
			<AddButtonContainer>
				<Button data-cy="venueStage-AddStageButton" onClick={() => setShowAddModal(true)} disabled={!selectedVenue?.id}>
					{t("addNewStage")}
				</Button>
			</AddButtonContainer>
			<AddStageModal
				open={showAddModal}
				handleSubmit={handleAddStage}
				handleClose={() => {
					setShowAddModal(false)
					setNewStageName("")
				}}
				newStageName={newStageName}
				setNewStageName={setNewStageName}
			/>
			<DeleteStageModal
				deletedId={deletedId}
				handleClose={() => setDeletedId("")}
				handleSubmit={handleDelete}
				open={deletedId !== ""}
			/>
		</Container>
	)
}

const Container = styled.div`
	margin-top: 4rem;
	${mediaQuery("md")`
		padding: 3rem, 5rem;
	`}
`
const AddButtonContainer = styled.div`
	margin-top: 2rem;
	${mediaQuery("md")`
		display: flex;
		justify-content: center;
	`}
`
const NoStagesContainer = styled(Card)`
	padding: 2rem;
`

export default Stages
