import attachmentDisplayName from "../../../util/attachmentDisplayName"
import mediaQuery from "../../../util/mediaQuery"
import styled, { useTheme } from "styled-components"
import PdfFile from "../../../icons/PdfFile"
import ImageFile from "../../../icons/ImageFile"
import VideoFile from "../../../icons/VideoFile"
import File from "../../../icons/File"
import VideoPlayer from "../../atoms/VideoPlayer"
import useWindowDimensions from "../../../hooks/useWindowDimensions"

const ShowPreview = ({ attachment }: { attachment: Attachment }) => {
	const { width: screenWidth } = useWindowDimensions()
	const theme = useTheme()

	const getFileIcon = (item: Attachment) => {
		// This is reused in AttachmentRow, need to extract
		const ext = item.file?.filename?.split(".").pop() || ""
		// use ext to return file icon
		let size = 128
		if (screenWidth <= 550) {
			size = 50
		} else if (screenWidth <= 787) {
			size = 80
		}
		switch (ext.toLowerCase()) {
			case "pdf":
				return <PdfFile size={size} color={theme.techPackIcon} />
			case "jpg":
			case "jpeg":
			case "png":
			case "gif":
			case "svg":
			case "webp":
				return <ImageFile size={size} color={theme.techPackIcon} />
			case "mp4":
			case "mov":
			case "avi":
			case "wmv":
			case "flv":
			case "mkv":
			case "webm":
				return <VideoFile size={size} color={theme.techPackIcon} />
			default:
				return <File size={size} color={theme.techPackIcon} />
		}
	}

	const requestFullScreen = (element: HTMLElement) => {
		if (element.requestFullscreen) {
			element.requestFullscreen()
		} else if ((element as any).mozRequestFullScreen) {
			/* Firefox */
			;(element as any).mozRequestFullScreen()
		} else if ((element as any).webkitRequestFullscreen) {
			/* Chrome, Safari & Opera */
			;(element as any).webkitRequestFullscreen()
		} else if ((element as any).msRequestFullscreen) {
			/* IE/Edge */
			;(element as any).msRequestFullscreen()
		}
	}

	const { file } = attachment
	const fileName = file?.filename || ""
	const isImage = fileName.toLowerCase().match(/\.(jpeg|jpg|gif|png|svg|webp)$/)
	const isVideo = fileName.toLowerCase().match(/\.(mp4|mov|avi|wmv|flv|mkv|webm)$/)
	const fileUrl = file?.publicUrls?.url
	const fileIsReady = file?.status?.streaming === "Complete"
	const videoUrl = file?.publicUrls?.streaming?.url

	if (isImage && fileUrl) {
		return (
			<ImageOverflow>
				<StyledImage
					src={fileUrl}
					alt={attachmentDisplayName(file, false)}
					onClick={() =>
						requestFullScreen(
							document.querySelector(
								'img[alt="' + attachmentDisplayName(file, false) + '"]',
							) as HTMLElement,
						)
					}
				/>
			</ImageOverflow>
		)
	} else if (isVideo && videoUrl && fileIsReady) {
		return (
			<VideoContainer
				key={file.id}
				onClick={() => {
					const videoElement = document.querySelector(`video`)
					if (videoElement) {
						requestFullScreen(videoElement)
					}
				}}
			>
				<VideoPlayer file={file} />
			</VideoContainer>
		)
	} else {
		return <IconContainer>{getFileIcon(attachment)}</IconContainer>
	}
}

const ImageOverflow = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	border-radius: 10px;
	height: 100%;
	width: 100%;
	border: 3px solid #545f70;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

	${mediaQuery("md")`
		margin-left: 15px;
		margin-right: 15px;
	`}
`
const StyledImage = styled.img`
	border-radius: 10px;
	max-height: 100%;
	max-width: 100%;
	object-fit: contain;
	cursor: pointer;
`
const VideoContainer = styled.div`
	height: 100%;
	width: 100%;
	border: 2px solid #545f70;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
`
const IconContainer = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.techPackHeaderButtonBg};
	display: flex;
	justify-content: center;
	border-radius: 10px;
	height: 100%;
	width: 100%;
	border: 2px solid #545f70;
	box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

	${mediaQuery("md")`
		margin-left: 15px;
		margin-right: 15px;
	`}
`

export default ShowPreview
