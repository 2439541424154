import { createRoot } from "react-dom/client"
import "./index.css"
import Root from "./Root"

const rootEl = document.getElementById("root")

if (rootEl === null) throw new Error("Root container missing in index.html")

const root = createRoot(rootEl)
root.render(<Root />)
