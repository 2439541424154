import {
	createContext,
	useEffect,
	useRef,
	useContext,
	useState,
	SetStateAction,
	Dispatch,
} from "react"
import { useMediaPredicate } from "react-media-hook"
import useLocalStorage from "../hooks/useLocalStorage"

export interface DarkModeContextType {
	darkMode: boolean
	setDarkMode: Dispatch<SetStateAction<boolean>>
}

export const DarkModeContext = createContext<DarkModeContextType>({
	darkMode: true,
	setDarkMode: () => null,
})

export const useDarkMode = () => useContext(DarkModeContext)

export default function DarkModeProvider({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element {
	const prefersDark = useMediaPredicate("(prefers-color-scheme: dark)")
	const [savedPreferDark, setPreferDark] = useLocalStorage<boolean>("darkMode", prefersDark)
	const [darkMode, setDarkMode] = useState(savedPreferDark)
	const firstRun = useRef(true)
	useEffect(() => {
		if (firstRun.current) {
			firstRun.current = false
			return
		}
		document.documentElement.style.setProperty("color-scheme", prefersDark ? "dark" : "light")
		setDarkMode(prefersDark)
		setPreferDark(prefersDark)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [prefersDark])
	useEffect(() => {
		document.documentElement.style.setProperty("color-scheme", darkMode ? "dark" : "light")
		setPreferDark(darkMode)
	}, [darkMode, setPreferDark])
	return (
		<DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
			{children}
		</DarkModeContext.Provider>
	)
}
