import HoverSvg from "../components/atoms/HoverSvg"

const Facility = ({ color, size }: IconTypes) => {
	const height = size * 1.777778
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 18V4H4V0H14V8H18V18H10V14H8V18H0ZM2 16H4V14H2V16ZM2 12H4V10H2V12ZM2 8H4V6H2V8ZM6 12H8V10H6V12ZM6 8H8V6H6V8ZM6 4H8V2H6V4ZM10 12H12V10H10V12ZM10 8H12V6H10V8ZM10 4H12V2H10V4ZM14 16H16V14H14V16ZM14 12H16V10H14V12Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Facility)
