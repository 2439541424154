import { DialogTitle } from "@mui/material"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Card from "../atoms/Card"
import { FC, useState, Dispatch, SetStateAction, MouseEventHandler, useEffect } from "react"
import Button from "../atoms/Button"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import AddEditStaffModal from "../organisms/staff/AddEditStaffModal"
import DeleteStaffModal from "../organisms/staff/DeleteStaffModal"
import mediaQuery from "../../util/mediaQuery"
import usePersonnel from "../../hooks/usePersonnel"
import { Personnel, PersonnelContextProvider } from "../../context/personnel"
import useAxios from "../../hooks/useAxios"
import { useMutation } from "@tanstack/react-query"
import LoadingPage from "./LoadingPage"
import { toast } from "react-toastify"
import useGetVenues from "../../hooks/useVenues"
import AvatarImage from "../molecules/AvatarImage"
import SaveButtonDrawer from "../molecules/SaveButtonDrawer"
import Grid from "../molecules/sorting/Grid"
import { SortableGridItem } from "../molecules/sorting/GridItem"

const StaffList = () => {
	const { t } = useTranslation()
	const [showAdd, setShowAdd] = useState(false)
	const [staffMember, setStaffMember] = useState<Personnel>()
	const { personnel, isLoading, refetch, shouldPreventClose, setShouldPreventClose } =
		usePersonnel()
	const axios = useAxios()
	const { selectedVenue } = useGetVenues()
	const [venuePersonnel, setVenuePersonnel] = useState<Personnel[]>([])

	useEffect(() => {
		setVenuePersonnel(personnel)
	}, [personnel])

	const { mutate: addUpdateMutate } = useMutation(
		async (person: {
			role: string
			name: string
			email: string
			phone: string
			id?: string
			includedInTechPack: boolean
		}) => {
			const data = {
				attachedToType: "Venue",
				attachedToId: selectedVenue!.id,
				type: "general",
				role: person.role,
				metadata: {
					includedInTechPack: person.includedInTechPack,
					order: personnel.length,
				},
				person: {
					email: person.email,
					name: {
						displayName: person.name,
					},
					phone: person.phone,
				},
			}
			if (person.id) {
				return await axios.patch(`venues/v1/personnel/${person.id}`, data)
			}
			return await axios.post("/venues/v1/personnel", data)
		},
		{
			onError: () => {
				toast.success(t("tryAgain"))
			},
			onSuccess: () => {
				//if sorting has happened, also save new order
				if (
					JSON.stringify(personnel.map(p => p.metadata?.order) || "") !==
					JSON.stringify(venuePersonnel.map(p => p.metadata?.order || ""))
				) {
					bulkUpdateMutate()
				} else {
					//else close out ui save actions that got picked up in bulkUpdateMutate
					refetch()
					setShouldPreventClose(false)
					toast.success(t("updateSuccessful"))
				}

				setStaffMember(undefined)
				setShowAdd(false)
			},
		},
	)

	const { mutate: deleteMutate } = useMutation(
		() => {
			return axios.delete(`/venues/v1/personnel/${staffMember?.id}`)
		},
		{
			onError: () => {
				toast.success(t("tryAgain"))
			},
			onSuccess: () => {
				refetch()
				setShouldPreventClose(false)
				setStaffMember(undefined)
				setShowAdd(false)
				toast.success(t("updateSuccessful"))
			},
		},
	)

	const { mutate: bulkUpdateMutate, isLoading: bulkUpdateLoading } = useMutation(
		() => {
			const data = venuePersonnel.map(({ id, metadata }) => ({ id, metadata }))
			return axios.post("/venues/v1/personnel/update", data)
		},
		{
			onError: () => {
				toast.success(t("tryAgain"))
			},
			onSuccess: () => {
				refetch()
				setShouldPreventClose(false)
				toast.success(t("updateSuccessful"))
			},
		},
	)

	const sortedPersonnel = venuePersonnel.sort(
		(a, b) => (a.metadata.order || 0) - (b.metadata.order || 0),
	)

	return (
		<LoadingPage isLoading={isLoading}>
			<Container
				className=""
				unsortableItems={[
					<AddButtonContainer key="add-venue-staff">
						<Button
							data-cy="staffCrew-addUserButton"
							disabled={!selectedVenue?.id}
							onClick={() => setShowAdd(true)}
						>
							{t("addVenueStaff")}
						</Button>
					</AddButtonContainer>,
				]}
				items={sortedPersonnel.map(staff => ({
					id: staff.id,
					child: (
						<StaffCard
							staff={staff}
							setShowEdit={() => {
								setStaffMember(staff)
								setShowAdd(true)
							}}
							setStaffMember={setStaffMember}
						/>
					),
					aut_hooks_name: `${staff.person.name.displayName}`,
				}))}
				setItems={setVenuePersonnel}
				afterSort={() => {
					if (!shouldPreventClose) {
						setShouldPreventClose(true)
					}
				}}
				Component={StyledSortableGridItem}
			/>
			<AddEditStaffModal
				showModal={showAdd}
				onSubmit={addUpdateMutate}
				onClose={() => {
					setShowAdd(false)
					setStaffMember(undefined)
				}}
				staff={staffMember}
			/>
			<DeleteStaffModal
				showModal={Boolean(staffMember) && !showAdd}
				onSubmit={deleteMutate}
				onClose={() => {
					setStaffMember(undefined)
				}}
				staff={staffMember}
			/>
			<SaveButtonDrawer
				show={shouldPreventClose}
				onSave={bulkUpdateMutate}
				disableButton={bulkUpdateLoading}
			/>
		</LoadingPage>
	)
}

const StaffCard: FC<{
	staff: Personnel
	setShowEdit: MouseEventHandler<HTMLDivElement>
	setStaffMember: Dispatch<SetStateAction<any>>
}> = ({ staff, setShowEdit, setStaffMember }) => {
	const { t } = useTranslation()

	// const permissionsPicker = Math.random() < 0.5 ? t("noPermission_plural") : t("administrator")
	// const [permission] = useState(permissionsPicker)

	return (
		<StaffCardContainer
			data-cy={"StaffCrew-Card-Container"}
			data-cy-cardname={staff.person.name.displayName}
		>
			<IconContainer>
				<div onClick={setShowEdit}>
					<StyledIcon
						data-cy={"StaffCrew-editUserButton-" + staff.person.name.displayName}
						icon="pencil"
					/>
				</div>
				<div
					onClick={() => {
						setStaffMember(staff)
					}}
				>
					<StyledIcon
						data-cy={"StaffCrew-deleteUserButton-" + staff.person.name.displayName}
						icon="trash-can"
					/>
				</div>
			</IconContainer>
			<Title data-cy={"StaffCrew-Card-Title-" + staff.person.name.displayName}>{staff.role}</Title>
			<AvatarImage
				data-cy={"StaffCrew-Card-AvatarImage-" + staff.person.name.displayName}
				staff={staff}
				height={50}
				width={50}
			/>
			<NameContainer data-cy={"StaffCrew-Card-Name-" + staff.person.name.displayName}>
				{staff.person.name.displayName}
			</NameContainer>
			<PhoneContainer data-cy={"StaffCrew-Card-Phone-" + staff.person.name.displayName}>
				{t("phone")}: <a href={`tel:${staff.person.phone}`}>{staff.person.phone}</a>
			</PhoneContainer>
			<EmailContainer data-cy={"StaffCrew-Card-Email-" + staff.person.name.displayName}>
				{t("email")}: <a href={`mailto:${staff.person.email}`}>{staff.person.email}</a>
			</EmailContainer>
			{/* <PermissionContainer>{permission}</PermissionContainer> */}
		</StaffCardContainer>
	)
}

const Staff = () => {
	return (
		<PersonnelContextProvider>
			<StaffList />
		</PersonnelContextProvider>
	)
}

// const PermissionContainer = styled.div`
// 	font-size: 0.9rem;
// 	margin-top: 0.5rem;
// `

const Container = styled(Grid)`
	margin-top: 4rem;
	align-items: stretch;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	gap: 2rem;
	width: 100%;

	${mediaQuery("lg")`
		align-items: center;
		flex-direction: column !important;
	`}
`
const AddButtonContainer = styled(Card)`
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	min-height: 270px;
	padding: 2rem;
	position: relative;
	width: 350px;
	max-width: 90vw;

	${mediaQuery("lg")`
		height: 100px;
		width: 100%;
	`}
`
const StyledSortableGridItem = styled(SortableGridItem)`
	${mediaQuery("lg")`
		width: 100%;
	`}
`
const StaffCardContainer = styled(Card)`
	align-items: center;
	display: flex;
	flex-direction: column;
	padding: 1.2rem 1rem 1rem;
	position: relative;
	min-height: 270px;
	width: 350px;
	max-width: 90vw;
	text-align: center;
	${mediaQuery("lg")`
		width: 100%;
	`}
`
const IconContainer = styled.div`
	display: flex;
	gap: 1rem;
	position: absolute;
	top: 1rem;
	right: 1rem;
`
const StyledIcon = styled(FontAwesomeIcon)`
	cursor: pointer;
	color: ${({ theme }) => theme.primaryColor};
	font-size: 1rem;
`
const Title = styled(DialogTitle)`
	font-family: "RobotoCondensed-Bold" !important;
`
const NameContainer = styled.div`
	font-family: "Roboto-Bold" !important;
	font-size: 1rem;
	margin-bottom: 1rem;
`
const PhoneContainer = styled.div`
	margin-bottom: 1rem;
	word-wrap: break-word;
	word-break: break-all;
`
const EmailContainer = styled.div`
	margin-bottom: 1rem;
	word-wrap: break-word;
	word-break: break-all;
`

export default Staff
