import useGetVenues from "../../../hooks/useVenues"
import Modal from "../../molecules/Modal"
import { FC, useEffect, useState, useRef, DragEvent } from "react"
import { useTranslation } from "react-i18next"
import { Badge, Checkbox, DialogTitle, FormControlLabel } from "@mui/material"
import styled from "styled-components"
// import Select from "../../atoms/Select"
import FormField from "../../atoms/FormField"
import mediaQuery from "../../../util/mediaQuery"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import ImageCropper from "../../molecules/ImageCropper"
import { Personnel } from "../../../context/personnel"
import imageTypes from "../../../util/constants/IMAGE_TYPES"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { toast } from "react-toastify"
import useAxios from "../../../hooks/useAxios"
import getCroppedImg from "../../../util/cropImage"
import AvatarImage from "../../molecules/AvatarImage"
import useDarkMode from "../../../hooks/useDarkMode"
import { isEntryInvalid } from "../../../util/formValidation"
import compare from "../../../util/compare"

interface ImageDataType {
	width: number
	height: number
	x: number
	y: number
}

interface Types {
	showModal: boolean
	onSubmit: (arg: {
		email: string
		phone: string
		name: string
		role: string
		id?: string
		includedInTechPack: boolean
	}) => void
	onClose: () => void
	staff?: Personnel
}

const AddEditStaffModal: FC<Types> = ({ showModal, onSubmit, onClose, staff }) => {
	const [role, setRole] = useState("")
	const [phone, setPhone] = useState("")
	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [includedInTechPack, setincludedInTechPack] = useState(true)
	const { selectedVenue } = useGetVenues()
	const { t } = useTranslation()
	const imageInputRef = useRef<HTMLInputElement>(null)
	const titleInputRef = useRef<HTMLInputElement>(null)
	const nameInputRef = useRef<HTMLInputElement>(null)
	const [file, setFile] = useState<File>()
	const [filePath, setFilePath] = useState("")
	const [dragActive, setDragActive] = useState(false)
	const axios = useAxios()
	const { darkMode } = useDarkMode()
	const queryClient = useQueryClient()
	const [selected, setSelected] = useState("No Permissions")
	// const selectionChangeHandler = (e: any) => {
	// 	setSelected(e.target.value)
	// }

	useEffect(() => {
		if (email.length === 0) {
			setSelected("No Permissions")
		}
	}, [email])

	const isTitleNameInvalid = isEntryInvalid(name) || isEntryInvalid(role)

	const handleClose = () => {
		onClose()
	}

	const [hasChanges, setHasChanges] = useState(false)

	useEffect(() => {
		// on edit, hasChanges is true if any of the fields have been changed
		setHasChanges(
			compare(
				{
					role,
					phone,
					name,
					email,
					includedInTechPack,
				},
				{
					role: staff?.role || "",
					phone: staff?.person.phone || "",
					name: staff?.person.name.displayName || "",
					email: staff?.person.email || "",
					includedInTechPack: staff?.metadata?.includedInTechPack,
				},
			),
		)
	}, [role, phone, name, email, includedInTechPack, staff])

	useEffect(() => {
		const { role, person, metadata } = staff || {
			role: "",
			person: { phone: "", name: { displayName: "" }, email: "" },
			metadata: { includedInTechPack: true },
		}
		setRole(role || "")
		setPhone(person.phone || "")
		setName(person.name.displayName || "")
		setEmail(person.email || "")
		setincludedInTechPack(metadata?.includedInTechPack)
	}, [staff])

	const isEdit = staff && Object.keys(staff).length > 0
	const submitText = isEdit ? "update" : "addVenueStaff"
	const titleText = isEdit
		? t("editContactDetails")
		: t("addVenueStaffTo", {
				destination: selectedVenue?.name || "",
				interpolation: { escapeValue: false },
		  })

	const showFileDialog = () => {
		imageInputRef?.current?.click()
	}

	const handleDrag = (e: DragEvent) => {
		e.preventDefault()
		e.stopPropagation()
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true)
		} else if (e.type === "dragleave") {
			setDragActive(false)
		}
	}

	const handleDrop = (e: any) => {
		e.preventDefault()
		e.stopPropagation()
		setDragActive(false)
		setFile(undefined)
		handlePhotoUpdate(e)
	}

	const handlePhotoUpdate = async (event: any) => {
		// click item
		const fileObject = event.target?.files?.[0]
		const fileType = fileObject?.type
		// drag/drop item
		const dragFileObject = event.dataTransfer?.files?.[0]
		const dragFileType = dragFileObject?.type
		if (!fileObject && !dragFileObject) {
			// something happened
			return
		}
		if (fileObject && !imageTypes.includes(fileType)) {
			// have click item but not an image
			return
		}
		if (dragFileObject && !imageTypes.includes(dragFileType)) {
			// have drag/drop item but not an image
			return
		}
		var path = (window.URL || window.webkitURL).createObjectURL(fileObject || dragFileObject)
		setFilePath(path)
		setFile(fileObject || dragFileObject)
	}

	const handleSubmit = () => {
		if (!role) {
			return titleInputRef?.current?.focus()
		}
		if (!name) {
			return nameInputRef?.current?.focus()
		}
		const data = {
			role,
			email,
			name,
			phone,
			id: staff?.id || "",
			includedInTechPack,
		}
		onSubmit(data)
		setRole("")
		setEmail("")
		setName("")
		setPhone("")
	}

	const { mutate: getUrl, isLoading: isGettingUrl } = useMutation(
		async () => {
			return await axios.post("/venues/v1/files", {
				filename: file?.name,
				attachedToType: "Personnel",
				attachedToId: staff?.id,
				replaceExisting: true,
			})
		},
		{
			onError: () => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: async (res, { data }: { data: ImageDataType }) => {
				const { id, presignedUrl } = res.data.data
				upload({ presignedUrl, id, data: data })
			},
		},
	)

	const { mutate: upload, isLoading: isUploadingFile } = useMutation(
		async ({
			presignedUrl,
			id,
			data,
		}: {
			presignedUrl: string
			id: string
			data: ImageDataType
		}) => {
			const imageToUpload = await getCroppedImg(filePath, data, 0)
			return await fetch(presignedUrl, {
				body: imageToUpload,
				method: "PUT",
			})
		},
		{
			onError: () => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: async (res, params: { id: string }) => {
				updateFile(params.id)
			},
		},
	)

	const { mutate: updateFile, isLoading: isUpdatingFile } = useMutation(
		async (id: string) => {
			return await axios.patch(`/venues/v1/files/${id}`, {
				disk: "s3",
			})
		},
		{
			onError: () => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: (res, id: string) => {
				attachFile(id)
			},
		},
	)

	const { mutate: attachFile, isLoading: isAttachingFile } = useMutation(
		async (id: string) => {
			return await axios.post(`/venues/v1/files/${id}/attach`, {
				attachedToType: "Personnel",
				attachedToId: staff?.id,
			})
		},
		{
			onError: () => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: () => {
				queryClient.invalidateQueries([`getUrl${staff?.id}`])
				if (imageInputRef?.current) {
					// clear file input
					imageInputRef.current.value = ""
				}
				setFilePath("")
				setFile(undefined)
			},
		},
	)

	if (!selectedVenue) {
		return null
	}

	return (
		<Modal
			open={showModal}
			onClose={handleClose}
			onSubmit={handleSubmit}
			submitText={t(submitText)}
			showCancel={false}
			maxWidth={"1200px"}
			submitButtonColor={"primary"}
			submitButtonDisabled={hasChanges || isTitleNameInvalid}
			isAddModal={showModal && selected === "Administrator"}
		>
			<CenterContainer>
				<Container>
					<TitleContainer>
						<StyledTitle>{titleText}</StyledTitle>
					</TitleContainer>
					{isEdit && (
						<BadgeContainer>
							<StyledBadge
								badgeContent={<CameraAltIcon />}
								overlap="circular"
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "right",
								}}
								sx={{
									cursor: "pointer",
								}}
								onClick={showFileDialog}
								onDragEnter={handleDrag}
								onDragLeave={handleDrag}
								onDragOver={handleDrag}
								onDrop={handleDrop}
							>
								{dragActive ? (
									<CloudUploadIcon sx={{ fontSize: "70px" }} />
								) : (
									<AvatarImage
										data-cy="staffCrewModal_UserAvatar"
										staff={staff}
										height={100}
										width={100}
									/>
								)}
								<input
									ref={imageInputRef}
									type="file"
									style={{ display: "none" }}
									accept="image/*"
									onChange={handlePhotoUpdate}
								/>
							</StyledBadge>
						</BadgeContainer>
					)}
					<InputContainer>
						<StyledField
							data-cy="staffCrewModal-TitleField"
							error={isEntryInvalid(role)}
							variant="standard"
							label={t("title")}
							required
							fullWidth
							value={role}
							onChange={e => setRole(e.target.value)}
							ref={titleInputRef}
							autoFocus={!role}
						/>
						<StyledField
							data-cy="staffCrewModal-NameField"
							error={isEntryInvalid(name)}
							variant="standard"
							label={t("name")}
							required
							fullWidth
							value={name}
							onChange={e => setName(e.target.value)}
							ref={nameInputRef}
						/>
						<StyledField
							data-cy="staffCrewModal-PhoneNumberField"
							variant="standard"
							label={t("phoneNumber")}
							fullWidth
							value={phone}
							onChange={e => setPhone(e.target.value)}
						/>
						<StyledField
							data-cy="staffCrewModal-EmailField"
							variant="standard"
							label={t("email")}
							fullWidth
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
					</InputContainer>
					<SelectContainer>
						<StyledControlLabel
							value="start"
							control={
								<Checkbox
									data-cy="staffCrewModal-TechPackToggle"
									checked={includedInTechPack}
									onChange={e => setincludedInTechPack(e.target.checked)}
									sx={{ color: darkMode ? "white" : "black" }}
								/>
							}
							label={t("includeInTechPack")}
							labelPlacement="start"
						/>
						{/* {!isEntryInvalid(email) ? (
							<StyledFormControl>
								<StyledSelect
									selectedColor={darkMode ? "white" : "black"}
									selected={selected}
									items={[
										{ id: "No Permissions", name: t("noPermission_plural") },
										{ id: "Administrator", name: t("administrator") },
									]}
									onChange={selectionChangeHandler}
								/>
							</StyledFormControl>
						) : null} */}
					</SelectContainer>
				</Container>
			</CenterContainer>
			{file && (
				<ImageCropper
					open={Boolean(filePath)}
					handleSaveFile={getUrl}
					handleClose={() => {
						if (imageInputRef?.current) {
							// clear file input
							imageInputRef.current.value = ""
						}
						setFilePath("")
					}}
					filePath={filePath}
					isLoading={isUploadingFile || isAttachingFile || isGettingUrl || isUpdatingFile}
					data-cy="staffCrewModal_UserAvatar"
				/>
			)}
		</Modal>
	)
}
const CenterContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
`
const Container = styled.div`
	width: 600px;

	${mediaQuery("lg")`
		width: 100%;
	`}
`
const TitleContainer = styled.div`
	text-align: center;
	margin-bottom: 1rem;
`
const StyledTitle = styled(DialogTitle)``
const BadgeContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`
const StyledBadge = styled(Badge)`
	& .MuiSvgIcon-root {
		margin-left: 10px;
		margin-top: 5px;
	}
	margin-bottom: 2rem;
`
const InputContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 2rem;
	width: 100%;

	${mediaQuery("lg")`
		flex-direction: column !important;
	`}
`

const SelectContainer = styled.div`
	display: flex;
	flex-direction: row;
	${mediaQuery("sm")`
		flex-direction: column !important;
		align-items: center !important;
	`}
`
const StyledField = styled(FormField)`
	width: calc(50% - 2rem) !important;
	${mediaQuery("lg")`
		width: 100% !important;
	`}
`
const StyledControlLabel = styled(FormControlLabel)`
	margin-left: 0 !important;
	margin-top: 2rem;
	${mediaQuery("sm")`
		justify-content: start !important;
	`}
`

// const StyledFormControl = styled(FormControl)`
// 	margin-top: 2rem;
// `

// const StyledSelect = styled(Select)`
// 	width: 175px;
// 	margin-top: 2rem;
// 	margin-left: 7rem;
// 	font-size: 1rem !important;
// 	font-weight: bold;
// 	background-color: ${props => props.theme.background} !important;
// 	color: ${({ theme }) => theme.text} !important;

// 	> svg {
// 		color: ${props => props.theme.text} !important;
// 	}
// 	${mediaQuery("sm")`
// 		margin-left: 0 !important;
// 		margin-top: 1rem;
// 	`}
// `

export default AddEditStaffModal
