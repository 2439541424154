export const getSection = argument => {
	switch (argument) {
		case "stageWings":
		case "paBays":
		case "orchestraPit":
		case "curtain":
		case "fireCurtain":
		case "steps":
		case "trimHeight":
		case "apron":
		case "deckToGrid":
		case "legs":
		case "borders":
		case "risers":
		case "stage":
			return "staging"
		case "access":
		case "dockType":
		case "deadCase":
		case "forklifts":
		case "loadComments":
			return "loading"
		case "flySystem":
		case "lineSets":
		case "riggingComments":
			return "rigging"
		case "audioPower":
		case "lightingPower":
		case "otherPower":
		case "showPower":
		case "distToStage":
		case "powerComments":
			return "power"
		case "houseLights":
		case "climateControl":
		case "fog":
		case "pyro":
		case "co2":
			return "show"
		case "fohPosition":
		case "snakeRun":
		case "barricade":
		case "roof":
		case "spots":
		case "venueSeats":
			return "house"
		case "dressingRooms":
		case "facilityNotes":
		case "offices":
		case "cateringLocale":
		case "cateringBreakfast":
		case "cateringLunch":
		case "cateringDinner":
		case "breakfast":
		case "lunch":
		case "dinner":
		case "rehearsalSpace":
		case "showers":
		case "security":
		case "merchandisers":
		case "hallMerchPercent":
		case "towels":
		case "laundry":
		case "runners":
			return "facility"
		case "truckParking":
		case "busParking":
		case "shorePower":
		case "guestParking":
		case "parkingComments":
			return "parking"
		case "wifiSsid":
		case "username":
		case "password":
		case "circuit":
		case "ip":
		case "wanIP":
		case "subnetMask":
		case "gateway":
		case "dns":
		case "isp":
		case "fees":
		case "officeComments":
			return "informationTech"
		default:
			return ""
	}
}

export const fieldNamesData = [
	{
		mainCats: "production",
		subCats: [
			{
				name: "staging",
				fields: [
					"stageWings",
					"paBays",
					"orchestraPit",
					"curtain",
					"fireCurtain",
					"steps",
					"trimHeight",
					"apron",
					"deckToGrid",
					"legs",
					"borders",
					"risers",
					"stage",
				],
			},
			{
				name: "loading",
				fields: ["access", "dockType", "deadCase", "forklifts", "loadComments"],
			},
			{
				name: "rigging",
				fields: ["flySystem", "lineSets", "riggingComments"],
			},
			{
				name: "power",
				fields: [
					"audioPower",
					"lightingPower",
					"otherPower",
					"showPower",
					"distToStage",
					"powerComments",
				],
			},
			{
				name: "show",
				fields: ["houseLights", "climateControl", "fog", "pyro", "co2"],
			},
			{
				name: "house",
				fields: ["fohPosition", "snakeRun", "barricade", "roof", "spots", "venueSeats"],
			},
		],
	},
	{
		mainCats: "facilities",
		subCats: [
			{
				name: "facility",
				fields: [
					"dressingRooms",
					"facilityNotes",
					"offices",
					"cateringLocale",
					"cateringBreakfast",
					"cateringLunch",
					"cateringDinner",
					"breakfast",
					"lunch",
					"dinner",
					"rehearsalSpace",
					"showers",
					"security",
					"merchandisers",
					"hallMerchPercent",
					"towels",
					"laundry",
					"runners",
				],
			},
			{
				name: "parking",
				fields: ["truckParking", "busParking", "shorePower", "guestParking", "parkingComments"],
			},
			{
				name: "informationTech",
				fields: [
					"wifiSsid",
					"username",
					"password",
					"circuit",
					"ip",
					"wanIP",
					"subnetMask",
					"gateway",
					"dns",
					"isp",
					"fees",
					"officeComments",
				],
			},
		],
	},
	{
		mainCats: "equipment",
		subCats: [
			{
				name: "",
				fields: [
					"audio",
					"audioComments",
					"staging",
					"lighting",
					"lightComments",
					"backline",
					"video",
					"videoComments",
					"other",
				],
			},
		],
	},
	{
		mainCats: "logistics",
		subCats: [
			{
				name: "",
				fields: [
					"airportNotes",
					"groundTransport",
					"areaBusiness",
					"areaRestaurants",
					"areaHotels",
					"interest",
					"closestCity",
					"directions",
				],
			},
		],
	},
	{
		mainCats: "localCrew",
		subCats: [
			{
				name: "",
				fields: ["localUnion", "minimumIN", "minimumOUT", "penalties", "crewComments"],
			},
		],
	},
]
