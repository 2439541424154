const DEFAULT_STAGE: Stage = {
	id: "",
	venueId: "",
	name: "",
	description: "",
	metadata: {
		includedInTechPack: false,
	},
	productionData: {},
	facilitiesData: {},
	equipmentData: {},
	localCrewData: {},
	logisticsData: {},
}

export default DEFAULT_STAGE
