import React, { useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { IconButton, TextareaAutosizeProps, TextFieldProps, Tooltip } from "@mui/material"
import TextAreaWithLabel from "../../molecules/TextAreaWithLabel"
import mediaQuery from "../../../util/mediaQuery"
import FormField from "../../atoms/FormField"
import Accordion from "../../molecules/Accordion"
import Paper from "../../atoms/Paper"
import useProduction from "../../../hooks/useProduction"
import StyledIcon from "../../atoms/StyledIcon"
import AddAttachmentModal from "./AddAttachmentModal"
import EditAttachmentModal from "./EditAttachmentModal"
import useDebounce from "../../../hooks/useDebounce"
import TimeStamp from "../../molecules/TimeStamp"
import { extractTimeStampData } from "../../../util/extractTimeStampData"
import ProductionAttachmentRow from "./ProductionAttachmentRow"

const StageFormField: React.FC<(TextFieldProps | TextareaAutosizeProps) & any> = ({
	data,
	dataCategory,
	isTextArea,
	placeholder = "",
	...props
}: {
	data: string
	dataCategory:
		| "productionData"
		| "facilitiesData"
		| "equipmentData"
		| "logisticsData"
		| "localCrewData"
	isTextArea: boolean
	placeholder?: string
}) => {
	const { t } = useTranslation()
	const { stageFieldChanges } = useProduction()
	const {
		state: { stage, attachments, venue },
		setState,
	} = useProduction()

	const label = t(data)
	const category = stage[dataCategory] || {}
	const [value, setValue] = useState(category[data])

	const onChange = () => {
		if (value === stage[dataCategory][data]) {
			return
		}

		setState((s: ProductionStateInterface) => ({
			...s,
			stage: {
				...s.stage,
				[dataCategory]: {
					...(s.stage[dataCategory] || {}),
					[data]: value,
				},
			},
		}))
	}
	const theseAttachments: Attachment[] = (attachments[dataCategory] || {})[data] || []

	const handleFileClick = (id: string) => 
		setState((s: ProductionStateInterface) => ({
			...s,
			editingAttachment: {
				data,
				dataCategory,
				selectedIndex: theseAttachments.findIndex(att => att.id === id),
			},
		}))


	const openAddAttachmentModal = () =>
		setState((s: ProductionStateInterface) => ({ ...s, addingAttachment: { data, dataCategory } }))

	useDebounce(value, onChange, 500)
	return (
		<StyledStageFormFieldContainer>
			<div className="input-container">
				<Tooltip title={t("addAttachment")}>
					{/* this span is a workaround for a warning from Tooltip->IconButton[disabled] */}
					<span>
						<IconButton onClick={openAddAttachmentModal} disabled={!venue?.id}>
							<StyledIcon className="add-icon" icon="square-plus" />
						</IconButton>
					</span>
				</Tooltip>
				{isTextArea ? (
					<TextAreaWithLabel
						{...{ ...props, label, placeholder: "" }}
						value={value || ""}
						minRows={2}
						onChange={e => setValue(e.target.value)}
					/>
				) : (
					<FormField
						{...{ ...props, label }}
						value={value || ""}
						onChange={e => setValue(e.target.value)}
						multiline
					/>
				)}
			</div>
			<TimeStamp
				{...extractTimeStampData(`${dataCategory}.${data}`, stageFieldChanges, "venueStage")}
				marginLeft={48}
				marginTop={-7}
			/>
			<ProductionAttachmentRow
				category={dataCategory}
				field={data}
				handleFileClick={handleFileClick}
			/>
		</StyledStageFormFieldContainer>
	)
}

const StyledStageFormFieldContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	& .input-container {
		display: flex;
		gap: 1rem;
		align-items: start;

		& button {
			margin-top: 1.25rem;
		}

		& :nth-child(2) {
			width: 100%;
		}
	}

	& .add-icon {
		width: 1rem;
		height: 1rem;
	}

	& .files-wrapper {
		display: flex;
		gap: 1rem;
		align-items: center;
		justify-content: start;
	}

	& .files-row {
		display: flex;
		gap: 1rem;
		align-items: end;
	}

	& .files-container {
		display: flex;
		overflow-x: auto;
		justify-content: start;
		align-items: start;
	}

	& .files-container svg {
		width: 3rem;
		height: 3rem;
	}

	& .files-container {
		& img {
			height: auto;
			max-width: 150px;
			width: 100%;
			cursor: move;
			touch-action: none;
		}
		& svg {
			cursor: move;
			touch-action: none;
		}
	}

	& .thumbnail-container {
		display: flex;
		gap: 2rem;
		flex-flow: wrap;
	}

	& .add-icon svg {
		width: 1rem;
		height: 1rem;
	}

	& .hide {
		visibility: hidden;
	}
`

export default function StageForm() {
	const { t } = useTranslation()

	const {
		state: { stage },
	} = useProduction()

	if (!stage.id) {
		return null
	}

	return (
		<>
			<AddAttachmentModal />
			<EditAttachmentModal />
			<FormContainer>
				<Accordion title={t("production")} data-cy="production-ToggleSection-Production">
					<ProductionInputs />
				</Accordion>
				<Accordion title={t("facilities")} data-cy="production-ToggleSection-Facilities">
					<FacilitiesInputs />
				</Accordion>
				<Accordion title={t("equipment")} data-cy="production-ToggleSection-Equipment">
					<EquipmentInputs />
				</Accordion>
				<Accordion title={t("logistics")} data-cy="production-ToggleSection-Logistics">
					<LogisticsInputs />
				</Accordion>
				<Accordion title={t("localCrew")} data-cy="production-ToggleSection-LocalCrew">
					<LocalCrewInputs />
				</Accordion>
			</FormContainer>
		</>
	)
}

const ThreeDeeContainer = styled(Paper)`
	background-color: ${({ theme }) => theme.cardColor} !important;
	color: ${({ theme }) => theme.text} !important;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	padding: 2rem;
	& .itemContainerParent {
		background-color: ${({ theme }) => theme.cardColor};
		display: flex;
		gap: 3rem;
		${mediaQuery("md")`
			flex-direction: column !important;
		`}
	}
`

const ItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const ThreeDeeStuff: any = () => {
	const { t } = useTranslation()
	const { stageFieldChanges } = useProduction()

	const {
		state: {
			stage: { productionData },
		},
		setState,
	} = useProduction()

	const [value, setValue] = useState({
		dimensions: productionData.dimensions,
		proscenium: productionData.proscenium,
	})

	const updateDimensions = (key: string) => {
		if (
			value.dimensions === productionData.dimensions &&
			value.proscenium === productionData.proscenium
		) {
			return
		}

		setState((s: ProductionStateInterface) => ({
			...s,
			stage: {
				...s.stage,
				productionData: {
					...s.stage.productionData,
					...value,
				},
			},
		}))
	}

	useDebounce(value, updateDimensions, 500)

	// Reduce arguments for each extractTimeStampData call
	const dimensionsTimeStamp = (fieldName: any) =>
		extractTimeStampData(fieldName, stageFieldChanges, "venueStage")

	return (
		<>
			<ThreeDeeContainer>
				{t("dimensions")}
				<div className="itemContainerParent">
					<ItemContainer>
						<FormField
							data-cy="production-Dimensions-Width"
							label={t("width")}
							value={value?.dimensions?.width || ""}
							onChange={(e: any) =>
								setValue(s => ({
									...s,
									dimensions: {
										...(s.dimensions || {}),
										width: e.target.value,
									},
								}))
							}
						/>
						<TimeStamp
							{...dimensionsTimeStamp("productionData.dimensions.width")}
							marginLeft={-1}
							marginTop={3}
						/>
					</ItemContainer>
					<ItemContainer>
						<FormField
							data-cy="production-Dimensions-Depth"
							label={t("depth")}
							value={value?.dimensions?.depth || ""}
							onChange={(e: any) =>
								setValue(s => ({
									...s,
									dimensions: {
										...(s.dimensions || {}),
										depth: e.target.value,
									},
								}))
							}
						/>
						<TimeStamp
							{...dimensionsTimeStamp("productionData.dimensions.depth")}
							marginLeft={-1}
							marginTop={3}
						/>
					</ItemContainer>
					<ItemContainer>
						<FormField
							data-cy="production-Dimensions-Height"
							label={t("height")}
							value={value?.dimensions?.height || ""}
							onChange={(e: any) =>
								setValue(s => ({
									...s,
									dimensions: {
										...(s.dimensions || {}),
										height: e.target.value,
									},
								}))
							}
						/>
						<TimeStamp
							{...dimensionsTimeStamp("productionData.dimensions.height")}
							marginLeft={-1}
							marginTop={3}
						/>
					</ItemContainer>
				</div>
			</ThreeDeeContainer>
			<ThreeDeeContainer>
				{t("proscenium")}
				<div className="itemContainerParent">
					<ItemContainer>
						<FormField
							data-cy="production-Proscenium-Width"
							label={t("width")}
							value={value?.proscenium?.width || ""}
							onChange={(e: any) =>
								setValue(s => ({
									...s,
									proscenium: {
										...(s.proscenium || {}),
										width: e.target.value,
									},
								}))
							}
						/>
						<TimeStamp
							{...dimensionsTimeStamp("productionData.proscenium.width")}
							marginLeft={-1}
							marginTop={3}
						/>
					</ItemContainer>
					<ItemContainer>
						<FormField
							data-cy="production-Proscenium-Depth"
							label={t("depth")}
							value={value?.proscenium?.depth || ""}
							onChange={(e: any) =>
								setValue(s => ({
									...s,
									proscenium: {
										...(s.proscenium || {}),
										depth: e.target.value,
									},
								}))
							}
						/>
						<TimeStamp
							{...dimensionsTimeStamp("productionData.proscenium.depth")}
							marginLeft={-1}
							marginTop={3}
						/>
					</ItemContainer>
					<ItemContainer>
						<FormField
							data-cy="production-Proscenium-Height"
							label={t("height")}
							value={value?.proscenium?.height || ""}
							onChange={(e: any) =>
								setValue(s => ({
									...s,
									proscenium: {
										...(s.proscenium || {}),
										height: e.target.value,
									},
								}))
							}
						/>
						<TimeStamp
							{...dimensionsTimeStamp("productionData.proscenium.height")}
							marginLeft={-1}
							marginTop={3}
						/>
					</ItemContainer>
				</div>
			</ThreeDeeContainer>
		</>
	)
}

const ProductionInputs: React.FC = () => {
	const { t } = useTranslation()

	return (
		<FieldsFormContainer >
			<h3>{t("staging")}</h3>
			<ThreeDeeStuff />
			<StageFormField data="stageWings" dataCategory="productionData" data-cy="production-StageWings-Field"/>
			<StageFormField data="paBays" dataCategory="productionData" data-cy="production-PABays-Field"/>
			<StageFormField data="orchestraPit" dataCategory="productionData" data-cy="production-OrchestraPit-Field"/>
			<StageFormField data="curtain" dataCategory="productionData" data-cy="production-Curtain-Field"/>
			<StageFormField data="fireCurtain" dataCategory="productionData" data-cy="production-FireCurtain-Field"/>
			<StageFormField data="steps" dataCategory="productionData" data-cy="production-Steps-Field"/>
			<StageFormField data="trimHeight" dataCategory="productionData" data-cy="production-TrimHeight-Field"/>
			<StageFormField data="apron" dataCategory="productionData" data-cy="production-Apron-Field"/>
			<StageFormField data="deckToGrid" dataCategory="productionData" data-cy="production-DeckToGrid-Field"/>
			<StageFormField data="legs" dataCategory="productionData" data-cy="production-Legs-Field"/>
			<StageFormField data="borders" dataCategory="productionData" data-cy="production-Borders-Field"/>
			<StageFormField data="risers" dataCategory="productionData" data-cy="production-Risers-Field"/>
			<StageFormField data="stage" dataCategory="productionData" data-cy="production-Stage-Field"/>
			<h3>{t("loading")}</h3>
			<StageFormField data="access" dataCategory="productionData" data-cy="production-Access-Field"/>
			<StageFormField data="dockType" dataCategory="productionData" data-cy="production-DockType-Field"/>
			<StageFormField data="deadCase" dataCategory="productionData" data-cy="production-DeadCase-Field"/>
			<StageFormField data="forklifts" dataCategory="productionData" data-cy="production-Forklifts-Field"/>
			<StageFormField data="loadComments" dataCategory="productionData" isTextArea={true} data-cy="production-LoadComments-Field"/>
			<h3>{t("rigging")}</h3>
			<StageFormField data="flySystem" dataCategory="productionData" data-cy="production-FlySystem-Field"/>
			<StageFormField data="lineSets" dataCategory="productionData" data-cy="production-LineSets-Field"/>
			<StageFormField data="riggingComments" dataCategory="productionData" isTextArea={true} data-cy="production-RiggingComments-Field"/>
			<h3>{t("power")}</h3>
			<StageFormField data="audioPower" dataCategory="productionData" data-cy="production-AudioPower-Field"/>
			<StageFormField data="lightingPower" dataCategory="productionData" data-cy="production-LightingPower-Field"/>
			<StageFormField data="otherPower" dataCategory="productionData" data-cy="production-OtherPower-Field"/>
			<StageFormField data="showPower" dataCategory="productionData" data-cy="production-ShowPower-Field"/>
			<StageFormField data="distToStage" dataCategory="productionData" data-cy="production-DistToStage-Field"/>
			<StageFormField data="powerComments" dataCategory="productionData" isTextArea={true} data-cy="production-PowerComments-Field"/>
			<h3>{t("show")}</h3>
			<StageFormField data="houseLights" dataCategory="productionData" data-cy="production-HouseLights-Field"/>
			<StageFormField data="climateControl" dataCategory="productionData" data-cy="production-ClimateControl-Field"/>
			<StageFormField data="fog" dataCategory="productionData" data-cy="production-Fog-Field"/>
			<StageFormField data="pyro" dataCategory="productionData" data-cy="production-Pyro-Field"/>
			<StageFormField data="co2" dataCategory="productionData" data-cy="production-CO2-Field"/>
			<h3>{t("house")}</h3>
			<StageFormField data="fohPosition" dataCategory="productionData" data-cy="production-FOHPosition-Field"/>
			<StageFormField data="snakeRun" dataCategory="productionData" data-cy="production-SnakeRun-Field"/>
			<StageFormField data="barricade" dataCategory="productionData" data-cy="production-Barricade-Field"/>
			<StageFormField data="roof" dataCategory="productionData" data-cy="production-Roof-Field"/>
			<StageFormField data="spots" dataCategory="productionData" data-cy="production-Spots-Field"/>
			<StageFormField data="venueSeats" dataCategory="productionData" data-cy="production-VenueSeats-Field"/>
		</FieldsFormContainer>
	)
}

const FacilitiesInputs: React.FC = () => {
	const { t } = useTranslation()
	return (
		<FieldsFormContainer>
			<h3>{t("facility")}</h3>
			<StageFormField data="dressingRooms" dataCategory="facilitiesData" data-cy="production-DressingRooms-Field"/>
			<StageFormField data="facilityNotes" dataCategory="facilitiesData" data-cy="production-FacilityNotes-Field"/>
			<StageFormField data="offices" dataCategory="facilitiesData" data-cy="production-Offices-Field"/>
			<StageFormField data="cateringLocale" dataCategory="facilitiesData" data-cy="production-CateringLocale-Field"/>
			<StageFormField data="cateringBreakfast" dataCategory="facilitiesData" data-cy="production-CateringBreakfast-Field"/>
			<StageFormField data="cateringLunch" dataCategory="facilitiesData" data-cy="production-CateringLunch-Field"/>
			<StageFormField data="cateringDinner" dataCategory="facilitiesData" data-cy="production-CateringDinner-Field"/>
			<StageFormField data="breakfast" dataCategory="facilitiesData" data-cy="production-Breakfast-Field"/>
			<StageFormField data="lunch" dataCategory="facilitiesData" data-cy="production-Lunch-Field"/>
			<StageFormField data="dinner" dataCategory="facilitiesData" data-cy="production-Dinner-Field"/>
			<StageFormField data="rehearsalSpace" dataCategory="facilitiesData" data-cy="production-RehearsalSpace-Field"/>
			<StageFormField data="showers" dataCategory="facilitiesData" data-cy="production-Showers-Field"/>
			<StageFormField data="security" dataCategory="facilitiesData" data-cy="production-Security-Field"/>
			<StageFormField data="merchandisers" dataCategory="facilitiesData" data-cy="production-Merchandisers-Field"/>
			<StageFormField data="hallMerchPercent" dataCategory="facilitiesData" data-cy="production-HallMerchPercent-Field"/>
			<StageFormField data="towels" dataCategory="facilitiesData" data-cy="production-Towels-Field"/>
			<StageFormField data="laundry" dataCategory="facilitiesData" data-cy="production-Laundry-Field"/>
			<StageFormField data="runners" dataCategory="facilitiesData" data-cy="production-Runners-Field"/>
			<h3>{t("parking")}</h3>
			<StageFormField data="truckParking" dataCategory="facilitiesData" data-cy="production-TruckParking-Field"/>
			<StageFormField data="busParking" dataCategory="facilitiesData" data-cy="production-BusParking-Field"/>
			<StageFormField data="shorePower" dataCategory="facilitiesData" data-cy="production-ShorePower-Field"/>
			<StageFormField data="guestParking" dataCategory="facilitiesData" data-cy="production-GuestParking-Field"/>
			<StageFormField data="parkingComments" dataCategory="facilitiesData" isTextArea={true} data-cy="production-ParkingComments-Field"/>
			<h3>{t("informationTech")}</h3>
			<StageFormField data="wifiSsid" dataCategory="facilitiesData" data-cy="production-WifiSSID-Field"/>
			<StageFormField data="username" dataCategory="facilitiesData" data-cy="production-Username-Field"/>
			<StageFormField data="password" dataCategory="facilitiesData" data-cy="production-Password-Field"/>
			<StageFormField data="circuit" dataCategory="facilitiesData" data-cy="production-Circuit-Field"/>
			<StageFormField data="ip" dataCategory="facilitiesData" data-cy="production-IP-Field"/>
			<StageFormField data="wanIP" dataCategory="facilitiesData" data-cy="production-WanIP-Field"/>
			<StageFormField data="subnetMask" dataCategory="facilitiesData" data-cy="production-SubnetMask-Field"/>
			<StageFormField data="gateway" dataCategory="facilitiesData" data-cy="production-Gateway-Field"/>
			<StageFormField data="dns" dataCategory="facilitiesData" data-cy="production-DNS-Field"/>
			<StageFormField data="isp" dataCategory="facilitiesData" data-cy="production-ISP-Field"/>
			<StageFormField data="fees" dataCategory="facilitiesData" data-cy="production-Fees-Field"/>
			<StageFormField data="officeComments" dataCategory="facilitiesData" isTextArea={true} data-cy="production-OfficeComments-Field"/>
		</FieldsFormContainer>
	)
}

const EquipmentInputs: React.FC = () => {
	return (
		<FieldsFormContainer>
			<StageFormField data="audio" dataCategory="equipmentData" data-cy="production-Audio-Field"/>
			<StageFormField data="audioComments" dataCategory="equipmentData" isTextArea={true} data-cy="production-AudioComments-Field"/>
			<StageFormField data="staging" dataCategory="equipmentData" data-cy="production-Staging-Field"/>
			<StageFormField data="lighting" dataCategory="equipmentData" data-cy="production-Lighting-Field"/>
			<StageFormField data="lightComments" dataCategory="equipmentData" isTextArea={true} data-cy="production-LightComments-Field"/>
			<StageFormField data="backline" dataCategory="equipmentData" data-cy="production-Backline-Field"/>
			<StageFormField data="video" dataCategory="equipmentData" data-cy="production-Video-Field"/>
			<StageFormField data="videoComments" dataCategory="equipmentData" isTextArea={true} data-cy="production-VideoComments-Field"/>
			<StageFormField data="other" dataCategory="equipmentData" data-cy="production-Other-Field"/>
		</FieldsFormContainer>
	)
}

const LogisticsInputs: React.FC = () => {
	return (
		<FieldsFormContainer>
			<StageFormField data="airportNotes" dataCategory="logisticsData" data-cy="production-AirportNotes-Field"/>
			<StageFormField data="groundTransport" dataCategory="logisticsData" data-cy="production-GroundTransport-Field"/>
			<StageFormField data="areaBusiness" dataCategory="logisticsData" data-cy="production-AreaBusiness-Field"/>
			<StageFormField data="areaRestaurants" dataCategory="logisticsData" data-cy="production-AreaRestaurants-Field"/>
			<StageFormField data="areaHotels" dataCategory="logisticsData" data-cy="production-AreaHotels-Field"/>
			<StageFormField data="interest" dataCategory="logisticsData" data-cy="production-Interest-Field"/>
			<StageFormField data="closestCity" dataCategory="logisticsData" data-cy="production-ClosestCity-Field"/>
			<StageFormField data="directions" dataCategory="logisticsData" data-cy="production-Directions-Field"/>
		</FieldsFormContainer>
	)
}

const LocalCrewInputs: React.FC = () => {
	return (
		<FieldsFormContainer>
			<StageFormField data="localUnion" dataCategory="localCrewData" data-cy="production-LocalUnion-Field"/>
			<StageFormField data="minimumIN" dataCategory="localCrewData" data-cy="production-MinimumIn-Field"/>
			<StageFormField data="minimumOUT" dataCategory="localCrewData" data-cy="production-MinimumOut-Field"/>
			<StageFormField data="penalties" dataCategory="localCrewData" data-cy="production-Penalties-Field"/>
			<StageFormField data="crewComments" dataCategory="localCrewData" isTextArea={true} data-cy="production-CrewComments-Field"/>
		</FieldsFormContainer>
	)
}

const FieldsFormContainer = styled.div`
	background-color: ${({ theme }) => theme.background};
	display: flex;
	flex-direction: column;
	gap: 2rem;
	margin: 1rem;

	& > h3 {
		text-align: left;
		${mediaQuery("md")`
			text-align: center;
		`}
	}
	& > h3:not(:first-child) {
		margin-top: 2rem;
	}
	& > div:first-child {
		margin-top: 2rem;
	}
`

const FormContainer = styled(Paper)`
	background-color: ${({ theme }) => theme.cardColor} !important;
	flex-direction: column;
	padding: 3%;

	${mediaQuery("md")`
		padding: 2%;
	`}
`
