import { useMutation } from "@tanstack/react-query"
import { toast } from "react-toastify"

// note, this uses axios and not useAxios
// unauthenticated for tech-pack

type DownloadParams = {
	url: string
	filename: string
}

export default function useDownloadAttachment() {
	const { mutate: downloadAttachment, isLoading: isDownloadLoading } = useMutation(
		async ({ url, filename }: DownloadParams) => {
			fetch(url)
				.then(response => response.blob())
				.then(blob => {
					var reader = new FileReader()
					reader.onload = function () {
						let base64 = this.result
						base64 = typeof base64 === "string" ? base64 : ""
						var a = document.createElement("a")
						a.href = base64
						a.download = filename
						a.click()
					}
					reader.readAsDataURL(blob)
				})
		},
		{
			onError: () => {
				toast.error("errorTryAgain")
			},
		},
	)

	return { downloadAttachment, isDownloadLoading }
}
