import Grid from "@mui/material/Grid"
import Card from "../atoms/Card"
import ProfileForm from "../organisms/ProfileForm"
import styled, { useTheme } from "styled-components"
import { useTranslation } from "react-i18next"
import useUserContext from "../../hooks/useUserContext"
import UserAvatar from "../atoms/UserAvatar"
import { Badge } from "@mui/material"
import CameraAltIcon from "@mui/icons-material/CameraAlt"
import { DragEvent, useRef, useState } from "react"
import ImageCropper from "../molecules/ImageCropper"
import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import imageTypes from "../../util/constants/IMAGE_TYPES"
import { toast } from "react-toastify"
import { useMutation } from "@tanstack/react-query"
import useAxios from "../../hooks/useAxios"
import getCroppedImg, { ImageDataType } from "../../util/cropImage"

const UserAvatarWithUpload = () => {
	const theme = useTheme()
	const { t } = useTranslation()
	const axios = useAxios()

	const {
		user: { preferredName },
		userInitials,
		profileId,
		avatar,
		setAvatar,
	} = useUserContext()

	const imageInputRef = useRef<HTMLInputElement>(null)
	const [filePath, setFilePath] = useState("")
	const [dragActive, setDragActive] = useState(false)
	const [fileType, setFileType] = useState("")

	const closeModal = () => {
		if (imageInputRef?.current) {
			// clear file input
			imageInputRef.current.value = ""
		}
		setFilePath("")
		setFileType("")
	}

	// 1) save data and get bucket url
	const { mutate: mutateInitUpload, isLoading: initialUploadLoading } = useMutation(
		async () => {
			return await axios.post("/venues/v1/files", {
				filename: `user-avatar-${new Date()}`,
				attachedToType: "Profile",
				attachedToId: profileId,
			})
		},
		{
			onError: (error: string) => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: async (res, { data }: { data: ImageDataType }) => {
				const { id, presignedUrl } = res.data.data
				mutateUpload({ presignedUrl, id, data: data })
			},
		},
	)
	// 2) upload file to bucket
	const { mutate: mutateUpload, isLoading: uploadLoading } = useMutation(
		async ({
			presignedUrl,
			id,
			data,
		}: {
			presignedUrl: string
			id: string
			data: ImageDataType
		}) => {
			const imageToUpload = await getCroppedImg(filePath, data, 0, fileType)

			return await fetch(presignedUrl, {
				body: imageToUpload,
				method: "PUT",
			})
		},
		{
			onError: (error: string) => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: async (res, params: { presignedUrl: string; id: string }) => {
				setFileAsUploaded(params.id)
			},
		},
	)
	// 3) set file as uploaded
	const { mutate: setFileAsUploaded, isLoading: setIsLoading } = useMutation(
		async (id: string) => {
			return await axios.patch(`/venues/v1/files/${id}`, {
				disk: "s3",
			})
		},
		{
			onError: (error: string) => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: async (res, id: string) => {
				getAvatarURL(id)
			},
		},
	)
	// 4) get read url for avatar
	const { mutate: getAvatarURL, isLoading: getAvatarLoading } = useMutation(
		async (id: string) => {
			return await axios.post(`/venues/v1/files/${id}/url`, {
				filename: "user-avatar",
				attachedToType: "profile",
				attachedToId: profileId,
			})
		},
		{
			onError: () => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: res => {
				toast.success(t("attachmentUploaded"))
				setAvatar(res.data.data.url)
				closeModal()
			},
		},
	)

	const handlePhotoUpdate = (event: any) => {
		// click item
		const fileObject = event.target?.files?.[0]
		const fileType = fileObject?.type
		// drag/drop item
		const dragFileObject = event.dataTransfer?.files?.[0]
		const dragFileType = dragFileObject?.type
		if (!fileObject && !dragFileObject) {
			// something happened
			return
		}
		if (fileObject && !imageTypes.includes(fileType)) {
			// have click item but not an image
			return
		}
		if (dragFileObject && !imageTypes.includes(dragFileType)) {
			// have drag/drop item but not an image
			return
		}

		var path = (window.URL || window.webkitURL).createObjectURL(fileObject || dragFileObject)
		setFilePath(path)
		setFileType(fileObject.type)
	}

	const handleDrag = (e: DragEvent) => {
		e.preventDefault()
		e.stopPropagation()
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true)
		} else if (e.type === "dragleave") {
			setDragActive(false)
		}
	}

	const handleDrop = (e: any) => {
		e.preventDefault()
		e.stopPropagation()
		setDragActive(false)
		handlePhotoUpdate(e)
	}

	return (
		<>
			<CurrentUserGrid>
				<StyledBadge
					data-cy="profile-UserAvatar"
					badgeContent={<CameraAltIcon />}
					overlap="circular"
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					sx={{
						cursor: "pointer",
					}}
					onClick={() => imageInputRef?.current?.click()}
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				>
					<UserAvatar
						src={avatar}
						sx={{
							bgcolor: theme.primaryButtonColor,
							height: 100,
							width: 100,
						}}
					>
						{dragActive ? <CloudUploadIcon sx={{ fontSize: "70px" }} /> : <h2>{userInitials}</h2>}
					</UserAvatar>
					<input
						ref={imageInputRef}
						type="file"
						style={{ display: "none" }}
						accept="image/*"
						onChange={handlePhotoUpdate}
					/>
				</StyledBadge>
				<h2>{preferredName}</h2>
			</CurrentUserGrid>
			<ImageCropper
				open={Boolean(filePath)}
				handleSaveFile={mutateInitUpload}
				handleClose={closeModal}
				filePath={filePath!}
				isLoading={getAvatarLoading || setIsLoading || uploadLoading || initialUploadLoading}
			/>
		</>
	)
}

const Profile = () => {
	return (
		<PageContainerGrid>
			<UserAvatarWithUpload />
			<ProfileContainerGrid>
				<ProfileCard>
					<ProfileForm />
				</ProfileCard>
				{/* {myVenues.length > 0 && (
					<ProfileCard>
						<h4>{t("permissions")}</h4>
						<div>
							{myVenues.map(item => (
								<ListItem key={`id_${item.id}`}>
									{item.permissions && (
										<StyledListItemText primary={item.name} secondary={t("administrator")} />
									)}
								</ListItem>
							))}
						</div>
					</ProfileCard>
				)} */}
			</ProfileContainerGrid>
		</PageContainerGrid>
	)
}

const StyledBadge = styled(Badge)`
	& .MuiSvgIcon-root {
		margin-left: 10px;
		margin-top: 5px;
	}
`

const ProfileCard = styled(Card)`
	padding: 32px;
	margin: 12px;
	width: 360px;
`

const PageContainerGrid = styled(Grid)`
	width: 100%;
	height: auto;
`

const CurrentUserGrid = styled(Grid)`
	align-items: center;
	align-content: center;
	color: ${({ theme }) => theme.text};
	flex-direction: column;
	padding: 1rem 3rem;
	text-align: center;
	width: 100%;
`

const ProfileContainerGrid = styled(Grid)`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
`

export default Profile
