import styled from "styled-components"
import FormField from "../../atoms/FormField"
import mediaQuery from "../../../util/mediaQuery"
import Modal from "../../molecules/Modal"
import { useTranslation, Trans } from "react-i18next"
import useProduction from "../../../hooks/useProduction"
import compare from "../../../util/compare"
import { createRef, useEffect, useState } from "react"
import { isEntryInvalid } from "../../../util/formValidation"
import TimeStamp from "../../molecules/TimeStamp"
import { extractTimeStampData } from "../../../util/extractTimeStampData"

const AddVenueContactModal = () => {
	const { t } = useTranslation()

	const focusRef = createRef<HTMLInputElement>()

	const {
		state: {
			venue,
			editingVenueContact,
			isAddVenueContactModalOpen,
			isEditVenueContactModalOpen,
			holdEditingVenueContact,
		},
		isUpdateVenueContactsLoading,
		updateVenueContacts,
		setState,
		venueFieldChanges,
	} = useProduction()

	const [labelError, setLabelError] = useState("")
	const [hasChanges, setHasChanges] = useState(false)
	useEffect(() => {
		const changed = !compare(holdEditingVenueContact, editingVenueContact)

		if (changed !== hasChanges) {
			setHasChanges(changed)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [holdEditingVenueContact, editingVenueContact])

	const updateEditingVenueContact = (update: object) =>
		setState((s: ProductionStateInterface) => ({
			...s,
			editingVenueContact: { ...s.editingVenueContact, ...update },
		}))

	const handleSubmit = () => {
		// prevent submitting only spaces as label
		if (!editingVenueContact.label || isEntryInvalid(editingVenueContact.label)) {
			setLabelError(t("fieldRequired"))
			focusRef.current?.focus()
			return
		}

		//prevent duplicates
		if (
			(isAddVenueContactModalOpen &&
				venue.contacts.find((c: VenueContact) => c.label === editingVenueContact.label)?.label) ||
			(isEditVenueContactModalOpen &&
				venue.contacts.filter((c: VenueContact) => c.label === editingVenueContact.label).length >
					1)
		) {
			setLabelError(t("possibleDuplicate"))
			focusRef.current?.focus()
			return
		}

		setLabelError("")

		if (isEditVenueContactModalOpen) {
			updateVenueContacts({
				translationKey: "contactUpdated",
				contacts: venue.contacts.map((c: VenueContact) =>
					compare(holdEditingVenueContact, c) ? editingVenueContact : c,
				),
			})
		} else {
			updateVenueContacts({
				translationKey: "contactAdded",
				contacts: [...venue.contacts, editingVenueContact],
			})
		}
		closeEditModal()
	}

	const closeEditModal = () =>
		setState((s: ProductionStateInterface) => ({
			...s,
			isAddVenueContactModalOpen: false,
			isEditVenueContactModalOpen: false,
		}))

	useEffect(() => setLabelError(""), [isAddVenueContactModalOpen, isEditVenueContactModalOpen])

	return (
		<Modal
			open={isAddVenueContactModalOpen || isEditVenueContactModalOpen}
			onClose={closeEditModal}
			onSubmit={handleSubmit}
			submitText={isAddVenueContactModalOpen ? t("add") : t("update")}
			submitLoading={isUpdateVenueContactsLoading}
			showCancel={false}
			submitButtonDisabled={!hasChanges || isEntryInvalid(editingVenueContact.label)}
		>
			<StyledAddContactModalBody>
				<h2>
					<Trans
						i18nKey={isAddVenueContactModalOpen ? "addPhoneAndEmailTo" : "updatePhoneAndEmailFor"}
						values={{
							to: venue.name,
						}}
						components={{ b: <b /> }}
					/>
				</h2>
				<div className="input-container">
					<FormField
						required
						error={isEntryInvalid(editingVenueContact.label)}
						helperText={labelError}
						label={t("label")}
						value={editingVenueContact?.label}
						onChange={e => updateEditingVenueContact({ label: e.target.value })}
						inputRef={focusRef}
						data-cy="production-VenueContact-Modal-Label-Field"
					/>
					{isEditVenueContactModalOpen && (
						<TimeStamp
							{...extractTimeStampData("contacts.0.label", venueFieldChanges, "venue")}
							marginLeft={-1}
							marginTop={-20}
						/>
					)}
					<div>
						<FormField
							label={t("phone")}
							value={editingVenueContact.phone}
							onChange={e => updateEditingVenueContact({ phone: e.target.value })}
							data-cy="production-VenueContact-Modal-Phone-Field"
						/>
						{isEditVenueContactModalOpen && (
							<TimeStamp
								{...extractTimeStampData("contacts.0.phone", venueFieldChanges, "venue")}
								marginLeft={-1}
								marginTop={1}
							/>
						)}
					</div>
					<div>
						<FormField
							label={t("email")}
							value={editingVenueContact.email}
							onChange={e => updateEditingVenueContact({ email: e.target.value })}
							data-cy="production-VenueContact-Modal-Email-Field"
						/>
						{isEditVenueContactModalOpen && (
							<TimeStamp
								{...extractTimeStampData("contacts.0.email", venueFieldChanges, "venue")}
								marginLeft={-1}
								marginTop={1}
							/>
						)}
					</div>
				</div>
			</StyledAddContactModalBody>
		</Modal>
	)
}

const StyledAddContactModalBody = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;

	text-align: center;

	.input-container {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;

		width: 50%;
		${mediaQuery("lg")`
			width: 75%;
		`}
	}
`

export default AddVenueContactModal
