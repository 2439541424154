import { useTranslation } from "react-i18next"
import Link from "../../icons/Link"
import styled, { useTheme } from "styled-components"
import { toast } from "react-toastify"

type LinkButtonProps = {
	color?: string
	borderColor?: string
	textToCopy: string
}

const LinkButton = ({
	color: colorProp,
	borderColor: borderColorProp,
	textToCopy,
	...rest
}: LinkButtonProps) => {
	const { t } = useTranslation()
	const theme = useTheme()
	const color = colorProp || theme.techPackIconColor

	const handleCopy = async () => {
		try {
			await navigator.clipboard.writeText(textToCopy)
			toast.success(t("linkCopied"))
		} catch (err) {
			toast.error(t("errorMessage"))
			console.error("Failed to copy: ", err)
		}
	}

	return (
		<ButtonContainer {...rest} onClick={handleCopy} data-cy-component="LinkButton_Component">
			<Link size={20} color={color} />
		</ButtonContainer>
	)
}

const ButtonContainer = styled.div`
	margin: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	background: ${({ theme }) => theme.techPackIconBackground};
	border: 1px solid ${({ theme }) => theme.techPackIconBackground};
	border-radius: 50%;
`

export default LinkButton
