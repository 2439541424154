import {
	Accordion as MUIAccordion,
	AccordionSummary,
	AccordionDetails,
	AccordionProps,
} from "@mui/material"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled, { useTheme } from "styled-components"

const Accordion: React.FC<
	{ children: React.ReactNode; title: string; unmount?: boolean } & AccordionProps
> = ({ children, title, unmount, ...props }) => {
	const theme = useTheme()

	return (
		<StyledAccordion
			data-cy-component="Component-Accordion"
			TransitionProps={{ unmountOnExit: unmount }}
			{...props}
		>
			<StyledAccordionSummary
				expandIcon={<FontAwesomeIcon color={theme.tertiaryTextColor} icon="chevron-up" />}
			>
				{title}
			</StyledAccordionSummary>
			<StyledAccordionDetails>{children}</StyledAccordionDetails>
		</StyledAccordion>
	)
}

const StyledAccordion = styled(MUIAccordion)`
	border: 1px solid ${({ theme }) => theme.textLight};
	color: ${({ theme }) => theme.text} !important;

	> div {
		border-color: ${({ theme }) => theme.primaryColor};
	}

	& .MuiAccordionSummary-root {
		background-color: ${({ theme }) => theme.tertiaryBackgroundColor} !important;
		color: ${({ theme }) => theme.tertiaryTextColor} !important;
	}
`
const StyledAccordionSummary = styled(AccordionSummary)`
	border-color: ${({ theme }) => theme.primaryColor};
`
const StyledAccordionDetails = styled(AccordionDetails)`
	background-color: ${({ theme }) => theme.background};
`

export default Accordion
