import { SelectChangeEvent, DialogTitle } from "@mui/material"
import styled from "styled-components"
import useVenues from "../../hooks/useVenues"
import Select from "../atoms/Select"
import { FC, useEffect, Dispatch, SetStateAction, useState } from "react"
import useConfirmClose from "../../hooks/useConfirmClose"
import { useTranslation } from "react-i18next"
import Modal from "../molecules/Modal"

interface VenueSelectorType {
	textColor?: string
	selectedColor?: string
}

const SelectorDiscardModal: FC<{
	setOpen: Dispatch<SetStateAction<boolean>>
	open: boolean
	handleStageChange: Dispatch<SetStateAction<string>>
}> = ({ setOpen, open, handleStageChange }) => {
	const { setShouldPreventClose } = useConfirmClose()
	const { t } = useTranslation()

	const handleSubmit = () => {
		setShouldPreventClose(false)
		handleStageChange("")
	}
	return (
		<Modal
			open={open}
			onClose={() => setOpen(false)}
			onSubmit={handleSubmit}
			submitText={t("discard")}
			showClose={false}
		>
			<StyledDialogTitle>{t("discardPrompt")}</StyledDialogTitle>
		</Modal>
	)
}

const VenueSelector: FC<VenueSelectorType> = ({ selectedColor, textColor, ...rest }) => {
	const {
		selectedStage,
		selectedVenue,
		setSelectedStage,
		setSelectedVenue,
		stageSelectorData,
		venues,
		userCanViewVenues,
	} = useVenues()
	const { shouldPreventClose, setShouldPreventClose } = useConfirmClose()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [newSelectedStage, setNewSelectedStage] = useState<Stage | null>(null)

	const updateSelectedStage = () => {
		if (!newSelectedStage) {
			return
		}
		const newVenue = venues.find(v => v.id === newSelectedStage?.venueId) || null
		setSelectedVenue(newVenue)
		setSelectedStage(newSelectedStage)
		setIsModalOpen(false)
		setShouldPreventClose(false)
	}

	const handleChange = (e: SelectChangeEvent<unknown>) => {
		if (e?.target?.value) {
			const stages = venues.reduce((acc, curr) => {
				acc.push(...curr.stages)
				return acc
			}, [] as Stage[])
			const newStage = stages.find(v => v.id === e.target.value) || null
			setNewSelectedStage(newStage)
		}
	}
	useEffect(() => {
		if (newSelectedStage !== null) {
			if (shouldPreventClose) {
				setIsModalOpen(true)
			} else {
				updateSelectedStage()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newSelectedStage])

	useEffect(() => {
		const venueStages = selectedVenue?.stages
		if (!selectedStage?.id && venueStages) {
			setSelectedStage(venueStages[0])
		}
	}, [selectedStage, selectedVenue?.stages, setSelectedStage])

	if (!selectedStage?.id) {
		return null
	}

	if (!userCanViewVenues) {
		return null
	}

	return (
		<>
			<SelectorDiscardModal
				handleStageChange={updateSelectedStage}
				open={isModalOpen}
				setOpen={setIsModalOpen}
			/>
			<StyledSelect
				{...rest}
				selected={selectedStage!.id}
				onChange={handleChange}
				items={stageSelectorData}
				title={`${selectedVenue!.name} - ${selectedStage!.name}`}
				textColor={textColor}
				selectedColor={selectedColor}
			/>
		</>
	)
}

const StyledSelect = styled(Select)<{ selectedColor?: string }>`
	color: ${props => (props.selectedColor ? props.selectedColor : props.theme.text)};
	font-size: 1rem !important;
	font-weight: bold;
	width: 100%;
`

const StyledDialogTitle = styled(DialogTitle)`
	color: ${({ theme }) => theme.text} !important;
	text-align: center;
`

export default VenueSelector
