import styled from "styled-components"
import ProductionContextProvider from "../../context/production"
import useProduction from "../../hooks/useProduction"
import SaveButtonDrawer from "../molecules/SaveButtonDrawer"
import StageForm from "../organisms/production/StageForm"
import VenueInformation from "../organisms/production/VenueInformation"

function ProductionPage() {
	const {
		state: { venueHasChanges, stageHasChanges, disableSaveButton },
		handleSaveForms,
		updateIsLoading,
	} = useProduction()

	return (
		<ProductionContainer>
			<VenueInformation />
			<StageForm />
			<SaveButtonDrawer
				show={venueHasChanges || stageHasChanges}
				onSave={handleSaveForms}
				disableButton={updateIsLoading || disableSaveButton}
			/>
		</ProductionContainer>
	)
}

export default function Production() {
	return (
		<ProductionContextProvider>
			<ProductionPage />
		</ProductionContextProvider>
	)
}

const ProductionContainer = styled.div`
	margin-top: 4rem;
`
