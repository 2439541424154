import Chip from "@mui/material/Chip"
import styled from "styled-components"

export default styled(Chip)`
	background-color: ${({ theme }) => theme.background} !important;
	color: ${({ theme }) => theme.text}!important;
	border: ${({ theme }) => theme.primaryButtonColor} 1px solid !important;

	& svg {
		color: ${({ theme }) => theme.textLight} !important;

		&:hover {
			color: ${({ theme }) => theme.errorColor} !important;
		}
	}

	&:hover {
		border: ${({ theme }) => theme.interactiveBorderColor} 1px solid !important;
		background-color: ${({ theme }) => theme.cardColor} !important;
	}
`