import styled from "styled-components"
import Modal from "../../molecules/Modal"
import { Trans, useTranslation } from "react-i18next"
import useProduction from "../../../hooks/useProduction"
import compare from "../../../util/compare"

const DeleteVenueContactModal: React.FC = () => {
	const { t } = useTranslation()

	const {
		state: { venue, deleteVenueContact, isDeleteVenueContactModalOpen },
		updateVenueContacts,
		isUpdateVenueContactsLoading,
		setState,
	} = useProduction()

	const handleClose = () => setState((s: ProductionStateInterface) => ({ ...s, isDeleteVenueContactModalOpen: false }))

	const handleDeleteVenueContact = () => {
		updateVenueContacts({
			translationKey: "contactDeleted",
			contacts: venue.contacts.filter((c: VenueContact) => !compare(c, deleteVenueContact)),
		})
		handleClose()
	}

	return (
		<Modal
			open={isDeleteVenueContactModalOpen}
			onClose={handleClose}
			onSubmit={handleDeleteVenueContact}
			submitLoading={isUpdateVenueContactsLoading}
			submitText={t("deleteContact")}
			submitButtonColor="error"
			showClose={false}
		>
			<CenterText>
				<h2>
					<Trans
						i18nKey="confirmDeleteFrom"
						values={{
							item: deleteVenueContact.label,
							from: venue.name,
						}}
						components={{ b: <b /> }}
					/>
				</h2>
			</CenterText>
		</Modal>
	)
}

const CenterText = styled.div`
	text-align: center;
	margin: 0 auto;
`

export default DeleteVenueContactModal
