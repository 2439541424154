import HoverSvg from "../components/atoms/HoverSvg"

const Xmark = ({ color, size }: IconTypes) => {
	const height = size / 1.4
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 15 21"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 22V20H18V22H10ZM14 19L10 15L11.4 13.6L13 15.2V11.025H15V15.2L16.6 13.6L18 15L14 19ZM2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H9L15 6V9.025H8V18H2ZM8 7H13L8 2V7Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Xmark)
