import { Trans, useTranslation } from "react-i18next"
import { FC } from "react"
import Modal from "../../molecules/Modal"
import styled from "styled-components"
import { DialogTitle } from "@mui/material"
import useVenues from "../../../hooks/useVenues"
import { useQueryClient, useMutation } from "@tanstack/react-query"
import useAxios from "../../../hooks/useAxios"
import { toast } from "react-toastify"

interface DeleteStageTypes {
	deletedId: string
	handleClose: () => void
	handleSubmit: () => void
	open: boolean
}

const DeleteStageModal: FC<DeleteStageTypes> = ({ deletedId, open, handleClose, handleSubmit }) => {
	const { selectedVenue } = useVenues()
	const axios = useAxios()
	const queryClient = useQueryClient()
	const { t } = useTranslation()

	const handleDelete = () => {
		return axios.delete(`/venues/v1/venue-stages/${deletedId}`)
	}

	const { mutate } = useMutation(handleDelete, {
		onError: () => {
			toast.error(t("tryAgain"))
		},
		onSuccess: () => {
			handleSubmit()
			queryClient.invalidateQueries(["venues"])
		},
	})

	return (
		<Modal
			open={open}
			onClose={handleClose}
			onSubmit={mutate}
			submitText={t("delete")}
			showClose={false}
			maxWidth="60rem"
		>
			<CenterText>
				<DialogTitle>
					<Trans
						i18nKey="deleteStageConfirmation"
						values={{
							stageName: (
								selectedVenue?.stages.find(stage => stage.id === deletedId) || { name: "" }
							).name,
							venueName: selectedVenue?.name || "",
						}}
						components={{ b: <strong /> }}
						shouldUnescape
					/>
				</DialogTitle>
			</CenterText>
		</Modal>
	)
}

const CenterText = styled.div`
	text-align: center;
	margin: 0 auto;
`

export default DeleteStageModal
