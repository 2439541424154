import HoverSvg from "../components/atoms/HoverSvg"

const ImageFile = ({ color, size }: IconTypes) => {
	const height = size
	const width = size * 1.375

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 33"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4 0.445557H14V8.44556C14 9.57056 14.875 10.4456 16 10.4456H24V28.4456C24 30.6956 22.1875 32.4456 20 32.4456H4C1.75 32.4456 0 30.6956 0 28.4456V4.44556C0 2.25806 1.75 0.445557 4 0.445557ZM16 0.445557L24 8.44556H16V0.445557ZM4 16.4456C4 17.5706 4.875 18.4456 6 18.4456C7.0625 18.4456 8 17.5706 8 16.4456C8 15.3831 7.0625 14.4456 6 14.4456C4.875 14.4456 4 15.3831 4 16.4456ZM13.5 18.4456V18.5081C13.125 18.5081 12.8125 18.6331 12.625 18.8831L9.625 23.3206L8.8125 21.9456C8.625 21.6331 8.3125 21.4456 8 21.4456C7.625 21.4456 7.3125 21.6331 7.125 21.9456L4.125 26.9456C3.9375 27.2581 3.9375 27.6331 4.125 27.9456C4.25 28.2581 4.625 28.4456 5 28.4456H8H11H13.5H19C19.375 28.4456 19.6875 28.2581 19.875 27.9456C20 27.6331 20 27.1956 19.8125 26.8831L14.3125 18.8831C14.125 18.6331 13.8125 18.4456 13.5 18.4456Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(ImageFile)
