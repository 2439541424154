import { useState, useMemo, useCallback, useEffect } from 'react';
import attachmentDisplayName from "../../../util/attachmentDisplayName"
import { FC } from "react"
import styled, { useTheme } from "styled-components"
import PdfFile from "../../../icons/PdfFile"
import VideoFile from "../../../icons/VideoFile"
import ImageFile from "../../../icons/ImageFile"
import File from "../../../icons/File"
import useProduction from "../../../hooks/useProduction"
import Grid from '../../molecules/sorting/Grid'
import useAxios from "../../../hooks/useAxios"
import compare from '../../../util/compare';

export type TechPackParams = {
	category: keyof Stage
	field: string
	handleFileClick: (arg: string) => {}
}

const ProductionAttachmentRow: FC<TechPackParams> = ({ category, field, handleFileClick }) => {
	const axios = useAxios();
	const [items, setItems] = useState<AttachmentDisplay[]>([])
	const [updateMetadata, setUpdateMetadata] = useState(false)
	const theme = useTheme()
	const {
		state: { attachments: stateAttachments },
	} = useProduction()

	const attachments: Attachment[] =
		useMemo(() => (stateAttachments[category] && stateAttachments[category][field]) || [], [category, field, stateAttachments])

	const getFileIcon = useCallback((item: Attachment) => {
		const ext = item.file?.filename?.split(".").pop() || ""
		// use ext to return file icon
		switch (ext) {
			case "pdf":
				return <PdfFile size={32} color={theme.darkGb1} />
			case "jpg":
			case "jpeg":
			case "png":
			case "gif":
				return <ImageFile size={32} color={theme.darkGb1} />
			case "mp4":
			case "mov":
			case "avi":
			case "wmv":
			case "flv":
			case "mkv":
			case "webm":
				return <VideoFile size={32} color={theme.darkGb1} />
			default:
				return <File size={32} color={theme.darkGb1} />
		}
	}, [theme.darkGb1])

	useEffect(() => {
		const attachmentsAreSame = attachments.every(attachment => items.find(item => attachment.id === item.id && compare(attachment.file, item.file)));

		if ((attachments.length !== items.length || !attachmentsAreSame)) {
			const defaultOrderVal = Math.max(...attachments.map(a => a.metadata?.order || -1)) + 1
			const sortedAttachments = attachments.sort((a, b) => 
				(a.metadata?.order !== undefined ? a.metadata?.order : defaultOrderVal) - (b.metadata?.order !== undefined ? b.metadata?.order : defaultOrderVal))
				.map(item => {
					const displayName = attachmentDisplayName(item.file, false)
					return {
						...item,
						child: 
							<RowContainer
								key={`${item.fileId}`}
								title={displayName}
								onClick={() => handleFileClick(item.id)}
							>
								{item.file?.publicUrls?.urlPreview ? (
									<ResponsiveImage src={item.file?.publicUrls?.urlPreview} alt={displayName} />
								) : (
									<StyledIconContainer>{getFileIcon(item)}</StyledIconContainer>
								)}
							</RowContainer>
					}
				})
			setItems(sortedAttachments);
		}
	}, [attachments, getFileIcon, handleFileClick, items])

	useEffect(() => {
		if (updateMetadata) {
			items.forEach(attachment => {
				axios.patch(`/venues/v1/file-attachments/${attachment.id}`, {
					metadata: attachment.metadata
				})
			})
			setUpdateMetadata(false);
		}
	}, [items, axios, updateMetadata])

	if (!attachments.length || !items.length) {
		return null
	}


	return (
		<StyledContainer>
			<Grid
				className="files-container thumbnail-container"
				unsortableItems={[]}
				items={items}
				setItems={setItems}
				afterSort={() => setUpdateMetadata(true)}
			/>
		</StyledContainer>
	)
}

const StyledContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-left: 47px;
	gap: 30px;
`
const RowContainer = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	margin-right: 2rem;
	margin-top: 0.5rem;
`

const ResponsiveImage = styled.img<{ width?: string }>`
	height: auto;
	max-width: ${({ width }) => (width ? width : "150px")};
	width: 100%;
`

const StyledIconContainer = styled.div`
	background-color: ${({ theme }) => theme.lightGb1};
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 150px;
	width: 150px;
`

export default ProductionAttachmentRow
