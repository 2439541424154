import { useNavigate } from "react-router-dom"
import styled, { useTheme } from "styled-components"
import mediaQuery from "../../util/mediaQuery"
import { useTranslation } from "react-i18next"
import { useRef, useState } from "react"
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material"
import useWindowDimensions from "../../hooks/useWindowDimensions"
import { useEffect } from "react"
import Card from "../atoms/Card"
import useGetVenues from "../../hooks/useVenues"
import { breakpoints } from "../../util/mediaQuery"

const App = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const containerRef = useRef<HTMLDivElement>(null)
	const [viewPosition, setViewPosition] = useState("left")
	const theme = useTheme()
	const { width } = useWindowDimensions()
	const [acceptableWidth, setAcceptableWidth] = useState<boolean>()
	const { selectedVenue, selectedStage, userCanViewVenues } = useGetVenues()

	const scroll = (newPosition: string) => {
		if (containerRef.current) {
			containerRef.current.scrollBy({
				left:
					newPosition === "left"
						? -containerRef.current.scrollWidth
						: containerRef.current.scrollWidth,
				behavior: "smooth",
			})
		}
	}

	// This is to handle an auto scroll happening on certain mobile devices
	useEffect(() => {
		if (width < breakpoints.sm) {
			window.scrollTo(0, 0)
		}
	}, [width])

	useEffect(() => {
		// when resizing, check if position has changed
		const check = width > 787 && width < 1580
		if (check !== acceptableWidth) {
			setAcceptableWidth(check)
			setViewPosition("left")
		}
	}, [acceptableWidth, width])

	const arrowStyles = { color: theme.primaryButtonColor, fontSize: 40 }

	const renderLeftArrow = () =>
		acceptableWidth &&
		viewPosition === "right" && (
			<BackwardScroll onClick={() => scroll("left")} canHover>
				<LeftArrow>
					<ArrowBackIos sx={arrowStyles} />
				</LeftArrow>
			</BackwardScroll>
		)

	const renderRightArrow = () =>
		acceptableWidth &&
		viewPosition === "left" && (
			<ForwardScroll onClick={() => scroll("right")} canHover>
				<RightArrow>
					<ArrowForwardIos sx={arrowStyles} />
				</RightArrow>
			</ForwardScroll>
		)

	const handleSroll = () => {
		if (containerRef.current) {
			const totalScrollWidth = containerRef.current.scrollWidth - containerRef.current.offsetWidth
			// if close to offset end, update position
			if (containerRef.current.scrollLeft > totalScrollWidth - 50 && viewPosition === "left") {
				setViewPosition("right")
			}
			if (containerRef.current.scrollLeft < 50 && viewPosition === "right") {
				setViewPosition("left")
			}
		}
	}

	return (
		<Container>
			{renderLeftArrow()}

			<Wrapper ref={containerRef} onScroll={handleSroll}>
				<StyledCard data-cy="dashboard-Profile" onClick={() => navigate("/profile")} canHover>
					<CardHeaderContainer>
						<CardHeader>{t("profile")}</CardHeader>
					</CardHeaderContainer>
					<CardDescription>{t("viewContactInformation")}</CardDescription>
				</StyledCard>
				{userCanViewVenues && (
					<StyledCard
						data-cy="dashboard-ViewTechPack"
						onClick={() => {
							if (selectedVenue?.id) {
								window.open(
									`${window.location}venue/${selectedVenue?.id || ""}/${selectedStage?.id || ""}`,
								)
							}
						}}
						canHover
					>
						<CardHeaderContainer>
							<CardHeader>{t("viewTechPack")}</CardHeader>
						</CardHeaderContainer>
						<CardDescription>{t("viewShareTechPack")}</CardDescription>
					</StyledCard>
				)}
				{userCanViewVenues ? (
					<StyledCard
						data-cy="dashboard-ManageProduction"
						onClick={() => navigate("/production")}
						canHover
					>
						<CardHeaderContainer>
							<CardHeader>{t("manageProduction")}</CardHeader>
						</CardHeaderContainer>
						<CardDescription>{t("editProductionDetails")}</CardDescription>
					</StyledCard>
				) : (
					<StyledCard>
						<CardHeaderContainer>
							<CardHeader>{t("noPermissionByObject", { object: "Venue" })}</CardHeader>
						</CardHeaderContainer>
					</StyledCard>
				)}
			</Wrapper>
			{renderRightArrow()}
		</Container>
	)
}
const Container = styled.div`
	background-color: ${({ theme }) => theme.body} !important;
	position: relative;
	margin-top: 4rem;
`
const Wrapper = styled.div`
	display: flex;
	flex-wrap: no-wrap;
	font-size: 18px;
	overflow-x: auto;
	padding: 0.5rem 0.8rem;
	position: relative;
	width: 100%;

	${mediaQuery("md")`
		align-items: center;
		font-size: 14px;
		flex-direction: column;
	`}
`

const StyledCard = styled(Card)`
	border-radius: 20px;
	cursor: pointer;
	flex: 0 0 auto;
	height: 400px;
	margin-bottom: 2rem;
	margin-right: 2rem;
	padding: 2rem;
	width: 300px;

	&:hover {
		box-shadow: 20px;
	}

	${mediaQuery("md")`
		margin-right: 0;
		width: 100%;
	`}
`
const CardHeaderContainer = styled.div`
	display: flex;
	justify-content: space-between;
	height: 30%;
`
const CardHeader = styled.div`
	font-family: "Roboto-Bold" !important;
	font-size: 1.5rem;
	font-weight: bold;

	${mediaQuery("md")`
		text-align: center;
	`}
`
const CardDescription = styled.p`
	color: ${({ theme }) => theme.text};
	font-size: 1rem;

	${mediaQuery("md")`
		text-align: center;
	`}
`
const ScrollContainer = styled(Card)`
	background: ${({ theme }) => theme.background} !important;
	border-radius: 50%;
	cursor: pointer;
	height: 60px;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	width: 60px;
	z-index: 1000;
`
const BackwardScroll = styled(ScrollContainer)`
	left: -40px;
`
const ForwardScroll = styled(ScrollContainer)`
	right: -40px;
`

const ArrowContainer = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	height: 100%;
	width: 100%;
`

const LeftArrow = styled(ArrowContainer)`
	margin-left: 8px;
`
const RightArrow = styled(ArrowContainer)`
	margin-left: 3px;
`

export default App
