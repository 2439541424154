import { createContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

export interface ConfirmCloseContextType {
	setShouldPreventClose: (shouldPreventClose: boolean) => void
	shouldPreventClose: boolean
}

export const ConfirmCloseContext = createContext<ConfirmCloseContextType>({
	setShouldPreventClose: () => {},
	shouldPreventClose: false,
})

export default function ConfirmCloseProvider({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element {
	const [shouldPreventClose, setShouldPreventClose] = useState(false)
	const { t } = useTranslation()
	useEffect(() => {
		const handleBeforeUnload = (event: Event) => {
			if (shouldPreventClose) {
				event.preventDefault()
				event.returnValue = true
			}
		}

		window.addEventListener("beforeunload", handleBeforeUnload)
		window.addEventListener("unload", handleBeforeUnload)
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload)
			window.removeEventListener("unload", handleBeforeUnload)
		}
	}, [shouldPreventClose, t])
	return (
		<ConfirmCloseContext.Provider value={{ setShouldPreventClose, shouldPreventClose }}>
			{children}
		</ConfirmCloseContext.Provider>
	)
}
