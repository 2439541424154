import { FC } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Button from "../atoms/Button"
import Card from "../atoms/Card"

const SaveButtonDrawer: FC<{
	show: boolean
	onSave: () => void
	disableButton: boolean
}> = ({ show, onSave, disableButton }) => {
	const { t } = useTranslation()

	return (
		<Container>
			<div className={`button-container ${show ? "open" : ""}`}>
				<Card>
					<Button data-cy="footer-SaveButton" disabled={disableButton} onClick={onSave}>
						{t("save")}
					</Button>
				</Card>
			</div>
		</Container>
	)
}

const Container = styled.div`
	& > .button-container {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		background: white;
		text-align: center;

		max-height: 0;
		transition: max-height 0.25s ease-out;

		button {
			margin: 1.5rem;
		}

		& .MuiPaper-root {
			border-radius: 0 !important;
		}
	}

	& > .button-container.open {
		max-height: 100vh;
	}
`

export default SaveButtonDrawer
