import { createContext, useState } from "react"

export type TokenContextType = [string, React.Dispatch<React.SetStateAction<string>>]

export const TokenContext = createContext<TokenContextType>(["", () => {}])

export default function TokenProvider({ children }: React.PropsWithChildren<unknown>): JSX.Element {
	return (
		<TokenContext.Provider value={useState(localStorage.token || "")}>
			{children}
		</TokenContext.Provider>
	)
}
