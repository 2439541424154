import Bugsnag from "@bugsnag/js"
import { getAnalytics, setUserId } from "firebase/analytics"
import { createContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation } from "@tanstack/react-query"
import { toast } from "react-toastify"
import useAxios from "../hooks/useAxios"

/**
 * This UserType does not match up with backend User schema (it was my earlier work on this project)
 */

const DEFAULT_USER: UserType = {
	firstName: "",
	lastName: "",
	nickName: "",
	preferredName: "",
	metadata: {
		email: "",
		phone: "",
	},
	userId: "",
}

export type UserType = {
	firstName: string
	lastName: string
	nickName: string
	preferredName: string
	metadata: {
		email?: string
		phone?: string
	}
	userId: string
}

export type UserContextType = {
	user: UserType
	userContextIsLoading: boolean
	getUserLoading: boolean
	profileId: string
	avatar: string
	myVenues: any[]
	setAvatar: (url: string) => void
	getUser: () => void
	setUser: (user: UserType) => void
	editUser: (edits: object) => void
	saveUser: ({
		firstName,
		lastName,
		phone,
		nickName,
		preferredName,
	}: {
		firstName: string
		lastName: string
		phone?: string
		nickName: string
		preferredName: string
	}) => void
	userInitials: string
}

export const UserContext = createContext<UserContextType>({
	user: DEFAULT_USER,
	userContextIsLoading: false,
	getUserLoading: true,
	profileId: "",
	avatar: "",
	myVenues: [],
	setAvatar: () => {},
	getUser: () => null,
	setUser: () => null,
	editUser: () => null,
	saveUser: () => null,
	userInitials: "",
})

export default function UserContextProvider({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element {
	const [user, setUser] = useState(DEFAULT_USER)
	const [userInitials, setUserInitials] = useState("")
	const [profileId, setProfileId] = useState("")
	const [avatar, setAvatar] = useState("")
	const [myVenues, setMyVenues] = useState([])
	const axios = useAxios()
	const { t } = useTranslation()
	const analytics = getAnalytics()

	const editUser = (edits: object) => setUser(u => ({ ...u, ...edits }))
	const editUserMetadata = (edits: object) =>
		setUser(u => ({ ...u, metadata: { ...u.metadata, ...edits } }))

	const { mutate: mutateGetProfile, isLoading: getUserLoading } = useMutation(
		async () => {
			return await axios.post("/venues/v1/profile/showByAttachedCriteria", {
				attachedToType: "_service",
				attachedToId: "masterVenue",
				includeAvatar: true,
			})
		},
		{
			onError: (error: string) => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: res => {
				if (res && res.data.success) {
					const {
						data: {
							metadata: { email, phone },
							name: { firstName, lastName, nickName, preferredName },
							userId,
							id,
							avatarUrl,
						},
					} = res.data

					setProfileId(id)
					setAvatar(avatarUrl)
					setUserId(analytics, id)

					//provide default "" if user.name object has undefined fields
					editUser({
						firstName: firstName || "",
						lastName: lastName || "",
						nickName: nickName || "",
						preferredName: preferredName || "",
						userId,
					})

					editUserMetadata({ email, phone })
					Bugsnag.setUser(userId, email, `${firstName} ${lastName}`)
				}
			},
		},
	)

	const { mutate: getMyVenues } = useMutation(
		async () => {
			return await axios.get("/venues/v1/venues")
		},
		{
			onError: (error: string) => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: res => {
				if (res && res.data.success) {
					setMyVenues(res.data.data)
				}
			},
		},
	)

	const handleSubmitProfile = async ({
		firstName,
		lastName,
		nickName,
		preferredName,
		phone,
	}: {
		firstName: string
		lastName: string
		nickName: string
		preferredName: string
		phone?: string
	}) => {
		const data = {
			name: { firstName, lastName, nickName, preferredName },
			metadata: { phone, email: user.metadata.email },
			attachedToType: "_service",
			attachedToId: "masterVenue",
		}

		return await axios.post(`/venues/v1/profile/updateByAttachedCriteria`, data).then(res => {
			if (res.data.success) {
				toast.success(t("updateSuccessful"))
				editUser({ firstName, lastName, nickName, preferredName, phone })
			}
		})
	}

	const { mutate: mutateSubmit, isLoading: postUserLoading } = useMutation(handleSubmitProfile, {
		onError: (error: string) => {
			toast.error(t("errorTryAgain"))
		},
	})

	useEffect(() => {
		if (localStorage.token) {
			mutateGetProfile()
			getMyVenues()
		}
	}, [getMyVenues, mutateGetProfile])

	// update initials on name change
	//  uses 1-2 outermost whitespace delimited words to grab first char as initials
	useEffect(() => {
		//https://stackoverflow.com/questions/33076177/getting-name-initials-using-js

		if (!user.preferredName) {
			setUserInitials("")
			return
		}

		var names = user.preferredName.split(" "),
			initials = names[0].substring(0, 1).toUpperCase()

		if (names.length > 1) {
			initials += names[names.length - 1].substring(0, 1).toUpperCase()
		}

		setUserInitials(initials)
	}, [user.preferredName])

	return (
		<UserContext.Provider
			value={{
				user,
				getUser: mutateGetProfile,
				setUser,
				saveUser: mutateSubmit,
				editUser,
				myVenues,
				userInitials,
				profileId,
				avatar,
				setAvatar,
				userContextIsLoading: getUserLoading || postUserLoading,
				getUserLoading,
			}}
		>
			{children}
		</UserContext.Provider>
	)
}
