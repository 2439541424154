import { Grid } from "@mui/material"
import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"
import Button from "../atoms/Button"
import FullScreen from "../atoms/FullScreen"

interface WhoopsTypes {
	error?: Error
	info?: React.ErrorInfo
	clearError?: () => void
}

type Props = WhoopsTypes

const Whoops = ({ clearError }: Props) => {
	const { t } = useTranslation()
	const handleClick = () => {
		if (clearError) {
			clearError()
		}
		window.location.href = "/"
	}
	return (
		<PageContainer>
			<Container>
				<h1>{t("errorTitle")}</h1>
				<p>{t("errorRefreshPage")}</p>
				<p>
					<Trans i18nKey="issueContinuesGetInTouch">
						<StyledLink href="mailto:support@eventric.com">Get In Touch</StyledLink>
					</Trans>
				</p>
				<StyledButton onClick={handleClick}>{t("home")}</StyledButton>
			</Container>
		</PageContainer>
	)
}

const PageContainer = styled(Grid)`
	display: flex;
	background-color: ${({ theme }) => theme.cardColor};
	color: ${({ theme }) => theme.text};
	height: 100vh !important;
	background-size: 40vh;
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 418 765'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none%3B%7D.cls-2%7Bfill:%23e8eff7%3B%7D.cls-3%7Bopacity:.3%3B%7D%3C/style%3E%3C/defs%3E%3Crect class='cls-1' width='418' height='765'/%3E%3Cg class='cls-3'%3E%3Cpath class='cls-2' d='M97 204h0c53.6 0 97 43.4 97 97v171c0 53.6-43.4 97-97 97h0c-53.6 0-97-43.4-97-97v-171c0-53.6 43.4-97 97-97Z'/%3E%3Cpath class='cls-2' d='M0 765v-86c0-53.6 43.4-97 97-97h0c53.6 0 97 43.4 97 97v86'/%3E%3Cpath class='cls-2' d='M194 0V94c0 53.6-43.4 97-97 97h0C43.4 191 0 147.6 0 94V0'/%3E%3Cpath class='cls-2' d='M305 8.5h0c53.6 0 97 43.4 97 97v171c0 53.6-43.4 97-97 97h0c-53.6 0-97-43.4-97-97V105.5c0-53.6 43.4-97 97-97Z'/%3E%3Cpath class='cls-2' d='M305 400h0c53.6 0 97 43.4 97 97v171c0 53.6-43.4 97-97 97h0c-53.6 0-97-43.4-97-97v-171c0-53.6 43.4-97 97-97Z'/%3E%3C/g%3E%3C/svg%3E");
	background-repeat: repeat;
`
const Container = styled(FullScreen)`
	display: flex;
	flex-direction: column !important;
	padding: 5rem 8rem;
`
const StyledButton = styled(Button)`
	width: 10rem;
`
const StyledLink = styled.a`
	color: ${({ theme }) => theme.text} !important;
	font-weight: bold;
	&:hover {
		color: ${({ theme }) => theme.primaryColor} !important;
	}
`

export default Whoops
