// lists of stage translation keys by subcategory

const PRODUCTION_STAGING_KEYS = [
	"dimensions",
	"proscenium",
	"stageWings",
	"paBays",
	"orchestraPit",
	"curtain",
	"fireCurtain",
	"steps",
	"trimHeight",
	"apron",
	"deckToGrid",
	"legs",
	"borders",
	"risers",
	"stage",
]

const PRODUCTION_LOADING_KEYS = ["access", "dockType", "deadCase", "forklifts", "loadComments"]

const PRODUCTION_RIGGING_KEYS = ["flySystem", "lineSets", "riggingComments"]

const PRODUCTION_POWER_KEYS = [
	"audioPower",
	"lightingPower",
	"otherPower",
	"showPower",
	"distToStage",
	"powerComments",
]

const PRODUCTION_SHOW_KEYS = ["houseLights", "climateControl", "fog", "pyro", "co2"]

const PRODUCTION_HOUSE_KEYS = [
	"fohPosition",
	"snakeRun",
	"barricade",
	"roof",
	"spots",
	"venueSeats",
]

const FACILITIES_FACILITY_KEYS = [
	"dressingRooms",
	"facilityNotes",
	"offices",
	"cateringLocale",
	"cateringBreakfast",
	"cateringLunch",
	"cateringDinner",
	"breakfast",
	"lunch",
	"dinner",
	"rehearsalSpace",
	"showers",
	"security",
	"merchandisers",
	"hallMerchPercent",
	"towels",
	"laundry",
	"runners",
]

const FACILITIES_PARKING_KEYS = [
	"truckParking",
	"busParking",
	"shorePower",
	"guestParking",
	"parkingComments",
]

const FACILITIES_INFORMATION_TECH_KEYS = [
	"wifiSsid",
	"username",
	"password",
	"circuit",
	"ip",
	"wanIP",
	"subnetMask",
	"gateway",
	"dns",
	"isp",
	"fees",
	"officeComments",
]
const EQUIPMENT_KEYS = [
	"audio",
	"audioComments",
	"backline",
	"lighting",
	"lightComments",
	"staging",
	"video",
	"videoComments",
	"other",
]
const LOGISTICS_KEYS = [
	"airportNotes",
	"groundTransport",
	"areaBusiness",
	"areaRestaurants",
	"areaHotels",
	"interest",
	"closestCity",
	"directions",
]
const LOCAL_CREW_KEYS = ["localUnion", "minimumIN", "minimumOUT", "penalties", "crewComments"]

export {
	PRODUCTION_STAGING_KEYS,
	PRODUCTION_LOADING_KEYS,
	PRODUCTION_RIGGING_KEYS,
	PRODUCTION_POWER_KEYS,
	PRODUCTION_SHOW_KEYS,
	PRODUCTION_HOUSE_KEYS,
	FACILITIES_FACILITY_KEYS,
	FACILITIES_PARKING_KEYS,
	FACILITIES_INFORMATION_TECH_KEYS,
	EQUIPMENT_KEYS,
	LOGISTICS_KEYS,
	LOCAL_CREW_KEYS,
}
