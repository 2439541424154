import { IconButton } from "@mui/material"
import { FC, useEffect, useState } from "react"
import styled, { useTheme } from "styled-components"
import Modal from "../../molecules/Modal"
import FormField from "../../atoms/FormField"
import { Trans, useTranslation } from "react-i18next"
import formatBytes from "../../../util/formatBytes"
import mediaQuery from "../../../util/mediaQuery"
import StyledIcon from "../../atoms/StyledIcon"
import compare from "../../../util/compare"
import useProduction from "../../../hooks/useProduction"
import Button from "../../atoms/Button"
import { trimFileName } from "./AddAttachmentModal"
import Checkbox from "../../atoms/Checkbox"
import { toast } from "react-toastify"
import { DEFAULT_ATTACHMENT } from "../../../context/production"
import { useQueryClient, useMutation } from "@tanstack/react-query"
import useAxios from "../../../hooks/useAxios"
import { ScaleLoader } from "react-spinners"
import FileIcon from "../../atoms/FileIcon"
import attachmentDisplayName from "../../../util/attachmentDisplayName"
import useDownloadAttachment from "../../../hooks/useDownloadAttachment"
import { isEntryInvalid } from "../../../util/formValidation"
import TimeStamp from "../../molecules/TimeStamp"
import parseStageAttachments from "../../../util/parseStageAttachments"

const EditAttachmentModal: FC = () => {
	const { t } = useTranslation()
	const axios = useAxios()
	const theme = useTheme()
	const queryClient = useQueryClient()
	const { downloadAttachment, isDownloadLoading } = useDownloadAttachment()

	const {
		state: {
			attachments,
			editingAttachment: { data, dataCategory, selectedIndex },
			stage: { id: stageId },
		},
		setState,
	} = useProduction()

	const open = !!(data && dataCategory)

	const theseAttachments: Attachment[] = (attachments[dataCategory] || {})[data] || []
	const thisAttachment = open ? theseAttachments[selectedIndex] : DEFAULT_ATTACHMENT

	const [showConfirmDelete, setShowConfirmDelete] = useState(false)

	const thisAttachmentDisplayName = attachmentDisplayName(thisAttachment.file, false)

	const closeModal = () => {
		setState((s: ProductionStateInterface) => ({
			...s,
			editingAttachment: { data: "", dataCategory: "", selectedIndex: 0 },
		}))
		setShowConfirmDelete(false)
		setHasChanges(false)
	}

	const { id: attachmentId, file, metadata } = thisAttachment

	const [filename, setFilename] = useState(trimFileName(file?.filename || ""))
	const [included, setIncluded] = useState<boolean>(
		typeof metadata?.includedInTechPack === "boolean" ? metadata.includedInTechPack : true,
	)

	const [hasChanges, setHasChanges] = useState(false)

	useEffect(() => {
		const metaIncluded =
			typeof metadata?.includedInTechPack === "boolean" ? metadata.includedInTechPack : true
		const changed = !compare(
			{ name: file?.metadata?.filename, includedInTechPack: metaIncluded },
			{ name: filename, includedInTechPack: included },
		)

		if (changed !== hasChanges) {
			setHasChanges(changed)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filename, included])

	const goBackAttachment = () =>
		setState((s: ProductionStateInterface) => {
			const newIndex =
				s.editingAttachment.selectedIndex < 1
					? theseAttachments.length - 1
					: s.editingAttachment.selectedIndex - 1

			return {
				...s,
				editingAttachment: {
					...s.editingAttachment,
					selectedIndex: newIndex,
				},
			}
		})

	const goNextAttachment = () =>
		setState((s: ProductionStateInterface) => {
			const max = theseAttachments.length - 1

			const newIndex =
				s.editingAttachment.selectedIndex >= max ? 0 : s.editingAttachment.selectedIndex + 1

			return {
				...s,
				editingAttachment: {
					...s.editingAttachment,
					selectedIndex: newIndex,
				},
			}
		})

	useEffect(() => {
		if (open) {
			setFilename(file?.metadata.filename || "")
			setIncluded(
				typeof metadata?.includedInTechPack === "boolean" ? metadata.includedInTechPack : true,
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [file?.metadata.filename, metadata?.includedInTechPack, open])

	const handleUpdate = async () => {
		const newData = await axios.post(
			`/venues/v1/attachment/object/venueStage/${stageId}/fileAttachments`,
			{
				includeUrls: true,
			},
		)
		const newAttachments = parseStageAttachments(newData.data.data)
		setState((s: ProductionStateInterface) => {
			return {
				...s,
				attachments: newAttachments,
			}
		})
	}

	const { mutate: updateInclude, isLoading: updateIncludeIsLoading } = useMutation(
		async () => {
			return await axios.patch(`/venues/v1/file-attachments/${attachmentId}`, {
				metadata: {
					...metadata,
					includedInTechPack: included,
				},
			})
		},
		{
			onSuccess: async res => {
				await handleUpdate()
				toast.success(t("attachmentUpdated"))
				setHasChanges(false)
			},
		},
	)

	const { mutate: onUpdate, isLoading: onUpdateIsLoading } = useMutation(
		async () => {
			return await axios.patch(`/venues/v1/files/${file?.id}`, {
				metadata: {
					...file?.metadata,
					filename,
				},
			})
		},
		{
			onSuccess: async (res: any) => {
				updateInclude()
			},
		},
	)

	const { mutate: onDelete, isLoading: onDeleteIsLoading } = useMutation(
		async () => {
			return await axios.delete(`/venues/v1/file-attachments/${attachmentId}`)
		},
		{
			onError: () => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: async () => {
				//update attachment metadata in state.attachments
				await queryClient.invalidateQueries([`${stageId}-attachments`])
				toast.success(t("attachmentDeleted"))
				closeModal()
			},
		},
	)

	// Modal Buttons get reused for 'deleteAttachment' modal
	return (
		<Modal
			open={open}
			onSubmit={showConfirmDelete ? onDelete : hasChanges ? onUpdate : closeModal}
			submitText={showConfirmDelete ? t("delete") : t("update")}
			submitButtonDisabled={showConfirmDelete ? false : !hasChanges || isEntryInvalid(filename)}
			submitButtonColor={showConfirmDelete ? "error" : "primary"}
			submitLoading={onUpdateIsLoading || onDeleteIsLoading || updateIncludeIsLoading}
			onClose={closeModal}
			showCancel={false}
			CustomButton={
				showConfirmDelete ? (
					<Button onClick={() => setShowConfirmDelete(false)}>{t("cancel")}</Button>
				) : (
					<Button onClick={() => setShowConfirmDelete(true)} color="error">
						{t("delete")}
					</Button>
				)
			}
		>
			{showConfirmDelete ? (
				<ShowDeleteContainer>
					<h2>
						<Trans
							i18nKey="confirmDelete"
							values={{
								item: filename,
							}}
						/>
					</h2>
				</ShowDeleteContainer>
			) : (
				<EditAttachmentContainer>
					<IconButton className="go-back" onClick={goBackAttachment}>
						<StyledIcon color="text" icon="chevron-left" />
					</IconButton>
					<IconButton className="go-next" onClick={goNextAttachment}>
						<StyledIcon color="text" icon="chevron-right" />
					</IconButton>
					<div className="attachment-preview">
						<div className="icon-container">
							{!file?.publicUrls?.urlPreview ? (
								<FileIcon filename={attachmentDisplayName(file)} />
							) : (
								<img src={file?.publicUrls.urlPreview} alt={thisAttachmentDisplayName} />
							)}
						</div>
						<p>
							{t("fileSize")}: {formatBytes(file?.filesize || 0)}
						</p>
					</div>
					<div className="attachment-details">
						<TimeStamp marginTop={4} />
						<div>{`${t(dataCategory.split("Data")[0])} / ${t(data)}`}</div>
						<FormField
							error={isEntryInvalid(filename)}
							value={filename}
							label={t("name")}
							inputProps={{ maxLength: 250 }}
							onChange={e => setFilename(e.target.value)}
						/>
						<Checkbox
							label={t("includeInTechPack")}
							checked={included || false}
							onChange={e => setIncluded(e.target.checked)}
						/>
						<div className="download-container">
							<div>{t("download")}:</div>
							<IconButton
								onClick={() => {
									const url =
										theseAttachments[selectedIndex].file?.publicUrls?.urlDownload ||
										theseAttachments[selectedIndex].file?.publicUrls?.url ||
										""
									downloadAttachment({
										url,
										filename: thisAttachmentDisplayName,
									})
								}}
								disabled={isDownloadLoading}
							>
								{isDownloadLoading ? (
									<ScaleLoader color={theme.primaryButtonColor} height="1rem" width="0.25rem" />
								) : (
									<StyledIcon icon="cloud-arrow-down" />
								)}
							</IconButton>
						</div>
					</div>
				</EditAttachmentContainer>
			)}
		</Modal>
	)
}

const ShowDeleteContainer = styled.div`
	text-align: center;
	margin: 0 auto;
`

const EditAttachmentContainer = styled.div`
	position: relative;
	display: flex;
	gap: 2rem;
	padding: 0 3rem;
	${mediaQuery("md")`
		align-items: center;
		flex-direction: column;
		gap: 1rem;
        padding: 0;
	`}

	& .go-back {
		position: absolute;
		bottom: 33%;
		left: 0;
	}

	& .go-next {
		position: absolute;
		bottom: 33%;
		right: 0;
	}

	& .attachment-preview {
		align-items: center;
		display: flex;
		flex-direction: column;
		width: 35%;
		${mediaQuery("md")`
            width: 100%;
        `}
	}

	& .attachment-details {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		align-items: start;
	}

	& .icon-container {
		display: flex;
		align-items: center;
		justify-content: center;

		margin-bottom: 2rem;
		width: 8rem !important;
		${mediaQuery("md")`
            height: 30% !important;
            width: 30% !important;
            margin-bottom: 0;
        `}

		img {
			width: 100%;
			max-height: 100%;
		}
	}

	& .MuiFormControlLabel-root {
		margin: 0;
	}

	& .download-container {
		display: flex;
		margin-bottom: 1rem;
		align-items: center;
	}
`

export default EditAttachmentModal
