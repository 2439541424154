import { Box, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Button from "../atoms/Button"
import FormField from "../atoms/FormField"
import { toast } from "react-toastify"
import useAxios from "../../hooks/useAxios"
import { LOCAL_STORAGE_REDIRECT_AFTER_LOGIN } from "../../context/axios"
import { useMutation } from "@tanstack/react-query"
import { useNavigate } from "react-router-dom"
import { KeyboardEvent } from "react"
import useUserContext from "../../hooks/useUserContext"

interface LoginTypes {
	email: string
	setEmail: (name: string) => void
	password: string
	setPassword: (password: string) => void
	toggleResetForm: () => void
}

const Login: React.FC<LoginTypes> = ({
	email,
	password,
	setEmail,
	setPassword,
	toggleResetForm,
}) => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const axios = useAxios()
	const { getUser } = useUserContext()

	const handleLogin = async () => {
		const data = {
			username: email,
			password,
		}
		return await axios.post("/venues/v1/login/masterVenue", data).then(res => {
			if (res?.data?.success) {
				// save token to local storage
				localStorage.token = res.data.data.token
				getUser()
			}
			else {
				throw Error('Login Failed');
			}
		})
	}

	const { mutate, isLoading } = useMutation(handleLogin, {
		onError: (error: string) => {
			toast.error(t("invalidEmailPasswordCombination"))
		},
		onSuccess: () => {
			// if token was invalid, hooks/axios interceptor sent user here and potentially had a redirect back to their previous page
			const redirectFromToken = localStorage.getItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN)

			if (redirectFromToken) {
				localStorage.removeItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN)
				navigate(redirectFromToken)
			} else {
				navigate("/")
			}
		},
	})

	return (
		<Box
			component="form"
			onKeyDown={(e: KeyboardEvent) => {
				if (e.key === "Enter") {
					e.preventDefault()
					email && password && !isLoading && mutate()
				}
			}}
		>
			<InputContainer item>
				<FormField
					variant="standard"
					label={t("email")}
					required
					id="emailTextField"
					data-cy="login-email"
					fullWidth
					value={email}
					onChange={e => setEmail(e.target.value)}
				/>
			</InputContainer>
			<InputContainer item>
				<FormField
					variant="standard"
					label={t("password")}
					type="password"
					required
					id="passwordTextField"
					data-cy="login-password"
					fullWidth
					value={password}
					onChange={e => setPassword(e.target.value)}
				/>
			</InputContainer>
			{/* <FormControlLabel
				control={
					<Checkbox
						checked={remember}
						onChange={toggleRemember}
						name="rememberMe"
						color="primary"
					/>
				}
				label={t("rememberMe")}
			/> */}
			<StyledButton
				fullWidth
				data-cy="submit-login-button"
				color="primary"
				variant="contained"
				onClick={e => {
					e.preventDefault()
					mutate()
				}}
				loading={isLoading}
				disabled={!email || !password || isLoading}
			>
				{t("login")}
			</StyledButton>
			<LoginText>
				<LoginNavLink className="textLink" data-cy="reset-password-link" onClick={toggleResetForm}>
					<>{t("resetPassword")}</>
				</LoginNavLink>
			</LoginText>
			<PrivacyPolicy
				href="https://www.eventric.com/privacy-policy/"
				target="_blank"
				rel="noopener noreferrer"
			>
				{t("privacyPolicy")}
			</PrivacyPolicy>
		</Box>
	)
}

const InputContainer = styled(Grid)`
	margin-bottom: 1rem !important;
`

const LoginText = styled(Grid)`
	font-size: 12px;
	line-height: 1.2;
	margin-top: 7%;
`

const LoginNavLink = styled(Grid)`
	color: ${({ theme }) => theme.primaryButtonColor};
	text-decoration: none;
	font-size: 12px;
	&:hover {
		color: ${({ theme }) => theme.primaryButtonHover};
		cursor: pointer;
	}
`
const StyledButton = styled(Button)`
	margin-top: 20px !important;
`

const PrivacyPolicy = styled.a`
	color: ${({ theme }) => theme.primaryButtonColor};
	text-decoration: none;
	font-size: 12px;
	&:hover {
		color: ${({ theme }) => theme.primaryButtonHover};
		cursor: pointer;
	}
`

export default Login
