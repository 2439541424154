import React, { Fragment } from "react"
import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"
import App from "./RouterRoot"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { library } from "@fortawesome/fontawesome-svg-core"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {
	faAnglesRight,
	faArrowLeft,
	faCartFlatbedSuitcase,
	faChevronRight,
	faChevronLeft,
	faChevronUp,
	faClipboardList,
	faCloudArrowDown,
	faHouse,
	faUser,
	faPeopleCarryBox,
	faPhotoFilm,
	faStopwatch,
	faTriangleExclamation,
	faWarning,
	faTrashCan,
	faXmark,
	faInfoCircle,
	faPencil,
	faSquarePlus,
	faLink,
	faSignOut,
	faHistory,
} from "@fortawesome/free-solid-svg-icons"
import AxiosProvider from "./context/axios"
import TokenProvider from "./context/token"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import semverUtils from "semver-utils"
import Whoops from "./components/views/Whoops"
import DarkModeProvider from "./context/darkmode"
import { OnlineStatusProvider } from "./context/onlineStatus"
import CssBaseline from "@mui/material/CssBaseline"
import { initializeApp } from "firebase/app"
import { getAnalytics } from "firebase/analytics"

const firebaseConfig = {
	apiKey: "AIzaSyCxJGiyKG22_23-6zSb-KazpFBXv6o-yVk",
	authDomain: "mastertourvenue.firebaseapp.com",
	projectId: "mastertourvenue",
	storageBucket: "mastertourvenue.appspot.com",
	messagingSenderId: "369835293673",
	appId: "1:369835293673:web:6abc3f8ccf131fd5ca2978",
	measurementId: "G-J148792J0V",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
getAnalytics(app)

const VERSION = require("../package.json").version
const semver = semverUtils.parse(VERSION)

Bugsnag.start({
	apiKey: "e44f26ef0e9c85493674d8cd966d39c5",
	appVersion: VERSION,
	plugins: [new BugsnagPluginReact()],
	releaseStage: (semver.release && semver.release.split(".")[0]) || "production",
})

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			// Exponential backoff of retry attempts
			retryDelay: attemptIndex => Math.min(3000 * 2 ** attemptIndex, 30000),
			staleTime: 45000,
		},
	},
})

library.add(
	faAnglesRight,
	faArrowLeft,
	faCartFlatbedSuitcase,
	faChevronRight,
	faChevronLeft,
	faChevronUp,
	faClipboardList,
	faCloudArrowDown,
	faHouse,
	faUser,
	faPeopleCarryBox,
	faPhotoFilm,
	faStopwatch,
	faTriangleExclamation,
	faWarning,
	faTrashCan,
	faXmark,
	faInfoCircle,
	faPencil,
	faSquarePlus,
	faLink,
	faSignOut,
	faHistory,
)

const Root = () => {
	const ErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React)

	return (
		<Fragment>
			<CssBaseline />
			<I18nextProvider i18n={i18n}>
				<ErrorBoundary FallbackComponent={Whoops}>
					<AxiosProvider>
						<QueryClientProvider client={queryClient}>
							<TokenProvider>
								<ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
								<ToastContainer theme="colored" position="bottom-center" />
								<DarkModeProvider>
									<OnlineStatusProvider>
										<App />
									</OnlineStatusProvider>
								</DarkModeProvider>
							</TokenProvider>
						</QueryClientProvider>
					</AxiosProvider>
				</ErrorBoundary>
			</I18nextProvider>
		</Fragment>
	)
}

export default Root
