import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Main from "./components/views/Main"
import Login from "./components/views/Login"
import ChangePassword from "./components/organisms/ChangePassword"
import useDarkMode from "./hooks/useDarkMode"
import getTheme from "./theme"
import { ThemeProvider } from "styled-components"
import useOnlineStatus from "./hooks/useOnlineStatus"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"
import TechPack from "./components/views/TechPack"

const AppRoutes = () => {
	const isOnline = useOnlineStatus()
	const [showToast, setShowToast] = useState(!isOnline)
	const { t } = useTranslation()
	useEffect(() => {
		const notify = () => {
			toast.error(t("checkConnection"), { autoClose: false, toastId: "checkConnection" })
		}
		if (!isOnline && !showToast) {
			setShowToast(true)
			notify()
		} else if (isOnline && showToast) {
			setShowToast(false)
			toast.dismiss()
		}
	}, [isOnline, showToast, setShowToast, t])
	const { darkMode } = useDarkMode()
	const theme = getTheme(darkMode)
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Routes>
					<Route path="/*" element={<Main />} />
					<Route path="/login" element={<Login />} />
					<Route path="/password/:code" element={<ChangePassword />} />
					<Route path={`/venue/:venueIdOrName/`} element={<TechPack />} />
					<Route path={`/venue/:venueIdOrName/:stageId`} element={<TechPack />} />
				</Routes>
			</Router>
		</ThemeProvider>
	)
}

export default AppRoutes
