import { FC, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import styled from "styled-components"
import { Personnel } from "../../context/personnel"
import useAxios from "../../hooks/useAxios"
import getInitials from "../../util/getInitials"
import UserAvatar from "../atoms/UserAvatar"

interface AVImageTypes {
	height: number
	width: number
	staff: Personnel
	techPack?: boolean
}

const AvatarImage: FC<AVImageTypes> = ({ height, width, staff, techPack, ...rest }) => {
	const [hasError, setError] = useState(false)

	const handleLoad = () => {
		setError(false)
	}
	const handleError = () => {
		setError(true)
	}

	const axios = useAxios()

	const { data, isLoading } = useQuery({
		queryKey: [`getUrl${staff.id}`],
		queryFn: () =>
			axios.post(`/venues/v1/attachment/object/Personnel/${staff.id}/files`, {
				includeUrls: true,
			}),
		enabled: !staff.imageUrl && !techPack,
	})

	const showImage = (!hasError && !isLoading) || staff.imageUrl
	const urls =
		data?.data.data.sort((a: any, b: any) =>
			new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1,
		) || []

	return (
		<UserAvatar
			{...rest}
			sx={{
				height,
				width,
			}}
		>
			{showImage && (urls.length || staff.imageUrl) ? (
				<ResponsiveImage
					src={staff.imageUrl ? staff.imageUrl : urls[0].publicUrls.url}
					alt={"personnel"}
					onLoad={handleLoad}
					onError={handleError}
					data-cy="avatar_ResponsiveImage"
				/>
			) : (
				<InitialText data-cy="avatar_UserInitials" size={width / 2.5} techPackStyles={techPack}>
					{getInitials(staff.person.name.displayName)}
				</InitialText>
			)}
		</UserAvatar>
	)
}

const ResponsiveImage = styled.img`
	height: auto;
	width: 100%;
`
const InitialText = styled.p<{ size: number; techPackStyles?: boolean }>`
	font-size: ${({ size }) => size}px;
	font-family: "Signika-Bold" !important;
	color: ${({ theme }) => theme.techPackHeaderText} !important;
`

export default AvatarImage
