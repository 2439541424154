import React, { useEffect } from "react"
import Grid from "@mui/material/Grid"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import Card from "../atoms/Card"
import Login from "../organisms/Login"
import ResetPassword from "../organisms/ResetPassword"
import mediaQuery from "../../util/mediaQuery"
import { useLocation } from "react-router-dom"
import { getAnalytics, logEvent } from "firebase/analytics"

const Initial = () => {
	const [showReset, setShowReset] = React.useState(false)
	const [email, setEmail] = React.useState("")
	const [password, setPassword] = React.useState("")
	const { t } = useTranslation()
	const location = useLocation()
	const analytics = getAnalytics()

	const toggleResetForm = () => {
		setShowReset(!showReset)
	}

	useEffect(() => {
		logEvent(analytics, "page_view", {
			page_title: "Login",
			page_location: window.location.href,
			page_path: location.pathname,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	return (
		<PageContainer className="login-background">
			<Grid item />
			<LoginFormBackgroundContainer>
				<LoginFormContainer>
					<Grid>
						<Grid item>
							<h3>{!showReset ? t("welcomeToMasterTourVenue") : t("resetPassword")}</h3>
						</Grid>
						{!showReset ? (
							<Login
								email={email}
								setEmail={setEmail}
								password={password}
								setPassword={setPassword}
								toggleResetForm={toggleResetForm}
							/>
						) : (
							<ResetPassword email={email} setEmail={setEmail} toggleResetForm={toggleResetForm} />
						)}
					</Grid>
				</LoginFormContainer>
			</LoginFormBackgroundContainer>
		</PageContainer>
	)
}

const LoginFormContainer = styled(Card)`
	padding-left: 8%;
	padding-right: 8%;
	padding-top: 15%;
	padding-bottom: 15%;
	width: 335px;
`

const LoginFormBackgroundContainer = styled(Grid)`
	display: flex;
	justify-content: right;
	padding-right: 3%;
	padding-left: 3%;
	background-color: ${({ theme }) => theme.primaryColor};
	height: 100vh;
	align-items: center;
	box-shadow: 2px 3px 12px 6px rgba(0, 0, 0, 0.14);
	${mediaQuery("sm")`
		width:  550px;
		justify-content: center;
	`}
`

const PageContainer = styled(Grid)`
	display: flex;
	justify-content: right;
	flex-direction: row;
	flex-wrap: no-wrap;
	align-content: stretch;
	background-color: ${({ theme }) => theme.body};
	height: 100vh !important;
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 418 765'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none%3B%7D.cls-2%7Bfill:%23e8eff7%3B%7D.cls-3%7Bopacity:.3%3B%7D%3C/style%3E%3C/defs%3E%3Crect class='cls-1' width='418' height='765'/%3E%3Cg class='cls-3'%3E%3Cpath class='cls-2' d='M97 204h0c53.6 0 97 43.4 97 97v171c0 53.6-43.4 97-97 97h0c-53.6 0-97-43.4-97-97v-171c0-53.6 43.4-97 97-97Z'/%3E%3Cpath class='cls-2' d='M0 765v-86c0-53.6 43.4-97 97-97h0c53.6 0 97 43.4 97 97v86'/%3E%3Cpath class='cls-2' d='M194 0V94c0 53.6-43.4 97-97 97h0C43.4 191 0 147.6 0 94V0'/%3E%3Cpath class='cls-2' d='M305 8.5h0c53.6 0 97 43.4 97 97v171c0 53.6-43.4 97-97 97h0c-53.6 0-97-43.4-97-97V105.5c0-53.6 43.4-97 97-97Z'/%3E%3Cpath class='cls-2' d='M305 400h0c53.6 0 97 43.4 97 97v171c0 53.6-43.4 97-97 97h0c-53.6 0-97-43.4-97-97v-171c0-53.6 43.4-97 97-97Z'/%3E%3C/g%3E%3C/svg%3E");
	background-repeat: repeat;
`

export default Initial
