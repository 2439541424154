import HoverSvg from "../components/atoms/HoverSvg"

const LeftChevron = ({ color, size }: IconTypes) => {
	const height = size / 1.38095
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 42 58"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				xmlns="http://www.w3.org/2000/svg"
				d="M2.125 26.25L26.125 2.25C27.625 0.625 30.25 0.625 31.75 2.25C33.375 3.75 33.375 6.375 31.75 7.875L10.625 29L31.75 50.25C33.375 51.75 33.375 54.375 31.75 55.875C30.25 57.5 27.625 57.5 26.125 55.875L2.125 31.875C0.5 30.375 0.5 27.75 2.125 26.25Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(LeftChevron)
