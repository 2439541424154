export type ImageDataType = { width: number; height: number; x: number; y: number }

const createImage = (url: string): Promise<HTMLImageElement> =>
	new Promise((resolve, reject) => {
		const image = new Image()
		image.addEventListener("load", () => resolve(image))
		image.addEventListener("error", error => reject(error))
		image.setAttribute("crossOrigin", "anonymous") // needed to avoid cross-origin issues on CodeSandbox
		image.src = url
	})

function getRadianAngle(degreeValue: number) {
	return (degreeValue * Math.PI) / 180
}

export default async function getCroppedImg(
	imageSrc: string,
	pixelCrop: ImageDataType,
	rotation = 0,
	fileType = "image/jpeg",
) {
	const image = await createImage(imageSrc)
	const canvas = document.createElement("canvas")
	const ctx = canvas.getContext("2d")

	const maxSize = Math.max(image.width, image.height)
	const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

	// set each dimensions to double largest dimension to allow for a safe area for the
	// image to rotate in without being clipped by canvas context
	canvas.width = safeArea
	canvas.height = safeArea

	// translate canvas context to a central location on image to allow rotating around the center.
	if (ctx) {
		ctx.translate(safeArea / 2, safeArea / 2)
		ctx.rotate(getRadianAngle(rotation))
		ctx.translate(-safeArea / 2, -safeArea / 2)

		// draw rotated image and store data.
		ctx.drawImage(image, safeArea / 2 - image.width * 0.5, safeArea / 2 - image.height * 0.5)

		const data = ctx.getImageData(0, 0, safeArea, safeArea)

		// set canvas width to final desired crop size - this will clear existing context
		canvas.width = pixelCrop.width
		canvas.height = pixelCrop.height

		// paste generated rotate image with correct offsets for x,y crop values.
		ctx.putImageData(
			data,
			0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
			0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y,
		)
	}

	function dataURItoBlob(dataURI: string) {
		var binary = atob(dataURI.split(",")[1])
		var array = []
		for (var i = 0; i < binary.length; i++) {
			array.push(binary.charCodeAt(i))
		}
		return new Blob([new Uint8Array(array)], { type: fileType })
	}

	return dataURItoBlob(canvas.toDataURL(fileType))
}
