import styled from "styled-components"
import { TextField, TextFieldProps } from "@mui/material"
import { forwardRef, ForwardRefRenderFunction } from "react"

const FormField: ForwardRefRenderFunction<HTMLDivElement, TextFieldProps> = (props, ref) => {
	return (
		<StyledTextField
			{...props}
			variant="standard"
			fullWidth
			InputLabelProps={{
				shrink: true,
			}}
			inputRef={ref}  // Correctly pass ref as inputRef to meet MUI's API requirements
			data-cy-component="Component-FormField"
		/>
	)
}

const StyledTextField = styled(TextField)`
	font-size: ${({ theme }) => theme.fontSize} !important;
	color: ${({ theme }) => theme.text} !important;

	& label {
		color: ${({ theme, error }) => (error ? theme.errorColor : theme.text)} !important;
	}
	& label.Mui-focused {
		color: ${({ theme }) => theme.primaryColor} !important;
	}
	& label.Mui-disabled {
		color: ${({ theme }) => theme.textLight} !important;
	}

	& .MuiInput-underline:after,
	& .MuiInput-underline:before {
		border-bottom: ${({ theme, error }) => `1px solid ${error ? theme.errorColor : theme.text}`} !important;
	}
	& input,
	textarea {
		color: ${({ theme }) => theme.text} !important;
	}
	& input.Mui-disabled,
	textarea.Mui-disabled {
		-webkit-text-fill-color: ${({ theme }) => theme.textLight};
	}
`

export default forwardRef(FormField) 
