import HoverSvg from "../components/atoms/HoverSvg"

const VideoFile = ({ color, size }: IconTypes) => {
	const height = size
	const width = size * 1.3333336

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 18 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 0H10.5V6C10.5 6.84375 11.1562 7.5 12 7.5H18V21C18 22.6875 16.6406 24 15 24H3C1.3125 24 0 22.6875 0 21V3C0 1.35938 1.3125 0 3 0ZM12 0L18 6H12V0ZM3 13.5V18C3 18.8438 3.65625 19.5 4.5 19.5H9C9.79688 19.5 10.5 18.8438 10.5 18V13.5C10.5 12.7031 9.79688 12 9 12H4.5C3.65625 12 3 12.7031 3 13.5ZM14.0625 18.6562C14.1562 18.75 14.2969 18.75 14.3906 18.75C14.7188 18.75 15 18.5156 15 18.1875V13.3594C15 13.0312 14.7188 12.75 14.3906 12.75C14.2969 12.75 14.1562 12.7969 14.0625 12.8906L12 14.25V17.25L14.0625 18.6562Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(VideoFile)
