import { FC, useState, useMemo } from "react"
import {
	FormControl,
	Select,
	MenuItem,
	ListSubheader,
	TextField,
	InputAdornment,
	InputLabel,
} from "@mui/material"
import { useTheme } from "styled-components"
import SearchIcon from "@mui/icons-material/Search"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

import mediaQuery from "../../../util/mediaQuery"
import useDarkMode from "../../../hooks/useDarkMode"

interface FieldNamesDropdownType {
	fieldData: Field[]
	setFilter: (filter: any) => void
}

const FieldNamesDropdown: FC<FieldNamesDropdownType> = ({ fieldData, setFilter }) => {
	const [selected, setSelected] = useState("")
	const [searchText, setSearchText] = useState("")
	const theme = useTheme()
	const { t } = useTranslation()
	const { darkMode } = useDarkMode()

	const containsText = (text: string, searchText: string) =>
		+text.toLowerCase().indexOf(searchText.toLowerCase()) > -1

	const selectionChangeHandler = (e: any) => {
		setSelected(e.target.value)
		setFilter((state: any) => ({
			...state,
			fieldName: e.target.value,
		}))
	}

	const handleClear = () => {
		setSelected("")
		setFilter((state: any) => ({
			...state,
			fieldName: undefined,
		}))
	}

	const fieldMap = useMemo(() => {
		const map: Record<string, Array<{ name: string; value: string }>> = {}
		fieldData.forEach(field => {
			if (field.category in map) {
				map[field.category].push({ name: field.name, value: `${field.type}_${field.fullName}` })
			} else {
				map[field.category] = [{ name: field.name, value: `${field.type}_${field.fullName}`}]
			}
		})
		return map
	}, [fieldData])

	return (
		<MainContainer>
			<StyledFormControl variant="outlined">
				{!selected ? (
					<Label shrink={false} id="test-select-label">
						<em>{t("fieldName")}</em>
					</Label>
				) : null}

				<StyledSelect
					notched={false}
					label="Field Names"
					labelId="test-select-label"
					value={selected}
					onChange={selectionChangeHandler}
					// Relating to search bar functionality
					onClose={() => setSearchText("")}
					// Gets rid of UI bug with window scroll bar
					MenuProps={{ autoFocus: false, disableScrollLock: true }}
				>
					<SearchBar>
						<SearchBarInput
							size="small"
							// Autofocus on textfield
							autoFocus
							placeholder="Type to search..."
							fullWidth
							InputProps={{
								sx: { color: theme.text },
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon sx={{ color: darkMode ? "#fff" : "#6666 " }} />
									</InputAdornment>
								),
							}}
							onChange={e => setSearchText(e.target.value)}
							onKeyDown={e => {
								if (e.key !== "Escape") {
									// Prevents autoselecting item while typing (default Select behaviour)
									e.stopPropagation()
								}
							}}
						/>
					</SearchBar>

					{Object.keys(fieldMap).map(
						(category, i) =>
							fieldMap[category].filter(option => containsText(option.name, searchText)).length >
								0 && [
								<FieldListMain
									className="mainHeader"
									disabled
									key={`select_${category}_${i}`}
									value={category}
								>
									{t(category)}
								</FieldListMain>,

								fieldMap[category]
									.filter(option => containsText(option.name, searchText))
									.map((field, index) => (
										<Item key={`select_${category}_${field.name}_${index}`} value={field.value}>
											{t(field.name)}
										</Item>
									)),
							],
					)}
				</StyledSelect>
			</StyledFormControl>
			<StyledButton onClick={handleClear}>{t("clear")}</StyledButton>
		</MainContainer>
	)
}

export default FieldNamesDropdown

const MainContainer = styled.div`
	margin-right: 5rem;
	min-width: 360px;
	${mediaQuery("lg")`
		margin-bottom: 1rem;
	`}
	${mediaQuery("sm")`
		min-width: 300px;
	`}
`

const Label = styled(InputLabel)`
	color: ${({ theme }) => theme.text} !important;
	> span {
		background-color: ${({ theme }) => theme.background} !important;
		color: ${({ theme }) => theme.text} !important;
	}
`
const SearchBar = styled(ListSubheader)`
	background-color: ${({ theme }) => theme.background} !important;
	padding-top: 1rem;
`

const SearchBarInput = styled(TextField)`
	background-color: ${({ theme }) => theme.background} !important;

	> input {
		color: ${({ theme }) => theme.text} !important;
	}
`

const FieldListMain = styled(MenuItem)`
	color: ${({ theme }) => theme.text} !important;
	opacity: 1 !important;
	background-color: ${({ theme }) => theme.background} !important;
	font-size: 1rem !important;
	font-weight: bold !important;
	margin: 0 !important;
	padding: 0 0 2px 5px !important;
`

const StyledFormControl = styled(FormControl)`
	width: 300px;
	margin-bottom: 20px;
	${mediaQuery("sm")`
		width: 220px;
	`}
`

const StyledSelect = styled(Select)`
	font-size: 1rem !important;
	font-weight: bold;
	background-color: ${props => props.theme.background} !important;
	color: ${({ theme }) => theme.text} !important;

	> svg {
		color: ${props => props.theme.text} !important;
	}
`
const Item = styled(MenuItem)`
	color: ${({ theme }) => theme.text} !important;
	background-color: ${({ theme }) => theme.background} !important;
	margin: 0;
	padding-left: 30px !important;
`

const StyledButton = styled.button`
	background-color: ${({ theme }) => theme.cardColor} !important;
	border: none;
	color: ${({ theme }) => theme.primaryButtonColor};
	padding: 10px 10px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	margin: 6px 0;
	cursor: pointer;
`
