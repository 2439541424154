import React from "react"
import { useTheme } from "styled-components"

const HoverSvg = (SVG: React.ElementType<HoverSvgProps>) => {
	const HoverSvgWrapper: React.FC<HoverSvgProps> = ({ color, size, ...rest }) => {
		const { darkGb1 } = useTheme()
		const defaultColor = color ?? darkGb1

		return <SVG color={defaultColor} size={size} {...rest} />
	}

	return HoverSvgWrapper
}

export default HoverSvg
