//ensure latlong matches MySQL column constraints
export const isValidLatLong = (latLong: string, isLatitude: boolean) => {
	if (!latLong) {
		return true
	}

	return isLatitude
		? /^^[-]?([0-8]?[0-9])\.(\d){0,7}$/.test(latLong)
		: /^[-]?(((1[0-7][0-9])|([0-9]?[0-9]))\.(\d){0,7}?)$/.test(latLong)
}

//used to format user input into a string that matches the regex above
//truncate decimal to 7 digits of precision , ref PR VWI-240


export const formatLatLong = (latLong: string) =>
//checks if the value has more than 7 digits of precision and only formats/rounds if it does
	isNaN(parseFloat(latLong)) ? "" : (parseFloat(latLong).toFixed(7) || "").toString().replace(/0*$/g, '')
