import HoverSvg from "../components/atoms/HoverSvg"

const RightChevron = ({ color, size }: IconTypes) => {
	const height = size / 1.38095
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 42 58"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				xmlns="http://www.w3.org/2000/svg"
				d="M39.75 26.25C41.375 27.75 41.375 30.375 39.75 31.875L15.75 55.875C14.25 57.5 11.625 57.5 10.125 55.875C8.5 54.375 8.5 51.75 10.125 50.25L31.25 29L10.125 7.875C8.5 6.375 8.5 3.75 10.125 2.25C11.625 0.625 14.25 0.625 15.75 2.25L39.75 26.25Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(RightChevron)
