/* eslint-disable react/jsx-props-no-spreading */
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { IconButton } from "@mui/material"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import useTheme from "../../../theme/index"
import styled from "styled-components"

// adapted from https://codesandbox.io/s/react-dnd-kit-swapping-test-forked-imkxc?file=/src/Grid/GridItem.jsx

export function SortableGridItem({ className, item, styles }) {
	const theme = useTheme()

	const { setNodeRef, attributes, listeners, transition, transform } = useSortable({
		id: item.id,
	})

	const style = {
		position: "relative",
		transition,
		transform: CSS.Transform.toString(transform),
		...styles,
	}

	return (
		<div data-cy={`SortableItem-${item.aut_hooks_name}`} ref={setNodeRef} item={item} style={style} className={className}>
			{item.child}
			<StyledHandle data-cy={`SortableItemHandle-${item.aut_hooks_name}`} {...attributes} {...listeners}>
				<DragIndicatorIcon sx={{ fill: theme.primaryButtonColor }} />
			</StyledHandle>
		</div>
	)
}

// touch-action: none; seems to be a magical styling for mobile devices to drag+sort smoothly
const StyledHandle = styled(IconButton)`
	touch-action: none;
	cursor: grab !important;
	position: absolute !important;
	top: 0.5rem;
	left: 0.5rem;
`
