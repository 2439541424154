export default function attachmentDisplayName(file: FileType | undefined, showExtension = true) {
	if (!file) return ""
	const {
		metadata: { filename },
		filename: originalFilename,
	} = file

	let displayName = originalFilename

	if (filename) {
		const extension = originalFilename.split(".").pop()
		displayName = `${filename}${showExtension ? `.${extension}` : ""}`
	}

	return displayName
}
