import { Routes, Route, useNavigate, useLocation } from "react-router-dom"
import MainLayout from "./MainLayout"
import Dashboard from "./Dashboard"
import Profile from "./Profile"
import Whoops from "./Whoops"
import Stages from "./Stages"
import VenueContextProvider from "../../context/venues"
import Production from "./Production"
import History from "./History"
import ConfirmCloseProvider from "../../context/confirmClose"
import Staff from "./Staff"
import UserContextProvider from "../../context/user"
import { useEffect } from "react"
import { LOCAL_STORAGE_REDIRECT_AFTER_LOGIN } from "../../context/axios"
import { getAnalytics, logEvent, setUserId } from "firebase/analytics"
import useUserContext from "../../hooks/useUserContext"

const AppRoutes = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const analytics = getAnalytics()
	const { user } = useUserContext()

	// user meant to be on a specific page, add redirect for no token after logging back in
	useEffect(() => {
		if (!localStorage.token) {
			localStorage.setItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN, location.pathname)
			navigate("/login")
		}
	}, [location, navigate])

	useEffect(() => {
		// if user is logged, update firebase with id
		if (localStorage.token && user.userId) {
			setUserId(analytics, user.userId)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user.userId])

	useEffect(() => {
		logEvent(analytics, "page_view", {
			page_title: location.pathname === "/" ? "Dashboard" : location.pathname,
			page_location: window.location.href,
			page_path: location.pathname,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	return (
		<UserContextProvider>
			<ConfirmCloseProvider>
				<VenueContextProvider>
					<Routes>
						<Route path="/" element={<MainLayout />}>
							<Route index element={<Dashboard />} />
							<Route path="/venuestage" element={<Stages />} />
							<Route path="/production" element={<Production />} />
							<Route path="history" element={<History />} />

							<Route path="/profile" element={<Profile />} />
							<Route path="/staff-crew" element={<Staff />} />
						</Route>
						<Route path="/*" element={<Whoops />} />
					</Routes>
				</VenueContextProvider>
			</ConfirmCloseProvider>
		</UserContextProvider>
	)
}

export default AppRoutes
