import { default as MUIModal } from "@mui/material/Modal"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Button from "../atoms/Button"
import { useTranslation } from "react-i18next"
import mediaQuery from "../../util/mediaQuery"
import { Checkbox, DialogTitle, FormControlLabel } from "@mui/material"
import { ReactNode, useEffect, useState } from "react"
import useDarkMode from "../../hooks/useDarkMode"

interface ModalTypes {
	children: React.ReactNode
	open: boolean
	onClose: () => void
	onSubmit: () => void
	submitText: string
	isAddModal?: boolean
	submitButtonColor?:
		| "primary"
		| "inherit"
		| "secondary"
		| "success"
		| "error"
		| "info"
		| "warning"
		| undefined
	submitLoading?: boolean
	showClose?: boolean
	showCancel?: boolean
	submitButtonDisabled?: boolean
	title?: string
	CustomButton?: ReactNode
	isLoading?: boolean
	onKeyPress?: (e: KeyboardEvent) => void
	maxWidth?: string
}

const Modal: React.FC<ModalTypes> = ({
	children,
	open,
	onClose,
	onSubmit,
	submitText,
	isAddModal,
	submitButtonColor = "primary",
	submitButtonDisabled = false,
	submitLoading = false,
	showClose = true,
	showCancel = true,
	title,
	CustomButton = null,
	isLoading,
	onKeyPress,
	maxWidth,
	...rest
}) => {
	const { t } = useTranslation()
	const { darkMode } = useDarkMode()
	const [isEmailInvite, setIsEmailInvite] = useState(false)

	useEffect(() => {
		if (typeof onKeyPress === "function") {
			window.addEventListener("keydown", onKeyPress)
			return () => window.removeEventListener("keydown", onKeyPress)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const sendEmailInvite = () => {
		setIsEmailInvite(!isEmailInvite)
	}

	return (
		<Container open={open} onClose={onClose} onSubmit={onClose}>
			<ModalBox maxWidth={maxWidth}>
				{showClose && (
					<div className="close-container">
						<div onClick={onClose}>
							<StyledIcon data-cy="modal-modalCloseOutX" icon="xmark" />
						</div>
					</div>
				)}
				{title ? <StyledTitle>{title}</StyledTitle> : null}
				<div className="modal-body">{children}</div>
				<div className="button-container-parent">
					<div className="button-container">
						{CustomButton}
						{showCancel && (
							<Button data-cy="modal-CancelButton" color="secondary" onClick={onClose}>
								{t("cancel")}
							</Button>
						)}

						<Button
							data-cy="modal-SubmitButton"
							onClick={onSubmit}
							loading={submitLoading || isLoading}
							color={submitButtonColor}
							disabled={submitButtonDisabled}
						>
							{submitText}
						</Button>
						{isAddModal ? (
							<StyledControlLabel
								value="start"
								control={
									<Checkbox
										checked={isEmailInvite}
										onChange={e => sendEmailInvite()}
										sx={{ color: darkMode ? "white" : "black" }}
									/>
								}
								label={t("sendInviteEmail")}
								labelPlacement="start"
							/>
						) : null}
					</div>
				</div>
			</ModalBox>
		</Container>
	)
}

const Container = styled(MUIModal)``

const StyledControlLabel = styled(FormControlLabel)`
	margin-left: 0 !important;
	${mediaQuery("sm")`
		margin-bottom: 1rem !important;
	`}
`

const StyledIcon = styled(FontAwesomeIcon)`
	cursor: pointer;
	color: ${({ theme }) => theme.errorColor};
	font-size: 1.5rem;
	padding: 0.5rem;
`

const StyledTitle = styled(DialogTitle)`
	font-weight: 900 !important;
	font-size: 1.75rem !important;
	display: flex;
	justify-content: center;
`

const ModalBox = styled.div<{ maxWidth?: string }>`
	background-color: ${({ theme }) => theme.background} !important;
	color: ${({ theme }) => theme.text};
	max-width: ${({ maxWidth }) => (maxWidth ? maxWidth : "80vw !important")};
	width: 70%;
	padding: 4rem;
	max-height: 75%;

	.modal-body {
		overflow-y: auto;
		margin-top: 1.5rem;
	}

	.close-container {
		position: absolute;
		top: 1rem;
		right: 2rem;
	}
	.button-container {
		display: flex;
		justify-content: space-around;
		max-width: 500px;
		width: 100%;
		${mediaQuery("sm")`
			margin-top: 0.5rem;
			flex-direction: column-reverse !important;
			align-items: center !important;
		`}
	}
	.button-container-parent {
		display: flex;
		justify-content: center;
	}

	display: flex;
	flex-direction: column;
	gap: 3rem;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	border: 0px solid #000;
	box-shadow: 24;
	border-radius: 2em;

	@media (orientation: landscape) {
		max-height: 100% !important;
		padding: 2rem !important;
	}

	${mediaQuery("sm")`
		width: 90%;
		padding: 9%;
	`}

	${mediaQuery("md")`
		gap: 1rem;
		width: 80%;
		padding: 8%;
	`}
`

export default Modal
