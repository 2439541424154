import Avatar from "@mui/material/Avatar"
import styled from "styled-components"

const StyledAvatar = styled(Avatar)`
	margin: auto;
	margin-bottom: 1%;
	font-family: "Signika-Bold" !important;
	background-color: ${({ theme }) => theme.techPackHeaderBg} !important;
`

export default StyledAvatar
