import useProduction from "../../../hooks/useProduction"
import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import styled from "styled-components"
import useAxios from "../../../hooks/useAxios"
import mediaQuery from "../../../util/mediaQuery"
import { orderBy } from "lodash"

const VenueLogoImage: FC = () => {
	const { t } = useTranslation()
	const axios = useAxios()
	const [hasError, setError] = useState(false)
	const {
		state: { venue },
	} = useProduction()
	const { data, isLoading } = useQuery(
		[`getFiles${venue.id}`],
		async () =>
			await axios.post(`/venues/v1/attachment/object/venue/${venue.id}/files`, {
				includeUrls: true,
			}),
		{ enabled: !!venue?.id },
	)
	const handleError = () => {
		setError(true)
	}
	const handleLoad = () => {
		setError(false)
	}
	if (isLoading) {
		return null
	}
	const urls = orderBy(data?.data.data, [item => item.file_attachments[0]?.createdDate], ["desc"])
	const canShowImage = !hasError && urls.length && urls[0].publicUrls.url

	return (
		<LogoContainer data-cy="production-VenueLogo">
			{canShowImage ? (
				<ResponsiveImage
					src={urls[0].publicUrls.url}
					alt={"logo"}
					onLoad={handleLoad}
					onError={handleError}
				/>
			) : (
				<Placeholder>
					{t("addVenueLogo")}
					{t("minVenueLogoSize")}
				</Placeholder>
			)}
		</LogoContainer>
	)
}

const LogoContainer = styled.div`
	align-items: center;
	border: 2px dashed ${({ theme }) => theme.text} !important;
	cursor: pointer;
	display: flex;
	justify-content: center;
	height: 225px;
	order: 2;
	width: 300px;

	${mediaQuery("md")`
		margin-top: 2rem;
	`}
`
const Placeholder = styled.div`
	align-items: center;
	border: 2px dashed ${({ theme }) => theme.text} !important;
	display: flex;
	height: 100%;
	justify-content: center;
	width: 100%;
`
const ResponsiveImage = styled.img`
	max-height: calc(100% + 6px);
	max-width: calc(100% + 6px);
	object-fit: contain;
`
export default VenueLogoImage
