import { useTranslation } from "react-i18next"
import useProduction from "../../hooks/useProduction"
import styled from "styled-components"

interface TimeStampTypes {
	marginLeft?: number
	marginTop?: number
	id?: string
	fieldName?: string
	modifiedBy?: string
	modifiedDate?: string | null
}

const TimeStamp = ({ marginLeft, marginTop, modifiedBy, modifiedDate }: TimeStampTypes) => {
	const { timeStampEnabled } = useProduction()

	const { t } = useTranslation()
	if (!modifiedDate || !modifiedBy)
		return (
			<>
				{timeStampEnabled && (
					<TimeStampContainer marginLeft={marginLeft} marginTop={marginTop}>
						<span>{t("noUpdate_plural")}</span>
					</TimeStampContainer>
				)}
			</>
		)
	const date = new Date(modifiedDate || "")
	const formattedDate = date.toLocaleString("en-US", {
		month: "numeric",
		day: "numeric",
		year: "2-digit",
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	})

	return (
		<>
			{timeStampEnabled && (
				<TimeStampContainer
					marginLeft={marginLeft}
					marginTop={marginTop}
					data-cy="TimestampContainer"
				>
					<span>{formattedDate}</span>
					<UpdatedBy>{t("updatedByName", { name: modifiedBy })}</UpdatedBy>
				</TimeStampContainer>
			)}
		</>
	)
}

const TimeStampContainer = styled.div<TimeStampTypes>`
	margin-left: ${props => props.marginLeft || 0}px;
	margin-top: ${props => props.marginTop || -30}px;
	text-align: left;
	font-style: italic;
	font-size: 0.7rem;
`
const UpdatedBy = styled.span`
	margin-left: 0.5rem;
`

export default TimeStamp
