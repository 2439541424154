import React, { useEffect, useState, useCallback } from "react"
import styled from "styled-components"
import LoadingPage from "../../views/LoadingPage"
import Paper from "../../atoms/Paper"
import mediaQuery from "../../../util/mediaQuery"
import FieldNamesDropdown from "./FieldNamesDropdown"
import UserNamesDropdown from "./UserNamesDropdown"
import { getSection } from "./fieldNames"

import { useTranslation } from "react-i18next"

import useAxios from "../../../hooks/useAxios"
import useVenues from "../../../hooks/useVenues"
import { Pagination } from "@mui/material"

const HistoryFields = () => {
	const axios = useAxios()
	const { t } = useTranslation()
	const [filter, setFilter] = useState<HistoryFilter>({ fieldName: undefined, userId: undefined })
	const [activeData, setActiveData] = useState<Array<HistoryEntry>>([])
	const [users, setUsers] = useState<Array<User>>([])
	const [fieldData, setFieldData] = useState<Array<Field>>([])
	const [activePage, setActivePage] = useState(1)
	const [pageCount, setPageCount] = useState(1)
	const [loading, setLoading] = useState(false)

	const { selectedVenue } = useVenues()

	useEffect(() => {
		if (selectedVenue) {
			setLoading(true)
			axios
				.post(`/venues/v1/data-change-logs/Venue/${selectedVenue.id}`, {
					includeUrls: true,
					page: activePage,
					filterUserId: filter.userId,
					filterFieldName: filter.fieldName,
				})
				.then(resp => {
					setPageCount(resp?.data?.data?.pagination.lastPage || 1)
					const usersForDropdown = resp?.data?.data?.users.map(
						({ id, name: { displayName } }: { id: string; name: { displayName: string } }) => ({
							id: id,
							displayName: displayName,
						}),
					)
					setUsers(usersForDropdown)
					const fields = resp?.data?.data?.fields.map(
						({
							name,
							labelTranslationKey,
							objectType,
						}: {
							name: string
							labelTranslationKey: string
							objectType: string
						}) => {
							const category = name.split(".")[0].split("Data")[0]
							return {
								name: labelTranslationKey,
								category: category === labelTranslationKey ? "" : category,
								fullPath: name,
								type: objectType,
							}
						},
					)
					setFieldData(fields)
					const data: HistoryEntry[] = resp?.data?.data?.dataChangeLogs.map((log: any) => {
						const field = log.fieldNameMetadata.name.split(".")
						const fieldCategory = field[0].split("Data")[0]
						return {
							id: log.id,
							date: log.actionDate,
							fieldName: field.length > 1 ? field[1] : null,
							fieldCategory: fieldCategory,
							actionDate: log.actionDate,
							createdBy: {
								id: log.user.id,
								displayName: log.user.name.displayName,
							},
							originalValue: log.originalValue,
							updatedValue: log.updatedValue,
						}
					})
					setActiveData(data)
					setLoading(false)
				})
		}
	}, [selectedVenue, axios, activePage, filter])

	const handlePageChange = useCallback(
		(event: React.ChangeEvent<unknown>, value: number) => {
			setActivePage(value)
		},
		[setActivePage],
	)

	const formatDateString = (dateString: string) => {
		const date = new Date(dateString)
		return date.toLocaleString("en-US", {
			month: "numeric",
			day: "numeric",
			year: "2-digit",

			hour: "numeric",
			minute: "numeric",
			hour12: true,
		})
	}

	return (
		<LoadingPage isLoading={loading}>
			<FormContainer>
				<Header>{t("history")}</Header>
				<DropDownContainer>
					<FieldNamesDropdown setFilter={setFilter} fieldData={fieldData} />
					<UserNamesDropdown setFilter={setFilter} users={users} />
				</DropDownContainer>
				{activeData.map(item => (
					<React.Fragment key={item.id}>
						<TimeStamp>
							<FieldContainer>
								<BoldSpan>{t(item.fieldCategory)} </BoldSpan>
								{item.fieldName && <BoldSpan>{">"}</BoldSpan>}

								{getSection(item.fieldName) ? (
									<>
										<BoldSpan>{t(getSection(item.fieldName))}</BoldSpan>
										<BoldSpan>{">"}</BoldSpan>
									</>
								) : null}

								{/* Filtered by field name  */}
								<BoldSpan>{t(item.fieldName)}:</BoldSpan>
							</FieldContainer>
							<UpdatedByDateContainer>
								<DateTimeContainer>
									<span>{formatDateString(item.date)}</span>
								</DateTimeContainer>
								<UpdatedByContainer>
									<UnderLinedSpan>
										{t("updatedByName", { name: item.createdBy.displayName })}
									</UnderLinedSpan>
								</UpdatedByContainer>
							</UpdatedByDateContainer>
						</TimeStamp>
						<UpdatedMainContainer>
							<UpdatedTitleContainer>
								<h3>{t("updated")}:</h3>
							</UpdatedTitleContainer>
							{/* Updated Value  */}
							<UpdatedDataContainer>
								<p>{item.updatedValue}</p>
							</UpdatedDataContainer>
						</UpdatedMainContainer>
						{/* TODO: Reimplement file updates once the API is updated */}
						{/* {(item.filesAdded || item.filesRemoved) && (
									<FilesMainContainer>
										<FilesLabelContainer>
											<>
												{item.filesAdded && <p>{t("filesAdded")}:</p>}
												{item.filesRemoved && <p>{t("filesRemoved")}:</p>}

												<p>{t("includedInTechPack")}</p>
											</>
										</FilesLabelContainer>
										<FilesDataContainer>
											<p>{item.filesAdded || item.filesRemoved}</p>
										</FilesDataContainer>
									</FilesMainContainer>
								)} */}
						<PreviousMainContainer>
							<PreviousTitleContainer>
								<h3>{t("previous")}:</h3>
							</PreviousTitleContainer>
							{/* Original Value */}
							<PreviousDataContainer>
								<p>{item.originalValue !== "null" ? item.originalValue : "-"}</p>
							</PreviousDataContainer>
						</PreviousMainContainer>
					</React.Fragment>
				))}
				{activeData.length === 0 && <NoDataContainer>No Data Available</NoDataContainer>}
				{activeData.length > 0 && (
					<ResultsContainer>
						<Pagination count={pageCount} page={activePage} onChange={handlePageChange} />
						<p>
							{t("resultsPagination", {
								first: activeData[0].id,
								last: activeData[activeData.length - 1].id,
								total: activeData.length,
							})}
						</p>
					</ResultsContainer>
				)}
			</FormContainer>
		</LoadingPage>
	)
}

export default HistoryFields

const FormContainer = styled(Paper)`
	background-color: ${({ theme }) => theme.cardColor} !important;
	margin-bottom: 2rem;
	padding: 2rem;
	min-width: 9rem;
`

const DropDownContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 1rem;
	${mediaQuery("lg")`
		flex-direction: column;
	`}
`

const Header = styled.h1`
	color: ${({ theme }) => theme.text};
	font-size: 1.5rem;
	font-weight: 600;
	margin: 0 0 20px 0;
	padding: 0;
`
const TimeStamp = styled.div`
	display: flex;
	flex-direction: row;
	min-width: 560px;
	color: ${({ theme }) => theme.text};
	span {
		padding: 0 0.2rem;
		font-size: 0.8rem;
		color: ${({ theme }) => theme.timeStampColor};
	}
	${mediaQuery("md")`
		flex-direction: column;
	`}
`
const FieldContainer = styled.div`
	display: flex;
`

const DateTimeContainer = styled.div`
	display: flex;
`

const UpdatedByDateContainer = styled.div`
	display: flex;
	${mediaQuery("sm")`
		flex-direction: column;
		margin-bottom: 0.5rem;
	`}
`

const UpdatedByContainer = styled.div`
	display: flex;
`

const UpdatedMainContainer = styled.div`
	display: flex;
	flex-direction: row;
	${mediaQuery("md")`
		flex-direction: column;
	`}
`
const UpdatedTitleContainer = styled.div`
	display: flex;
	margin-left: 3rem;
	color: ${({ theme }) => theme.text};

	h3 {
		font-weight: 400;
		font-size: 1.1rem;
		margin: 0 5px;
		text-align: right;
	}
	${mediaQuery("md")`
		margin-left: 0;
	`}
`
const UpdatedDataContainer = styled.div`
	p {
		color: ${({ theme }) => theme.text};
		margin: 0 5px;
		margin-left: 10px;
		margin-top: 3px;
		font-size: 0.9rem;
		line-height: 1.1rem;
		white-space: pre-wrap;
	}
`

const NoDataContainer = styled.div`
	color: ${({ theme }) => theme.text};
`

// const FilesMainContainer = styled.div`
// 	margin-top: 1rem;
// 	display: flex;
// 	flex-direction: row;
// `
// const FilesLabelContainer = styled.div`
// 	color: ${({ theme }) => theme.text};
// 	font-size: 0.8rem;
// 	font-weight: 400;
// 	margin-left: -1rem;
// 	margin-right: 3px;
// 	width: 10rem;

// 	p {
// 		text-align: right;
// 		margin: 0;
// 		text-decoration: underline;
// 		${mediaQuery("md")`
// 			text-align: left;
// 			margin-left: 1rem;
// 		`}
// 	}
// 	${mediaQuery("md")`
// 		width: 7rem;
// 		margin-left: -0.5rem;
// 	`}
// `
// const FilesDataContainer = styled.div`
// 	p {
// 		color: ${({ theme }) => theme.text};
// 		margin: 0 5px;
// 		font-size: 0.8rem;
// 		font-style: italic;
// 		${mediaQuery("md")`
// 			margin-left: 0;
// 		`}
// 	}
// `
const PreviousMainContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 2rem;
	${mediaQuery("md")`
		flex-direction: column;
	`}
`
const PreviousTitleContainer = styled.div`
	display: flex;
	margin-left: 3rem;
	color: #959595;

	h3 {
		font-weight: 400;
		font-size: 1.1rem;
		margin: 0 5px;
		text-align: right;
	}
	${mediaQuery("md")`
		margin-left: 0;
	`}
`
const PreviousDataContainer = styled.div`
	p {
		color: #959595;
		margin: 0 5px;
		margin-left: 10px;
		margin-top: 5px;
		font-size: 0.9rem;
		white-space: pre-wrap;
	}
`

const UnderLinedSpan = styled.span`
	text-decoration: underline;
`

const BoldSpan = styled.span`
	font-weight: 600;
`
const ResultsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	justify-content: center;
	p {
		font-size: 1rem;
		color: ${({ theme }) => theme.text};
	}
`
