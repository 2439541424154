/**
 * test case when we get to it:
 *
 * objectCheckEmptyRecursive({})
 * assert true
 *
 * objectCheckEmptyRecursive({a: {b: {c: "data"}}})
 * assert false
 */

//function that recursively checks object for any value that is not falsey
//example: check Stage object for empty values to show/hide entire section
export default function isObjectEmptyRecursive(obj: any) {
	for (let key in obj) {
		if (obj[key] instanceof Object === true) {
			//check recursion
			if (isObjectEmptyRecursive(obj[key]) === false) return false
		} else {
			//check value
			if (obj[key] && obj[key].length !== 0) return false
		}
	}
	return true
}
