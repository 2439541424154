import { useRef } from "react"
import { useMutation } from "@tanstack/react-query"
import useAxios from "../../hooks/useAxios"
import VenueLogoImage from "../organisms/production/VenueLogoImage"
import { useQueryClient } from "@tanstack/react-query"
import useProduction from "../../hooks/useProduction"
import styled from "styled-components"
import mediaQuery from "../../util/mediaQuery"

const VenueLogo = () => {
	const imageInputRef = useRef<HTMLInputElement>(null)
	const axios = useAxios()
	const queryClient = useQueryClient()
	const {
		state: { venue },
	} = useProduction()

	const showFileDialog = () => {
		imageInputRef?.current?.click()
	}

	const handlePhotoUpdate = (event: any) => {
		// click item
		const fileObject = event.target?.files?.[0]
		if (!fileObject) {
			// something happened
			return
		}
		getUrl(fileObject)
	}
	const { mutate: getUrl } = useMutation(
		async (fileObject: File) => {
			return await axios.post("/venues/v1/files", {
				filename: fileObject?.name,
				attachedToType: "venue",
				attachedToId: venue.id,
				category: "logo",
				replaceExisting: true,
				fileAttachmentMetadata: {
					includedInTechPack: true,
				},
			})
		},
		{
			onSuccess: async (res, file) => {
				const { id, presignedUrl } = res.data.data
				uploadImage({ presignedUrl, id, file })
			},
		},
	)
	const { mutate: uploadImage } = useMutation(
		async ({ presignedUrl, file }: { presignedUrl: string; id: string; file: File }) => {
			return await fetch(presignedUrl, {
				body: file,
				method: "PUT",
			})
		},
		{
			onSuccess: async (_res, params: { id: string }) => {
				markUploaded(params.id)
			},
		},
	)
	const { mutate: markUploaded } = useMutation(
		async (id: string) => {
			return await axios.patch(`/venues/v1/files/${id}`, {
				disk: "s3",
			})
		},
		{
			onSuccess: () => {
				queryClient.invalidateQueries([`getFiles${venue.id}`])
				if (imageInputRef?.current) {
					// clear file input
					imageInputRef.current.value = ""
				}
			},
		},
	)

	return (
		<Container>
			<div onClick={showFileDialog}>
				<VenueLogoImage />
				<input
					disabled={!venue.id}
					ref={imageInputRef}
					type="file"
					style={{ display: "none" }}
					accept="image/*"
					onChange={handlePhotoUpdate}
				/>
			</div>
		</Container>
	)
}
const Container = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	${mediaQuery("md")`
		justify-content: center;
	`}
`

export default VenueLogo
