import { FC, useState } from "react"
import { Box, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Button from "../atoms/Button"
import FormField from "../atoms/FormField"
import { toast } from "react-toastify"
import { useMutation } from "@tanstack/react-query"
import axios from "axios"
import { KeyboardEvent } from "react"

const { REACT_APP_API_URL } = process.env
interface ResetPasswordTypes {
	email: string
	setEmail: (email: string) => void
	toggleResetForm: () => void
}

const ResetPassword: FC<ResetPasswordTypes> = ({ email, setEmail, toggleResetForm }) => {
	const [notification, setNotification] = useState("")
	const { t } = useTranslation()
	const { mutate: handleResetSend, isLoading } = useMutation(
		async () =>
			await axios.post(`${REACT_APP_API_URL}/venues/v1/password/reset`, { username: email }),
		{
			onError: (error: string) => {
				toast.error(t("unregisteredAccount"))
			},

			onSuccess: res => {
				toast.success(t("checkEmail"))
				setNotification("checkEmail")
			},
		},
	)

	return (
		<Box
			component="form"
			onKeyDown={(e: KeyboardEvent) => {
				if (e.key === "Enter") {
					e.preventDefault()
					email && !isLoading && handleResetSend()
				}
			}}
		>
			<ResetNameInput
				variant="standard"
				label={t("email")}
				required
				id="emailTextField"
				data-cy="reset-password-email-input"
				fullWidth
				value={email}
				onChange={e => setEmail(e.target.value)}
			/>
			{notification === "checkEmail" ? (
				<CheckEmailNotification>{t(notification)}</CheckEmailNotification>
			) : null}
			<Button
				loading={isLoading}
				fullWidth
				color="primary"
				variant="contained"
				onClick={() => handleResetSend()}
			>
				{t("sendResetEmail")}
			</Button>

			<ReturnToLoginLink onClick={toggleResetForm}>{t("returnToLogIn")}</ReturnToLoginLink>
		</Box>
	)
}

const CheckEmailNotification = styled(Grid)`
	margin-bottom: 15px;
`

const ResetNameInput = styled(FormField)`
	margin-bottom: 20px !important;
`
const ReturnToLoginLink = styled.p`
	color: ${({ theme }) => theme.primaryButtonColor};
	margin-top: 20px;
	text-decoration: none;
	font-size: 12px;
	&:hover {
		color: ${({ theme }) => theme.primaryButtonHover};
		cursor: pointer;
	}
`

export default ResetPassword
