import HoverSvg from "../components/atoms/HoverSvg"

const Equipment = ({ color, size }: IconTypes) => {
	const height = size
	const width = size * 1.0357

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 20 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7 4H13V2H7V4ZM0 16V11H4V12H6V11H14V12H16V11H20V16H0ZM0 10V6C0 5.45 0.195833 4.97917 0.5875 4.5875C0.979167 4.19583 1.45 4 2 4H5V2C5 1.45 5.19583 0.979167 5.5875 0.5875C5.97917 0.195833 6.45 0 7 0H13C13.55 0 14.0208 0.195833 14.4125 0.5875C14.8042 0.979167 15 1.45 15 2V4H18C18.55 4 19.0208 4.19583 19.4125 4.5875C19.8042 4.97917 20 5.45 20 6V10H16V8H14V10H6V8H4V10H0Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Equipment)
