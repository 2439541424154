import HoverSvg from "../components/atoms/HoverSvg"

const Contact = ({ color, size }: IconTypes) => {
	const height = size * 1.04
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 23 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3.5 0H17C18.6406 0 20 1.35938 20 3V21C20 22.6875 18.6406 24 17 24H3.5C1.8125 24 0.5 22.6875 0.5 21V3C0.5 1.35938 1.8125 0 3.5 0ZM8.75 13.5C6.64062 13.5 5 15.1875 5 17.25C5 17.6719 5.32812 18 5.75 18H14.75C15.125 18 15.5 17.6719 15.5 17.25C15.5 15.1875 13.8125 13.5 11.75 13.5H8.75ZM7.25 9C7.25 10.0781 7.8125 11.0625 8.75 11.625C9.64062 12.1406 10.8125 12.1406 11.75 11.625C12.6406 11.0625 13.25 10.0781 13.25 9C13.25 7.96875 12.6406 6.98438 11.75 6.42188C10.8125 5.90625 9.64062 5.90625 8.75 6.42188C7.8125 6.98438 7.25 7.96875 7.25 9ZM23 3.75V6.75C23 7.17188 22.625 7.5 22.25 7.5C21.8281 7.5 21.5 7.17188 21.5 6.75V3.75C21.5 3.375 21.8281 3 22.25 3C22.625 3 23 3.375 23 3.75ZM22.25 9C22.625 9 23 9.375 23 9.75V12.75C23 13.1719 22.625 13.5 22.25 13.5C21.8281 13.5 21.5 13.1719 21.5 12.75V9.75C21.5 9.375 21.8281 9 22.25 9ZM23 15.75V18.75C23 19.1719 22.625 19.5 22.25 19.5C21.8281 19.5 21.5 19.1719 21.5 18.75V15.75C21.5 15.375 21.8281 15 22.25 15C22.625 15 23 15.375 23 15.75Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Contact)
