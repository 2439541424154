import { useState, useMemo, useEffect, RefObject } from "react"

const useIsInViewport = (ref: RefObject<HTMLElement>) => {
	const [isIntersecting, setIsIntersecting] = useState(false)

	const observer = useMemo(
		() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)),
		[],
	)

	useEffect(() => {
		if (ref && ref.current) {
			observer.observe(ref.current)
		}

		return () => {
			observer.disconnect()
		}
	}, [ref, observer])

	return isIntersecting
}

export default useIsInViewport
