import HoverSvg from "../components/atoms/HoverSvg"

const Hamburger = ({ color, size }: IconTypes) => {
	const height = size * 0.857
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 28 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M0 2C0 0.9375 0.875 0 2 0H26C27.0625 0 28 0.9375 28 2C28 3.125 27.0625 4 26 4H2C0.875 4 0 3.125 0 2ZM0 12C0 10.9375 0.875 10 2 10H26C27.0625 10 28 10.9375 28 12C28 13.125 27.0625 14 26 14H2C0.875 14 0 13.125 0 12ZM28 22C28 23.125 27.0625 24 26 24H2C0.875 24 0 23.125 0 22C0 20.9375 0.875 20 2 20H26C27.0625 20 28 20.9375 28 22Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Hamburger)
