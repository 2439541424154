import { DialogTitle } from "@mui/material"
import { FC, useState, useRef, DragEvent, useEffect } from "react"
import styled, { useTheme } from "styled-components"
import Modal from "../../molecules/Modal"
import FormField from "../../atoms/FormField"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import formatBytes from "../../../util/formatBytes"
import mediaQuery from "../../../util/mediaQuery"
import { toast } from "react-toastify"
import Checkbox from "../../atoms/Checkbox"
import useAxios from "../../../hooks/useAxios"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import useProduction from "../../../hooks/useProduction"
import FileIcon from "../../atoms/FileIcon"
import { isEntryInvalid } from "../../../util/formValidation"

export const trimFileName = (name: string) => {
	const parts = name.split(".")
	return parts[0]
}

const AddAttachmentModal: FC = () => {
	const {
		state: {
			addingAttachment: { data, dataCategory },
			stage,
		},
		setState,
	} = useProduction()
	const queryClient = useQueryClient()

	const open = !!data

	const [file, setFile] = useState<File>()
	const [filename, setFilename] = useState(trimFileName(file?.name || ""))
	const [dragActive, setDragActive] = useState(false)
	const [includeFile, setIncludeFile] = useState(true)
	const [disableSubmit, setDisableSubmit] = useState(false)
	const fileInputRef = useRef<HTMLInputElement>(null)
	const theme = useTheme()
	const { t } = useTranslation()
	const axios = useAxios()

	const closeModal = () => {
		setDisableSubmit(false)
		setState((s: ProductionStateInterface) => ({ ...s, addingAttachment: { data: "", dataCategory: "" } }))
	}

	const handleError = () => {
		toast.error(t("errorTryAgain"))
		setDisableSubmit(false)
	}

	const handleShowDialog = () => {
		fileInputRef?.current?.click()
	}

	useEffect(() => {
		//unset file on modal close
		if (!open) {
			setFile(undefined)
		}
	}, [open])

	useEffect(() => {
		if (file?.name) {
			setFilename(trimFileName(file?.name || ""))
		}
	}, [file])

	const handleSelectedFile = (event: any) => {
		// click item
		const fileObject = event.target?.files?.[0]

		// drag/drop item
		const dragFileObject = event.dataTransfer?.files?.[0]
		if (event.dataTransfer?.files?.length > 1) {
			toast.warning(t("selectOneFileAtATime"))
		}

		const newFile = fileObject ? fileObject : dragFileObject

		if (!newFile) {
			// something happened
			return
		}

		setFile(newFile)
	}

	const handleDrag = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
		if (e.type === "dragenter" || e.type === "dragover") {
			setDragActive(true)
		} else if (e.type === "dragleave") {
			setDragActive(false)
		}
	}

	const handleDrop = (e: DragEvent<HTMLDivElement>) => {
		e.preventDefault()
		e.stopPropagation()
		setDragActive(false)
		setFile(undefined)
		handleSelectedFile(e)
	}

	const addAttachmentToState = (attachment: Attachment) => {
		const attachmentWithSize = { ...attachment, filesize: file?.size || 0 }

		setState((s: ProductionStateInterface) => ({
			...s,
			attachments: {
				...s.attachments,
				[dataCategory]: {
					...(s.attachments[dataCategory] || {}),
					[data]: [
						...(s.attachments[dataCategory] && s.attachments[dataCategory][data]
							? s.attachments[dataCategory][data]
							: []),
						attachmentWithSize,
					],
				},
			},
		}))
	}

	// 1) send data and get back upload url
	// adding attachedTo data creates attachment
	const { mutate: mutateInitUpload, isLoading: initialUploadLoading } = useMutation(
		async () => {
			return await axios.post("/venues/v1/files", {
				filename: file?.name,
				attachedToType: "venueStage",
				attachedToId: stage.id,
				category: `production.${dataCategory}.${data}`,
				metadata: {
					filename,
				},
			})
		},
		{
			onError: handleError,
			onSuccess: async res => {
				const { presignedUrl, id } = res.data.data
				const attachment = res.data.data.file_attachments[0]
				mutateUpload({ presignedUrl, id, attachment })
				addAttachmentToState(res.data.data)
			},
		},
	)
	// 2) upload file to bucket url
	const { mutate: mutateUpload, isLoading: uploadLoading } = useMutation(
		async ({ presignedUrl, id, attachment }: { presignedUrl: string; id: string, attachment: Attachment }) => {
			return await fetch(presignedUrl, {
				body: file || null,
				method: "PUT",
				headers: { "Content-Type": file?.type || "" },
			})
		},
		{
			onError: handleError,
			onSuccess: async (_res, { id, attachment }) => {
				setUploadedFileAsUploaded({id, attachment})
			},
		},
	)
	// 3) update file by id that is has uploaded
	const { mutate: setUploadedFileAsUploaded } = useMutation(
		async ({id, attachment}: { id: string, attachment: Attachment }) => {
			return await axios.patch(`/venues/v1/files/${id}`, {
				disk: "s3",
			})
		},
		{
			onError: handleError,
			onSuccess: async (res, {id, attachment}) => {
				updateInclude({id, attachment})
			},
		},
	)
	// 4) add includeInTechPack to metadata
	const { mutate: updateInclude } = useMutation(
		async ({id, attachment}: { id: string, attachment: Attachment }) => {
			return await axios.patch(`/venues/v1/file-attachments/${attachment.id}`, {
				metadata: {
					includedInTechPack: includeFile,
				}
			})
		},
		{
			onSuccess: (_res, {id}) => {
				getNewUrls(id)
			},
		},
	)

	// 5) get preview url to add to new item
	// can get urls in tech pack endpoint
	const { mutate: getNewUrls } = useMutation(
		async (id: string) => {
			return await axios.post(`/venues/v1/files/${id}/url`, {
				disk: "s3",
			})
		},
		{
			onError: handleError,
			onSuccess: async () => {
				//update attachment metadata in state.attachments
				await queryClient.invalidateQueries([`${stage.id}-attachments`])
				toast.success(t("attachmentUpdated"))
				closeModal()
			},
		},
	)

	return (
		<Modal
			open={open}
			onSubmit={() => {
				if (file) {
					setDisableSubmit(true)
					mutateInitUpload()
				} else {
					handleShowDialog()
				}
			}}
			submitLoading={uploadLoading || initialUploadLoading}
			submitText={file ? t("upload") : t("selectFile")}
			onClose={() => {
				setFile(undefined)
				closeModal()
			}}
			showCancel={false}
			submitButtonDisabled={(file && isEntryInvalid(filename)) || disableSubmit}
		>
			{!file ? (
				<Another
					onDragEnter={handleDrag}
					onDragLeave={handleDrag}
					onDragOver={handleDrag}
					onDrop={handleDrop}
				>
					<Container>
						<DialogTitle>{t("addAttachment")}</DialogTitle>
						<ImageContainer onClick={handleShowDialog}>
							<FontAwesomeIcon
								icon={dragActive ? "cloud-arrow-down" : "photo-film"}
								size="6x"
								color={theme.primaryButtonColor}
							/>
						</ImageContainer>
						<p>{t("clickSelectOrDragAndDrop")}</p>
					</Container>
					<input
						ref={fileInputRef}
						type="file"
						style={{ display: "none" }}
						onChange={handleSelectedFile}
					/>
				</Another>
			) : (
				<>
					<SelectedContainer>
						<SelectedLeft>
							<IconContainer>
								<FileIcon filename={file?.name || ""} />
							</IconContainer>
							<p>
								{t("fileSize")}: {formatBytes(file.size)}
							</p>
						</SelectedLeft>
						<SelectedRight>
							<FormField
								error={isEntryInvalid(filename)}
								value={filename}
								label={t("name")}
								inputProps={{ maxLength: 250 }}
								onChange={e => setFilename(e.target.value)}
							/>
							<Checkbox
								label={t("includeInTechPack")}
								checked={includeFile}
								onChange={e => setIncludeFile(e.target.checked)}
							/>
						</SelectedRight>
					</SelectedContainer>
				</>
			)}
		</Modal>
	)
}
const Another = styled.div`
	display: flex;
	justify-content: center;
	overflow: none;
`
const Container = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
`
const ImageContainer = styled.div`
	align-items: center;
	border: 2px solid ${({ theme }) => theme.primaryButtonColor};
	border-radius: 20px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	margin-bottom: 2rem;
	padding: 10%;
`
const SelectedContainer = styled.div`
	display: flex;
	gap: 2rem;
	${mediaQuery("md")`
		align-items: center;
		flex-direction: column;
		gap: 1rem;
	`}
`
const SelectedLeft = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 35%;
	${mediaQuery("md")`
		width: 100%;
	`}
`
const IconContainer = styled.div`
	height: 8rem !important;
	margin-bottom: 2rem;
	width: 8rem !important;
	${mediaQuery("md")`
		height: 30% !important;
		width: 30% !important;
		margin-bottom: 0;
	`}
`
const SelectedRight = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	align-items: start;
`

export default AddAttachmentModal
