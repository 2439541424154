import { createContext, useMemo } from "react"
import Axios, { AxiosError, AxiosInstance } from "axios"
import { toast } from "react-toastify"
import { useTranslation } from "react-i18next"

export const LOCAL_STORAGE_REDIRECT_AFTER_LOGIN = "mv-redirect"

export const AxiosContext = createContext<AxiosInstance>(Axios)

export default function AxiosProvider({ children }: React.PropsWithChildren<unknown>): JSX.Element {
	const { t } = useTranslation()
	const handleBadRequests = (error: AxiosError) => {
		const { status } = error.request
		if (window.location.pathname !== "/login") {
			if (status === 401) {
				//invalid token
				localStorage.setItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN, window.location.pathname)
				window.location.href = "/login"
			} else if (status === 403) {
				//revoked token
				window.location.href = "/login"
			} else if (status === 503) {
				toast.error(t("serverBusy"))
			} else if (!toast.isActive("checkConnection") && status === 0) {
				toast.error(t("checkConnection"), { autoClose: false, toastId: "checkConnection" })
			} else {
				toast.error(t("errorTryAgain"))
			}
		}
	}

	const axios = useMemo(() => {
		const axios = Axios.create({
			headers: {
				"Content-Type": "application/json",
			},
		})

		axios.interceptors.request.use(config => {
			config.baseURL = process.env.REACT_APP_API_URL
			if (!config.headers) {
				config.headers = {}
			}
			const token = localStorage.getItem("token")
			if (token) {
				config.headers.Authorization = `Bearer ${token}`
			} else {
				// there is no token and we are not logging in, redirect
				if (config.url !== "/venues/v1/login/masterVenue") {
					if (window.location.pathname !== "/login") {
						localStorage.setItem(LOCAL_STORAGE_REDIRECT_AFTER_LOGIN, window.location.pathname)
					}
					window.location.href = "/login"
				}
			}

			return config
		}, handleBadRequests)

		axios.interceptors.response.use(response => response, handleBadRequests)

		return axios
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <AxiosContext.Provider value={axios}>{children}</AxiosContext.Provider>
}
