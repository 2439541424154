import { useTranslation } from "react-i18next"
import useAxios from "../hooks/useAxios"
import { camelCase } from "lodash"
import { useQuery } from "@tanstack/react-query"
import { toast } from "react-toastify"
import { useMemo } from "react"

type VenueType = {
	translationKey: string
	translatedValue: string
}

export default function useVenueTypes() {
	const axios = useAxios()
	const { t } = useTranslation()

	const { data = { data: { data: [] } } } = useQuery(
		["venue-types"],
		async () => {
			return await axios.get("/venues/v1/venue-types")
		},
		{
			onError: (error: string) => {
				toast.error(t("errorTryAgain"))
			},
		},
	)

	// Alpha sort by translated value here to ensure all languages have correctly sorted output
	const venueTypes: VenueType[] = useMemo(
		() =>
			data.data.data
				.map((venue: Venue) => {
					const translationKey = camelCase(venue.name)
					return { translationKey, translatedValue: t(translationKey) }
				})
				.sort((a: VenueType, b: VenueType) => a.translatedValue.localeCompare(b.translatedValue)),
		[data.data.data, t],
	)

	return [venueTypes]
}
