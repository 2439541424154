import { FC } from "react"
import { Trans, useTranslation } from "react-i18next"
import styled from "styled-components"
import { Personnel } from "../../../context/personnel"
import useGetVenues from "../../../hooks/useVenues"
import Modal from "../../molecules/Modal"

interface Types {
	showModal: boolean
	onSubmit: () => void
	onClose: () => void
	staff?: Personnel
}

const DeleteStaffModal: FC<Types> = ({ showModal, onClose, onSubmit, staff }) => {
	const { selectedVenue } = useGetVenues()
	const { t } = useTranslation()
	if (!selectedVenue) {
		return null
	}
	return (
		<Modal open={showModal} onClose={onClose} onSubmit={onSubmit} submitText={t("delete")}>
			<Container>
				<Trans
					i18nKey="confirmDeleteFrom"
					values={{
						item: staff?.person.name.displayName,
						from: selectedVenue.name,
					}}
					components={{ b: <b /> }}
					shouldUnescape
				/>
			</Container>
		</Modal>
	)
}

const Container = styled.p`
	font-size: 1.5rem;
	padding-top: 2rem;
	text-align: center;
	width: 100%;
`

export default DeleteStaffModal
