import { createContext, PropsWithChildren, useState, useEffect } from "react"

// courtesty of https://gist.github.com/patrickcze/b0701f4aa9dbaa7e815a0af62c41541e#file-useonlinestatus-tsx

const PING_RESOURCE = "/ping.txt"
const TIMEOUT_TIME_MS = 6000
const onlinePollingInterval = 30000

const timeout = (time: number, promise: Promise<any>) => {
	return new Promise(function (resolve, reject) {
		setTimeout(() => {
			reject(new Error("Request timed out."))
		}, time)
		promise.then(resolve, reject)
	})
}

const checkOnlineStatus = async () => {
	const controller = new AbortController()
	const { signal } = controller

	// If the browser has no network connection return offline
	if (!navigator.onLine) return navigator.onLine

	//
	try {
		await timeout(
			TIMEOUT_TIME_MS,
			fetch(PING_RESOURCE, {
				method: "GET",
				signal,
			}),
		)
		return true
	} catch (error) {
		// Error Log
		console.error(error)

		// This can be because of request timed out
		// so we abort the request for any case
		controller.abort()
	}
	return false
}

export const OnlineStatusContext = createContext(true)

export const OnlineStatusProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
	const [onlineStatus, setOnlineStatus] = useState<boolean>(true)

	const checkStatus = async () => {
		const online = await checkOnlineStatus()
		setOnlineStatus(online)
	}

	useEffect(() => {
		window.addEventListener("offline", () => {
			setOnlineStatus(false)
		})

		// Add polling incase of slow connection
		const id = setInterval(() => {
			checkStatus()
		}, onlinePollingInterval)

		return () => {
			window.removeEventListener("offline", () => {
				setOnlineStatus(false)
			})

			clearInterval(id)
		}
	}, [])

	return (
		<OnlineStatusContext.Provider value={onlineStatus}>{children}</OnlineStatusContext.Provider>
	)
}
