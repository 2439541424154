import HoverSvg from "../components/atoms/HoverSvg"

const Info = ({ color, size }: IconTypes) => {
	const height = size * 1.2307
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 26 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13 28C8.6875 28 4.75 25.75 2.59375 22C0.4375 18.2969 0.4375 13.75 2.59375 10C4.75 6.29688 8.6875 4 13 4C17.2656 4 21.2031 6.29688 23.3594 10C25.5156 13.75 25.5156 18.2969 23.3594 22C21.2031 25.75 17.2656 28 13 28ZM11.125 19.75C10.4688 19.75 10 20.2656 10 20.875C10 21.5312 10.4688 22 11.125 22H14.875C15.4844 22 16 21.5312 16 20.875C16 20.2656 15.4844 19.75 14.875 19.75H14.5V15.625C14.5 15.0156 13.9844 14.5 13.375 14.5H11.125C10.4688 14.5 10 15.0156 10 15.625C10 16.2812 10.4688 16.75 11.125 16.75H12.25V19.75H11.125ZM13 10C12.1562 10 11.5 10.7031 11.5 11.5C11.5 12.3438 12.1562 13 13 13C13.7969 13 14.5 12.3438 14.5 11.5C14.5 10.7031 13.7969 10 13 10Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Info)
