import { ReactNode, useEffect, useState } from "react"
import { ScaleLoader } from "react-spinners"
import styled, { useTheme } from "styled-components"

interface LoadingPageProps {
	children: ReactNode | any
	isLoading: boolean
	initialLoading?: boolean
}

const LoadingPage: React.FC<LoadingPageProps> = ({
	children,
	isLoading,
	initialLoading = true,
}) => {
	const [overrideLoadingState, setOverrideLoadingState] = useState(initialLoading)

	const theme = useTheme()

	useEffect(() => {
		let timer: ReturnType<typeof setTimeout> | null = null

		if (!isLoading && overrideLoadingState) {
			timer = setTimeout(() => setOverrideLoadingState(false), 500)
		}

		return () => {
			if (timer) {
				clearTimeout(timer)
			}
		}
	}, [isLoading, overrideLoadingState])

	return isLoading || overrideLoadingState ? (
		<LoadingContainer>
			<ScaleLoader color={theme.primaryButtonColor} />
		</LoadingContainer>
	) : (
		children
	)
}

const LoadingContainer = styled.div`
	background-color: ${({ theme }) => theme.background};
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
`

export default LoadingPage
