// sort into attachments[category][field]

interface StageAttachments {
	[key: string]: {
		[key: string]: Attachment[]
	}
}

export default function parseStageAttachments(rawAttachments: Attachment[]) {
	const attachments = rawAttachments.reduce((r, attachment) => {
		if (attachment.category) {
			const splitCategory = attachment.category.split(".")

			// check for bad old data
			if (!splitCategory[2] || !attachment.file?.filename) {
				return r
			}

			const field = splitCategory[2]
			const fieldCategory = splitCategory[1]
			r[fieldCategory] = r[fieldCategory] || {}
			r[fieldCategory][field] = r[fieldCategory][field] || []
			r[fieldCategory][field].push(attachment)
		}
		return r
	}, {} as StageAttachments)

	return attachments
}
