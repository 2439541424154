import { Trans, useTranslation } from "react-i18next"
import { FC, useState } from "react"
import Modal from "../../molecules/Modal"
import { DialogTitle } from "@mui/material"
import styled from "styled-components"
import FormField from "../../atoms/FormField"
import useVenues from "../../../hooks/useVenues"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import useAxios from "../../../hooks/useAxios"
import { toast } from "react-toastify"
import { isEntryInvalid } from "../../../util/formValidation"

interface AddModalTypes {
	handleClose: () => void
	handleSubmit: (data: any) => void
	open: boolean
	newStageName: string
	setNewStageName: (newStageName: string) => void
}

const AddStageModal: FC<AddModalTypes> = ({ open, handleSubmit, handleClose }) => {
	const { t } = useTranslation()
	const { selectedVenue } = useVenues()
	const [name, setName] = useState("")
	const axios = useAxios()
	const queryClient = useQueryClient()

	const { mutate } = useMutation(
		() => {
			return axios.post("/venues/v1/venue-stages", {
				name,
				venueId: selectedVenue?.id,
			})
		},
		{
			onError: () => {
				toast.error(t("tryAgain"))
			},
			onSuccess: res => {
				queryClient.invalidateQueries(["venues"])
				setName("")
				handleSubmit(res.data.data)
				handleClose()
			},
		},
	)
	return (
		<Modal
			open={open}
			onClose={handleClose}
			onSubmit={mutate}
			submitText={t("add")}
			showCancel={false}
			submitButtonDisabled={isEntryInvalid(name)}
			maxWidth="60rem"
		>
			<CenterText>
				<DialogTitle>
					<Trans
						i18nKey="addStageConfirmation"
						values={{
							name: selectedVenue?.name || "",
						}}
						components={{ b: <strong /> }}
						shouldUnescape
					/>
				</DialogTitle>
			</CenterText>
			<FormField
				data-cy='venueStageModal-stageNameField'
				label={t("stageName")}
				error={isEntryInvalid(name)}
				fullWidth
				required
				value={name}
				onChange={e => setName(e.target.value)}
			/>
		</Modal>
	)
}

const CenterText = styled.div`
	text-align: center;
	margin: 0 auto;
`

export default AddStageModal
