import parseStageAttachments from "../util/parseStageAttachments"
import { createContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@tanstack/react-query"
import { toast } from "react-toastify"
import useAxios from "../hooks/useAxios"
import useConfirmClose from "../hooks/useConfirmClose"
import useGetVenues from "../hooks/useVenues"
import compare from "../util/compare"
import DEFAULT_STAGE from "../util/constants/defaults/DEFAULT_STAGE"
import DEFAULT_VENUE from "../util/constants/defaults/DEFAULT_VENUE"
import DEFAULT_VENUE_CONTACT from "../util/constants/defaults/DEFAULT_VENUE_CONTACT"
import { formatLatLong } from "../util/latLong"

const { REACT_APP_GOOGLE_API_KEY } = process.env

export const DEFAULT_FILE: FileType = {
	category: "",
	fileId: "",
	filename: "",
	filesize: 0,
	id: "",
	createdDate: "",
	metadata: {
		filename: "",
	},
	publicUrls: {
		urlPreview: "",
	},
}

export const DEFAULT_ATTACHMENT: Attachment = {
	attachedToId: "",
	attachedToType: "",
	category: "",
	file: DEFAULT_FILE,
	fileId: "",
	id: "",
	isDeleted: 0,
	metadata: {
		includedInTechPack: true,
	},
	type: "",
}

const INITIAL_STATE = {
	venue: DEFAULT_VENUE,
	stage: DEFAULT_STAGE,
	venueHasChanges: false,
	stageHasChanges: false,
	disableSaveButton: false,
	isAddVenueContactModalOpen: false,
	isEditVenueContactModalOpen: false,
	editingVenueContact: DEFAULT_VENUE_CONTACT,
	holdEditingVenueContact: DEFAULT_VENUE_CONTACT,
	isDeleteVenueContactModalOpen: false,
	deleteVenueContact: DEFAULT_VENUE_CONTACT,
	addingAttachment: {
		data: "",
		dataCategory: "",
	},
	editingAttachment: {
		data: "",
		dataCategory: "",
		selectedIndex: 0,
	},
	attachments: {},
}

export const ProductionContext = createContext<ProductionContextTypes>({
	state: INITIAL_STATE,
	venueFieldChanges: undefined,
	stageFieldChanges: undefined,
	setState: () => null,
	updateIsLoading: false,
	handleSaveForms: () => null,
	fetchPlacesAPI: () => Promise.resolve(),
	updateVenueContacts: ({ translationKey, contacts }: updateVenueContactsProps) => null,
	isUpdateVenueContactsLoading: false,
	updateVenuePublicNotes: () => null,
	updateVenueUrl: () => null,
	timeStampEnabled: false,
	setTimeStampEnabled: () => null,
	shouldPreventClose: false,
	setShouldPreventClose: () => false,
})

export default function ProductionContextProvider({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element {
	const [state, setState] = useState<ProductionStateInterface>(INITIAL_STATE)
	const axios = useAxios()
	const { t } = useTranslation()
	const { selectedVenue, setSelectedVenue, selectedStage, setSelectedStage } = useGetVenues()
	const { shouldPreventClose, setShouldPreventClose } = useConfirmClose()
	const [timeStampEnabled, setTimeStampEnabled] = useState(false)
	const [venueFieldChanges, setVenueFieldChanges] = useState<VenueChanges | undefined>()
	const [stageFieldChanges, setStageFieldChanges] = useState<StageChanges | undefined>()

	//used for compare()ing diff to show save button
	const [originalVenue, setOriginalVenue] = useState<Venue>(DEFAULT_VENUE)
	const [originalStage, setOriginalStage] = useState<Stage>(DEFAULT_STAGE)

	//used to prevent showing 2 toasts on save venue + stage
	//  can't put in handleSaveForms() because the state isn't updated in onSuccess on time
	const [isSubmitting, setIsSubmitting] = useState(false)

	// handle stage/venue data coming from upstream to set in this context
	useEffect(() => {
		if (!selectedVenue?.id || !selectedStage?.id) {
			return
		}

		if (selectedVenue?.id !== state.venue.id) {
			setState(s => ({
				...s,
				venue: selectedVenue,
				venueHasChanges: false,
			}))
			setOriginalVenue(selectedVenue)
		}

		if (selectedStage?.id !== state.stage.id) {
			setState(s => ({
				...s,
				stage: selectedStage,
				stageHasChanges: false,
			}))
			setOriginalStage(selectedStage)
		}

		// eslint-disable-next-line
	}, [selectedVenue, selectedStage])

	// Retrieve VENUE field change timestamps
	const { mutate: getVenueFieldChanges } = useMutation(
		async () =>
			await axios.post(`/venues/v1/data-change-logs/Venue/${state.venue.id}/field-summary`),
		{
			onError: (error: string) => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: res => {
				if (res && res.data.data) {
					setVenueFieldChanges(res.data.data)
				}
			},
		},
	)
	// Retrieve STAGE field change timestamps
	const { mutate: getStageFieldChanges } = useMutation(
		async () =>
			await axios.post(`/venues/v1/data-change-logs/VenueStage/${state.stage.id}/field-summary`),
		{
			onError: (error: string) => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: res => {
				if (res && res.data.data) {
					setStageFieldChanges(res.data.data)
				}
			},
		},
	)
	useEffect(() => {
		if (state.stage?.id) {
			getVenueFieldChanges()
			getStageFieldChanges()
		}
	}, [getVenueFieldChanges, state.stage?.id, getStageFieldChanges])

	//initialize stage attachments
	const { data } = useQuery(
		[`${state.stage.id}-attachments`],
		() =>
			axios.post(`/venues/v1/attachment/object/venueStage/${state.stage.id}/fileAttachments`, {
				includeUrls: true,
			}),
		{
			enabled: !!state.stage.id,
		},
	)
	useEffect(() => {
		if (data?.data.data) {
			const attachments = parseStageAttachments(data.data.data)
			setState(s => ({ ...s, attachments }))
		}
	}, [data])

	//handle update both venue and stage depending on if form has changed (no venue contacts)
	const { mutate: saveVenue, isLoading: updateIsLoading } = useMutation(
		async () => await axios.put(`/venues/v1/venues/${state.venue?.id}`, state.venue),
		{
			onError: () => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: res => {
				setOriginalVenue(state.venue)
				setSelectedVenue(state.venue)
				toast.success(t("updateSuccessful"))
			},
		},
	)
	const { mutate: saveStage } = useMutation(
		async () => await axios.put(`/venues/v1/venue-stages/${state.stage.id}`, state.stage),
		{
			onError: () => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: _res => {
				setOriginalStage(state.stage)
				setSelectedStage(state.stage)
				if (isSubmitting) {
					setIsSubmitting(false)
				} else {
					toast.success(t("updateSuccessful"))
				}
			},
		},
	)
	const handleSaveForms = () => {
		if (state.venueHasChanges) {
			saveVenue()
			setState(s => ({ ...s, venueHasChanges: false }))
		}
		if (state.stageHasChanges) {
			saveStage()
			setState(s => ({ ...s, stageHasChanges: false }))
		}

		setShouldPreventClose(false)
	}

	//logic block for showing save button
	useEffect(() => {
		if (!state.venue.id || state.venueHasChanges || state.venue.id !== selectedVenue?.id) {
			return
		}
		const { contacts, ...v } = state.venue
		const { contacts: c, ...ve } = originalVenue
		const compareEqualVenue = compare(v, ve)
		setState(s => ({ ...s, venueHasChanges: !compareEqualVenue }))
		// eslint-disable-next-line
	}, [state.venue, selectedVenue])
	useEffect(() => {
		if (!state.stage?.id || state.stageHasChanges || state.stage?.id !== selectedStage?.id) {
			return
		}
		const compareEqualStage = compare(state.stage, originalStage)
		setState(s => ({ ...s, stageHasChanges: !compareEqualStage }))
		// eslint-disable-next-line
	}, [state.stage, selectedStage])
	useEffect(() => {
		if (!shouldPreventClose && (state.venueHasChanges || state.stageHasChanges)) {
			setShouldPreventClose(true)
		}
		if (!isSubmitting && state.venueHasChanges && state.stageHasChanges) {
			setIsSubmitting(true)
		}
	}, [
		state.venueHasChanges,
		state.stageHasChanges,
		setShouldPreventClose,
		shouldPreventClose,
		isSubmitting,
	])

	//logic for hiding save button if changed back to original
	useEffect(() => {
		if (!state.venueHasChanges && !state.stageHasChanges) {
			return
		}

		const { contacts, ...v } = state.venue
		const { contacts: c, ...ve } = originalVenue
		const compareEqualVenue = compare(v, ve)

		const compareEqualStage = compare(state.stage, originalStage)

		if (compareEqualVenue && compareEqualStage) {
			setState(s => ({ ...s, venueHasChanges: false, stageHasChanges: false }))
		} // eslint-disable-next-line
	}, [state.venue, selectedVenue, state.stage, selectedStage])

	//handle lat/long on address change
	const fetchPlacesAPI = async () => {
		//enable save venue/stage button for after api call
		const endPlacesAPICall = () => {
			//form validation to prevent save button from flashing onblur
			if (state.venue.name.trim().length > 0) {
				setState(s => ({ ...s, disableSaveButton: false }))
			}
		}

		//make sure disableSaveButton gets set back to false to not prevent saving form
		try {
			if (!state.venue.id) {
				endPlacesAPICall()
				return
			}

			const { venue } = state
			const address = encodeURI(`${venue.addressLine1},${venue.city},${venue.state}`)

			const data = await (
				await fetch(
					`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${REACT_APP_GOOGLE_API_KEY}`,
				)
			).json()

			if (!data || data.status !== "OK") {
				endPlacesAPICall()
				return
			}

			const { lat: latitude, lng: longitude } =
				data.results[0] && data.results[0]?.geometry.location

			if (
				latitude &&
				longitude &&
				latitude !== state.venue.latitude &&
				longitude !== state.venue.longitude
			) {
				setState(s => ({
					...s,
					venue: {
						...s.venue,
						latitude: formatLatLong(latitude),
						longitude: formatLatLong(longitude),
					},
				}))
			}

			endPlacesAPICall()
		} catch (e) {
			endPlacesAPICall()
		}
	}

	//handle venue.contacts
	const { mutate: updateVenueContacts, isLoading: isUpdateVenueContactsLoading } = useMutation(
		async ({ translationKey, contacts }: updateVenueContactsProps) =>
			await axios.put(`/venues/v1/venues/${state.venue.id}`, {
				contacts,
			}),
		{
			onError: () => {
				toast.error(t("errorTryAgain"))
			},
			onSuccess: (_res, { translationKey, contacts }) => {
				toast.success(t(translationKey))

				setSelectedVenue((venue: Venue) =>
					venue
						? {
								...venue,
								contacts,
						  }
						: null,
				)

				setState(s => ({ ...s, venue: { ...s.venue, contacts } }))
			},
		},
	)

	//edit or create venue.url[label=primary]
	const updateVenueUrl = (value: string) =>
		setState(s => ({
			...s,
			venue: {
				...s.venue,
				urls: Object.keys(s.venue.urls.find(url => url.label === "primary") || {}).length
					? s.venue.urls.map(url => (url.label === "primary" ? { ...url, url: value } : url))
					: [...s.venue.urls, { label: "primary", url: value }],
			},
		}))

	//edit or create venue.notes[label=public]
	const updateVenuePublicNotes = (value: string) =>
		setState(s => ({
			...s,
			venue: {
				...s.venue,
				notes: Object.keys(s.venue?.notes.find(n => n.label === "public") || {}).length
					? s.venue?.notes.map(note => (note.label === "public" ? { ...note, notes: value } : note))
					: [...s.venue.notes, { label: "public", notes: value }],
			},
		}))

	return (
		<ProductionContext.Provider
			value={{
				state,
				venueFieldChanges,
				stageFieldChanges,
				setState,
				updateIsLoading,
				handleSaveForms,
				fetchPlacesAPI,
				updateVenueContacts,
				isUpdateVenueContactsLoading,
				updateVenuePublicNotes,
				updateVenueUrl,
				timeStampEnabled,
				setTimeStampEnabled,
				shouldPreventClose,
				setShouldPreventClose,
			}}
		>
			{children}
		</ProductionContext.Provider>
	)
}
