import HoverSvg from "../components/atoms/HoverSvg"

const Logistics = ({ color, size }: IconTypes) => {
	const height = size * 1.5238
	const width = size

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 21 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.1094 8.35938L13.3594 24.8594C13.0781 25.5156 12.375 25.8906 11.6719 25.75C10.9688 25.6094 10.5 25 10.5 24.25V16H2.25C1.5 16 0.890625 15.5312 0.75 14.8281C0.609375 14.125 0.984375 13.4219 1.64062 13.1406L18.1406 6.39062C18.7031 6.15625 19.3594 6.29688 19.7812 6.71875C20.2031 7.14062 20.3438 7.79688 20.1094 8.35938Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Logistics)
