export const breakpoints = {
	sm: 550,
	md: 787,
	lg: 1023,
	xl: 1300,
}

const mediaQuery = (key: keyof typeof breakpoints) => (style: TemplateStringsArray | string) =>
	`@media (max-width: ${breakpoints[key]}px) { ${style} }`

export default mediaQuery

const heightBreakpoints = {
	sm: 590,
	md: 787,
	lg: 1023,
}

export const mediaQueryHeight =
	(key: keyof typeof heightBreakpoints) => (style: TemplateStringsArray | string) =>
		`@media (max-height: ${heightBreakpoints[key]}px) { ${style} }`
