export const dateFormatToLocale = (date: string): string => {
	if (!date) {
		return ""
	}

	const dateObj = new Date(date)

	// Utility function to add ordinal suffix to day
	const getOrdinalSuffix = (day: number): string => {
		if (day > 3 && day < 21) return "th" // covers 4th to 20th
		switch (day % 10) {
			case 1:
				return "st"
			case 2:
				return "nd"
			case 3:
				return "rd"
			default:
				return "th"
		}
	}

	// Separate options for date and time for custom formatting
	const dateOptions: Intl.DateTimeFormatOptions = {
		year: "numeric",
		month: "short",
		day: "numeric",
	}

	const timeOptions: Intl.DateTimeFormatOptions = {
		hour: "numeric",
		minute: "2-digit",
		hour12: true,
	}

	const day = dateObj.getDate()
	const ordinalSuffix = getOrdinalSuffix(day)

	// Formatting the date and time separately
	let formattedDate = new Intl.DateTimeFormat("en-US", dateOptions).format(dateObj)
	const formattedTime = new Intl.DateTimeFormat("en-US", timeOptions).format(dateObj)

	// Injecting the ordinal suffix
	formattedDate = formattedDate.replace(new RegExp(`(\\d+)(,\\s\\d{4})`), `$1${ordinalSuffix}$2`)

	// Assembling the final output
	return `${formattedDate} at ${formattedTime}`
}
