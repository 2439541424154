export const extractTimeStampData = (
	fieldName: string,
	fieldChangesData: any,
	path: "venue" | "venueStage",
) => {
	if (!fieldChangesData || !(path in fieldChangesData) || !fieldChangesData[path][fieldName]) {
		return
	}

	const fieldData = fieldChangesData[path][fieldName]
	return {
		modifiedBy: fieldData?.user.name.displayName,
		modifiedDate: fieldData?.actionDate,
	}
}
