import HoverSvg from "../components/atoms/HoverSvg"

const Crew = ({ color, size }: IconTypes) => {
	const height = size
	const width = size * 1.25

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 10 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M5 8C3.5625 8 2.25 7.25 1.53125 6C0.8125 4.76562 0.8125 3.25 1.53125 2C2.25 0.765625 3.5625 0 5 0C6.42188 0 7.73438 0.765625 8.45312 2C9.17188 3.25 9.17188 4.76562 8.45312 6C7.73438 7.25 6.42188 8 5 8ZM5 2.5C4.45312 2.5 3.96875 2.79688 3.6875 3.25C3.42188 3.71875 3.42188 4.29688 3.6875 4.75C3.96875 5.21875 4.45312 5.5 5 5.5C5.53125 5.5 6.01562 5.21875 6.29688 4.75C6.5625 4.29688 6.5625 3.71875 6.29688 3.25C6.01562 2.79688 5.53125 2.5 5 2.5Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Crew)
