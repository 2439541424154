import HoverSvg from "../components/atoms/HoverSvg"

const Xmark = ({ color, size }: IconTypes) => {
	const height = size
	const width = size

	return (
		<svg width={width} height={height} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M30.0625 6.15625L20.2188 16L30.0625 25.9375C31.2812 27.0625 31.2812 29.0312 30.0625 30.1562C28.9375 31.375 26.9688 31.375 25.8438 30.1562L16 20.3125L6.0625 30.1562C4.9375 31.375 2.96875 31.375 1.84375 30.1562C0.625 29.0312 0.625 27.0625 1.84375 25.9375L11.6875 16L1.84375 6.15625C0.625 5.03125 0.625 3.0625 1.84375 1.9375C2.96875 0.71875 4.9375 0.71875 6.0625 1.9375L16 11.7812L25.8438 1.9375C26.9688 0.71875 28.9375 0.71875 30.0625 1.9375C31.2812 3.0625 31.2812 5.03125 30.0625 6.15625Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(Xmark)
