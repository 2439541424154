import { useEffect } from "react"

/**
 * value is the placeholder for what is being debounced
 * callback is resuming regular functionality
 */
export default function useDebounce(
	value: any,
	callback: (value: any) => void,
	delay: number = 1000,
) {
	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			callback(value)
		}, delay)

		return () => clearTimeout(delayDebounceFn)
		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value])
}
