import { FC, useCallback, useState } from "react"
import Modal from "./Modal"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Cropper from "react-easy-crop"
import { Point, Area } from "react-easy-crop/types"

interface ImageCropperTypes {
	filePath: string
	handleClose: () => void
	handleSaveFile: any
	open: boolean
	isLoading?: boolean
}

const ImageCropper: FC<ImageCropperTypes> = ({
	filePath,
	handleClose,
	handleSaveFile,
	open,
	isLoading,
}) => {
	const { t } = useTranslation()
	const defaultCrop = {
		x: 25,
		y: 25,
	}
	const [crop, setCrop] = useState<Point>(defaultCrop)
	const [zoom, setZoom] = useState(1)
	const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()

	const onCropComplete = useCallback((croppedArea: any, croppedAreaPixels: Area) => {
		setCroppedAreaPixels(croppedAreaPixels)
	}, [])

	return (
		<Modal
			open={open}
			onClose={handleClose}
			onSubmit={() => {
				handleSaveFile({
					data: croppedAreaPixels,
				})
			}}
			submitText={t("uploadImage")}
			showCancel
			title={t("cropImage")}
			isLoading={isLoading}
		>
			<Container>
				<CropContainer>
					<Cropper
						image={filePath}
						crop={crop}
						zoom={zoom}
						aspect={1}
						cropShape="round"
						showGrid={false}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
					/>
				</CropContainer>
			</Container>
		</Modal>
	)
}
const Container = styled.div`
	height: 30vh;
`
const CropContainer = styled.div`
	height: 100%;
	position: relative;
`

export default ImageCropper
