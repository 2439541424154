import HoverSvg from "../components/atoms/HoverSvg"

const PlayIcon = ({ color, size }: IconTypes) => {
	const height = size
	const width = size * 1.194444

	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 72 86"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.6875 2.3125L67.6875 35.3125C70.3125 37 72 40 72 43C72 46.1875 70.3125 49.1875 67.6875 50.6875L13.6875 83.6875C10.875 85.375 7.3125 85.5625 4.5 83.875C1.6875 82.375 0 79.375 0 76V10C0 6.8125 1.6875 3.8125 4.5 2.3125C7.3125 0.625 10.875 0.625 13.6875 2.3125Z"
				fill={color}
			/>
		</svg>
	)
}

export default HoverSvg(PlayIcon)
