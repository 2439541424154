import styled from "styled-components"
import HistoryFields from "../organisms/history/HistoryFields"
import mediaQuery from "../../util/mediaQuery"

export default function History() {
	return (
		<HistoryContainer>
			<HistoryFields />
		</HistoryContainer>
	)
}

const HistoryContainer = styled.div`
	margin-top: 4rem;
	min-width: 53rem;
	${mediaQuery("lg")`
		min-width: 0;
	`}
`
