import { Outlet, useLocation } from "react-router-dom"
import styled, { useTheme } from "styled-components"
import useUserContext from "../../hooks/useUserContext"
import useVenues from "../../hooks/useVenues"
import mediaQuery from "../../util/mediaQuery"
import MVStar from "../atoms/MVStar"
import VenueSelector from "../molecules/VenueSelector"
import NavDrawer from "../organisms/NavDrawer"
import LoadingPage from "./LoadingPage"

const App = () => {
	const { pathname } = useLocation()
	const { isLoading } = useVenues()
	const { getUserLoading } = useUserContext()
	const theme = useTheme()

	return (
		<LoadingPage isLoading={isLoading || getUserLoading} initialLoading={true}>
			<MainContainer>
				<NavDrawer />
				{pathname !== "/profile" && (
					<>
						<StarContainer>
							<MVStar data-cy="header-MVStarLogo" size={80} />
						</StarContainer>
						<HeaderContainer>
							<SelectorContainer data-cy="header_venueSelect">
								<VenueSelector selectedColor={theme.text} data-cy="header_venueSelectOptions" />
							</SelectorContainer>
						</HeaderContainer>
					</>
				)}
				<Outlet />
			</MainContainer>
		</LoadingPage>
	)
}

const MainContainer = styled.div`
	background: ${({ theme }) => theme.background};
	color: ${({ theme }) => theme.text};
	min-height: 100vh;
	padding: 5rem 8rem;
	overflow: hidden;
	${mediaQuery("md")`
		padding: 5rem 2rem;
	`}
`

const HeaderContainer = styled.div`
	position: absolute;
	top: 60px;
	width: 58%;
	${mediaQuery("md")`
		margin-bottom: 0;
		top: 85px;
		width: 91%;
	`}
	${mediaQuery("sm")`
		width: 88%;
	`}
`

const StarContainer = styled.div`
	position: absolute;
	width: 100%;
	display: flex;
	justify-content: flex-end;
	right: 125px;
	top: 40px;
	${mediaQuery("md")`
		transform: scale(0.60);
		right: -15%;
		top: 10px;
	`}
`

const SelectorContainer = styled.div`
	margin-right: 15px;
	> div > div {
		color: ${({ theme }) => theme.text} !important;
		font-size: 1.5rem;
		font-weight: bold;
		margin-bottom: -0.5rem;
	}
	${mediaQuery("md")`
	width: 99%;
		left: 1.5rem;
	`}
`

export default App
