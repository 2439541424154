import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { IconButton, Tooltip } from "@mui/material"
import styled from "styled-components"

export default function InformationBubble({ title }: { title: string }) {
	return (
		<Tooltip title={title}>
			<IconButton>
				<StyledIcon icon="info-circle" />
			</IconButton>
		</Tooltip>
	)
}

const StyledIcon = styled(FontAwesomeIcon)`
	color: ${({ theme }) => theme.secondaryButtonColor}!important;
`
