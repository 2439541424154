import { FC } from "react"
import styled, { useTheme } from "styled-components"
import Accordion from "./Accordion"

const NavigationRow = ({
	children,
	icon,
	title,
	onClick,
	isMobileNav = false,
	...rest
}: {
	children: NonNullable<React.ReactNode> & React.ReactNode
	icon: FC<HoverSvgProps>
	title: string
	onClick?: () => void
	isMobileNav?: boolean
}) => {
	const theme = useTheme()
	const Icon = icon

	return (
		<Container onClick={onClick} {...rest}>
			<StyledAccordion
				style={{ boxShadow: "none", padding: 0 }}
				title={title}
				icon={<Icon size={24} color={theme.techPackIcon} />}
				defaultExpanded={!isMobileNav}
			>
				{children}
			</StyledAccordion>
		</Container>
	)
}
const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 35px;
	cursor: pointer;
	.MuiPaper-root {
		background-color: ${({ theme }) => theme.techPackSecondaryBackground};
	}
`

const StyledAccordion = styled(Accordion)`
	.MuiAccordionDetails-root {
		padding: 0 !important;
		margin-top: 18px;
		margin-bottom: -30px;
	}
`

export default NavigationRow
