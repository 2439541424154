import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Paper from "../../atoms/Paper"
import Button from "../../atoms/Button"
import AddVenueContactModal from "./AddVenueContactModal"
import DeleteVenueContactModal from "./DeleteVenueContactModal"
import mediaQuery from "../../../util/mediaQuery"
import InformationBubble from "../../molecules/InformationBubble"
import useProduction from "../../../hooks/useProduction"
import DEFAULT_VENUE_CONTACT from "../../../util/constants/defaults/DEFAULT_VENUE_CONTACT"
import TimeStamp from "../../molecules/TimeStamp"

export default function VenueContacts() {
	const { t } = useTranslation()
	const { venueFieldChanges } = useProduction()
	const {
		state: {
			venue: { contacts, id },
		},
		setState,
	} = useProduction()

	const openAddVenueContactModal = () =>
		setState((s: ProductionStateInterface) => ({
			...s,
			isAddVenueContactModalOpen: true,
			editingVenueContact: DEFAULT_VENUE_CONTACT,
		}))

	const openEditVenueContactModal = (contact: VenueContact) =>
		setState((s: ProductionStateInterface) => ({
			...s,
			isEditVenueContactModalOpen: true,
			editingVenueContact: contact,
			holdEditingVenueContact: contact,
		}))

	const openDeleteVenueContactModal = (contact: VenueContact) =>
		setState((s: ProductionStateInterface) => ({ ...s, isDeleteVenueContactModalOpen: true, deleteVenueContact: contact }))

	const extractMostRecentContactData = () => {
		if (!venueFieldChanges?.venue) {
			return null
		}
		// Filter out the keys that start with 'contacts'
		const contactKeys = Object.keys(venueFieldChanges.venue).filter(key =>
			key.startsWith("contacts"),
		)
		if (contactKeys.length === 0) {
			return null
		}
		// Find the most recent contact according to the actionDate
		const mostRecentContactKey = contactKeys.reduce((recentKey, currentKey) => {
			const recentDate = new Date(venueFieldChanges.venue[recentKey].actionDate)
			const currentDate = new Date(venueFieldChanges.venue[currentKey].actionDate)
			return currentDate > recentDate ? currentKey : recentKey
		})
		// Return the modifiedBy and modifiedDate values for the most recent contact
		return {
			modifiedBy: venueFieldChanges.venue[mostRecentContactKey].user.name.displayName,
			modifiedDate: venueFieldChanges.venue[mostRecentContactKey].actionDate,
		}
	}


	return (
		<ContactContainer>
			<TimeStampHeaderContainer>
				<StyledTimeStamp {...extractMostRecentContactData()} marginTop={-10} />
			</TimeStampHeaderContainer>
			<div className="header-container">
				<h3>{t("generalPhonesAndEmails")}</h3>
				<InformationBubble title={t("generalPhonesAndEmailsNotTiedToAnyParticularStaffMember")} />
			</div>

			{contacts.map(contact => (
				<StyledCard key={`venue_contact_${contact?.label})}`} data-cy={`production-VenueContact-Card-${contact.label}`}>
					<div className="contact-details">
						<h4 data-cy={`production-VenueContact-Label-${contact.label}`}>{contact.label}</h4>
						<a href={`tel:${contact.phone}`} data-cy={`production-VenueContact-Phone-${contact.label}`}>{contact.phone} </a>
						<a href={`mailto:${contact.email}`} data-cy={`production-VenueContact-Email-${contact.label}`}>{contact.email}</a>
					</div>
					<div onClick={() => openEditVenueContactModal(contact)}>
						<StyledIcon icon="pencil" data-cy={`production-VenueContact-Edit-${contact.label}`}/>
					</div>
					<div onClick={() => openDeleteVenueContactModal(contact)}>
						<StyledIcon icon="trash-can" data-cy={`production-VenueContact-Delete-${contact.label}`}/>
					</div>
				</StyledCard>
			))}

			<div className="text-center">
				<Button onClick={openAddVenueContactModal} disabled={!id} data-cy="production-VenueContact-AddButton">
					{t("add")}
				</Button>
			</div>

			<AddVenueContactModal />
			<DeleteVenueContactModal />
		</ContactContainer>
	)
}

const TimeStampHeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`

const StyledTimeStamp = styled(TimeStamp)`
	text-align: center;
`

const StyledIcon = styled(FontAwesomeIcon)`
	cursor: pointer;
	color: ${({ theme }) => theme.primaryColor};
	font-size: 1.25rem;
`

const ContactContainer = styled(Paper)`
	background-color: ${({ theme }) => theme.background} !important;
	color: ${({ theme }) => theme.text} !important;
	display: flex;
	flex-direction: column;
	gap: 3rem;
	padding: 2rem;
	min-width: 300px;

	& .text-center {
		text-align: center;
		margin: 0 auto;
	}

	& > .header-container {
		margin-top: -30px;
		margin-bottom: -30px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 1rem;

		& > * {
			margin: 0;
			padding: 0;
		}
	}

	& button {
		${mediaQuery("md")`
			margin-top: 1rem;
		`}
	}

	${mediaQuery("md")`
		justify-content: center;
		align-items: center;
		margin: 0;
		gap: 2rem;

		& > .header-container {
			flex-direction: column;
			h3 {
				text-align: center;
			}
		}
	`}
`

const StyledCard: any = styled.div`
	& h4 {
		margin: 0;
		padding: 0;
	}

	& a {
		color: ${({ theme }) => theme.linkColor};
	}

	.contact-details {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		cursor: pointer;
		flex: 1;

		& a {
			word-break: break-all;
		}
	}

	align-self: start;
	${mediaQuery("md")`
		align-self: center;
	`}

	display: flex;
	gap: 1.5rem;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	& > div {
		display: flex;
		flex-direction: column;
	}
`
