import styled, { useTheme } from "styled-components"
import Xmark from "../../../../icons/Xmark"
import { Dispatch, SetStateAction } from "react"
import Info from "../../../../icons/Info"
import Contact from "../../../../icons/Contact"
import Production from "../../../../icons/Production"
import Facility from "../../../../icons/Facility"
import Equipment from "../../../../icons/Equipment"
import Logistics from "../../../../icons/Logistics"
import NavigationRow from "./NavigationRow"
import EventricLogo from "../../../../logos/Eventric_Logo.jpg"
import EventricLogoDark from "../../../../logos/Eventric_Logo_Dark_Mode.svg"
import useTechPackNavigationData from "../../../../hooks/useTechPackNavigationData"
import { useTranslation } from "react-i18next"
import useDarkMode from "../../../../hooks/useDarkMode"
import LightDarkModeToggle from "./LightDarkModeToggle"
import mediaQuery from "../../../../util/mediaQuery"
import Crew from "../../../../icons/Crew"

const MobileNavigation = ({ setShowNav }: { setShowNav: Dispatch<SetStateAction<boolean>> }) => {
	const theme = useTheme()
	const { darkMode } = useDarkMode()
	const { showFacilities, showPersonnel, showProduction, keysToShow } = useTechPackNavigationData()
	const { t } = useTranslation()
	const handleItemClick = (section: string) => {
		// url hash
		const hash = `#${section.charAt(0).toUpperCase() + section.slice(1)}`
		// get the element
		const element = document.querySelector(hash) || document.querySelector(`#${section}`)
		// scroll to the element
		const yOffset = -150
		if (element) {
			const y = element.getBoundingClientRect().top + window.scrollY + yOffset
			window.scrollTo({ top: y, behavior: "smooth" })
		}
		setShowNav(false)
	}
	return (
		<Main data-cy-component="Component-MobileNavigation">
			<Top>
				<TopBrand>
					<LogoContainer>
						<a
							href="https://www.eventric.com/"
							rel="noreferrer"
							target="_blank"
							data-cy="techPack-header_EventricLogo"
						>
							<Logo src={darkMode ? EventricLogoDark : EventricLogo} alt="Eventric Logo" />
						</a>
					</LogoContainer>
				</TopBrand>
				<XContainer onClick={() => setShowNav && setShowNav(false)}>
					<Xmark size={30} color={theme.techPackTextHeadings} />
				</XContainer>
			</Top>
			<NavigationRow title="venueInfo" icon={Info} onClick={() => handleItemClick(t("venueInfo"))}>
				<></>
			</NavigationRow>
			{showPersonnel && (
				<NavigationRow
					title="contact_plural"
					icon={Contact}
					onClick={() => handleItemClick(t("contact_plural"))}
				>
					<></>
				</NavigationRow>
			)}
			{showProduction && (
				<NavigationRow title="production" icon={Production} isMobileNav>
					{Object.keys(keysToShow.production).map((category: string) => {
						// @ts-ignore
						if (keysToShow.production[category]?.length) {
							return (
								<CategoryContainer key={category}>
									<NavigationCategory
										data-cy="Navigation_Category"
										onClick={() => handleItemClick(category)}
									>
										{t(category)}
									</NavigationCategory>
									<SubcategoryContainer data-cy="Navigation_Subcategory">
										{/* @ts-ignore */}
										{keysToShow.production[category]?.map((item: any) => (
											<NavigationSubcategory key={item} onClick={() => handleItemClick(item)}>
												{t(item)}
											</NavigationSubcategory>
										))}
									</SubcategoryContainer>
								</CategoryContainer>
							)
						} else return null
					})}
				</NavigationRow>
			)}
			{showFacilities && (
				<NavigationRow title="facility_plural" icon={Facility} isMobileNav>
					{Object.keys(keysToShow.facilities).map((category: string) => {
						// @ts-ignore
						if (keysToShow.facilities[category]?.length) {
							return (
								<CategoryContainer key={category}>
									<NavigationCategory onClick={() => handleItemClick(category)}>
										{t(category)}
									</NavigationCategory>
									<SubcategoryContainer>
										{/* @ts-ignore */}
										{keysToShow.facilities[category]?.map((item: any) => (
											<NavigationSubcategory key={item} onClick={() => handleItemClick(item)}>
												{t(item)}
											</NavigationSubcategory>
										))}
									</SubcategoryContainer>
								</CategoryContainer>
							)
						} else return null
					})}
				</NavigationRow>
			)}
			{keysToShow?.equipment?.length ? (
				<NavigationRow title="equipment" icon={Equipment} isMobileNav>
					<CategoryContainer>
						{keysToShow.equipment.map(key => (
							<NavigationCategory key={key} onClick={() => handleItemClick(key)}>
								{t(key)}
							</NavigationCategory>
						))}
					</CategoryContainer>
				</NavigationRow>
			) : null}
			{keysToShow?.logistics?.length ? (
				<NavigationRow title="logistics" icon={Logistics} isMobileNav>
					<CategoryContainer>
						{keysToShow.logistics.map(key => (
							<NavigationCategory key={key} onClick={() => handleItemClick(key)}>
								{t(key)}
							</NavigationCategory>
						))}
					</CategoryContainer>
				</NavigationRow>
			) : null}
			{keysToShow?.localCrew?.length ? (
				<NavigationRow title="localCrew" icon={Crew} isMobileNav>
					<CategoryContainer>
						{keysToShow.localCrew.map(key => (
							<NavigationCategory key={key} onClick={() => handleItemClick(key)}>
								{t(key)}
							</NavigationCategory>
						))}
					</CategoryContainer>
				</NavigationRow>
			) : null}
			<LightDarkModeToggle />
		</Main>
	)
}

const Main = styled.div`
	background-color: ${({ theme }) => theme.techPackSecondaryBackground};
	border: ${({ theme }) => `1px solid ${theme.techPackBorder}`};
	color: ${({ theme }) => theme.techPackText};
	border-radius: 10px;
	padding: 30px;
	min-width: 360px;
	height: 100%;

	* > p {
		margin: 0;
	}
`
const XContainer = styled.div`
	display: flex;
	align-items: center;
`
const Top = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 35px;
`
const TopBrand = styled.div`
	display: flex;
	align-items: center;
	font-size: 22px;
`
const Logo = styled.img`
	width: 290px;
	max-width: 290px;
	height: auto;
	object-fit: contain;

	${mediaQuery("lg")`
		max-width: 190px;
	`}
`
const LogoContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 20px;
`

const CategoryContainer = styled.ul`
	font-size: 18px;
	padding-left: 44px;
`
const NavigationCategory = styled.li`
	list-style-type: circle;
	margin-left: 16px;
	padding-left: 6px;
	padding-top: 8px;
	padding-bottom: 10px;
`
const SubcategoryContainer = styled.li`
	list-style-type: none;
	border-left: 2px solid ${({ theme }) => theme.techPackBorder};
`
const NavigationSubcategory = styled.div`
	padding-top: 8px;
	padding-left: 20px;
	margin-bottom: 10px;
`

export default MobileNavigation
