import { useEffect, useState } from "react"
import SearchIcon from "@mui/icons-material/Search"
import styled from "styled-components"
import mediaQuery from "../../util/mediaQuery"
import { useTranslation } from "react-i18next"
import SearchModal from "./SearchModal"
import useTechPackNavigationData from "../../hooks/useTechPackNavigationData"
import useTechPack from "../../hooks/useTechPack"
import attachmentDisplayName from "../../util/attachmentDisplayName"

const TechPackSearch = ({ ...rest }) => {
	const [searchTerm, setSearchTerm] = useState("")
	const [options, setOptions] = useState<{ label: string; value: string; isSection: boolean }[]>([])
	const { t } = useTranslation()
	const { keysToShow } = useTechPackNavigationData()
	const {
		personnel,
		setShowSearchModal,
		stage,
		attachments: stageAttachments,
		venue,
	} = useTechPack()

	const getSectionTitles = (data: any) => {
		const result: { label: string; value: string; isSection: boolean }[] = []
		const order = ["production", "facilities", "equipment", "logistics", "localCrew"]
		for (const category of order) {
			// data.production | data.equipment
			if (data[category]) {
				if (Array.isArray(data[category])) {
					// we are in equipment, logistics, localCrew
					if (data[category].length) {
						result.push({ label: t(category), value: category, isSection: true })
						// eslint-disable-next-line no-loop-func
						data[category].forEach((item: string) => {
							result.push({ label: `${t(category)} > ${t(item)}`, value: item, isSection: true })
							const data = stage[`${category}Data` as keyof Stage]

							// we are in equipmentData, logisticsData, localCrewData
							// lets add the data after the name
							if (data && data[item as keyof typeof data]) {
								result.push({
									label: data[item as keyof typeof data],
									value: item,
									isSection: false,
								})
							}

							// get attachment title for section
							const attachments: StageAttachment[] =
								(stageAttachments[`${category}Data`] &&
									stageAttachments[`${category}Data`][item]) ||
								[]
							if (attachments.length) {
								attachments.forEach(attachment => {
									result.push({
										label: attachmentDisplayName(attachment.file),
										value: item,
										isSection: false,
									})
								})
							}
						})
					}
				} else {
					let shouldAddCategory = false
					const subData = []
					// we are in production, facilities
					// we are using shouldAddCategory to avoid adding duplicate categories, we don't want to add production twice
					// we aslo don't want to add the category unless it has subcategories and items in it

					// eslint-disable-next-line no-loop-func
					Object.keys(data[category]).forEach(subCategory => {
						if (data[category][subCategory].length) {
							shouldAddCategory = true
							// we are in production.loading || facilities.parking
							subData.push({
								label: `${t(category)} > ${t(subCategory)}`,
								value: subCategory,
								isSection: true,
							})
							data[category][subCategory].forEach((item: keyof typeof data) => {
								subData.push({
									label: `${t(category)} > ${t(subCategory)} > ${t(item)}`,
									value: item,
									isSection: true,
								})
								// get attachment titles for section
								const attachments: Attachment[] =
									(stageAttachments[`${category}Data`] &&
										stageAttachments[`${category}Data`][item]) ||
									[]
								if (attachments.length) {
									attachments.forEach(attachment => {
										subData.push({
											label: attachmentDisplayName(attachment.file),
											value: item,
											isSection: false,
										})
									})
								}
								const data = stage[`${category}Data` as keyof Stage]
								if (data && data[item]) {
									if (typeof data[item] !== "string") {
										// we are in dimensions or proscenium
										Object.keys(data[item]).forEach(subItem => {
											if (data[item][subItem]) {
												subData.push({
													label: `${t(subItem)} > ${data[item][subItem]}`,
													value: item,
													isSection: false,
												})
											}
										})
									} else if (data[item]) {
										subData.push({
											label: t(data[item]),
											value: item,
											isSection: false,
										})
									}
								}
							})
						}
					})
					if (shouldAddCategory) {
						subData.unshift({
							label: t(category),
							value: t(category)?.toLowerCase().replace(/ /g, ""),
							isSection: true,
						})
					}
					result.push(...subData)
				}
			}
		}

		return result
	}

	const getContactOptions = () => {
		return personnel.map(person => {
			const {
				role,
				person: {
					phone,
					email,
					name: { displayName },
				},
			} = person
			return {
				label: `${displayName} ${role ? `(${role})` : ""} ${phone ? phone : ""} ${
					email ? email : ""
				}`,
				value: displayName.replace(/ /g, ""),
				isSection: false,
			}
		})
	}

	const getGeneralNotes = () => {
		const result: { label: string; value: string; isSection: boolean }[] = []
		const publicNotes = (venue.notes.find(note => note.label === "public") || { notes: "" }).notes
		if (publicNotes) {
			result.push({
				label: t("notes"),
				value: "notes",
				isSection: true,
			})
			result.push({
				label: publicNotes,
				value: "notes",
				isSection: false,
			})
		}
		return result
	}

	useEffect(() => {
		let options = [{ label: "", value: "", isSection: false }]
		if (searchTerm !== "") {
			options = [...getGeneralNotes(), ...getSectionTitles(keysToShow), ...getContactOptions()]
		}
		setOptions(options)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm])

	return (
		<Container>
			<SearchModal searchTerm={searchTerm} options={options} setSearchTerm={setSearchTerm} />
			<SearchPlaceholder onClick={() => setShowSearchModal(true)} {...rest}>
				<PlaceholderContainer>
					<StyledSearchIcon />
					<StyledSearchText>{t("search")}</StyledSearchText>
				</PlaceholderContainer>
			</SearchPlaceholder>
		</Container>
	)
}

const Container = styled.div`
	width: 100%;
	margin: 0px 30px;

	${mediaQuery("md")`
		display: none;
	`}
`
const SearchPlaceholder = styled.div`
	display: flex;
	align-items: center;
	height: 50px;
	background-color: ${({ theme }) => theme.techPackPrimaryBackground};
	border-radius: 28px;
	cursor: pointer;
	min-width: 140px;
	border: 1px solid #545f70;
`
const PlaceholderContainer = styled.div`
	display: flex;
	align-items: center;
	height: 80px;
	color: ${({ theme }) => theme.techPackSearchText} !important;
	font-size: 16px;
	padding-left: 10px;
`
const StyledSearchText = styled.p`
	margin-left: 14px;
	font-size: 16px !important;
`
const StyledSearchIcon = styled(SearchIcon)`
	font-size: 20px !important;
	color: ${({ theme }) => theme.techPackSearchText} !important;
	margin-left: 5px !important;
`

export default TechPackSearch
