import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { FC, useState } from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import mediaQuery from "../../../util/mediaQuery"

interface FieldNamesDropdownType {
	users: User[]
	setFilter: React.Dispatch<React.SetStateAction<HistoryFilter>>
}

const UserNamesDropdown: FC<FieldNamesDropdownType> = ({ setFilter, users }) => {
	const [selected, setSelected] = useState("")

	const selectionChangeHandler = (e: any) => {
		setSelected(e.target.value)
		setFilter((state: HistoryFilter) => ({
			...state,
			userId: e.target.value,
		}))
	}

	const handleClear = () => {
		setSelected("")
		setFilter((state: any) => ({
			...state,
			userId: undefined,
		}))
	}
	const { t } = useTranslation()

	return (
		<MainContainer>
			<StyledFormControl variant="outlined">
				{!selected ? (
					<Label shrink={false} id="test-select-label">
						<em>{t("username")}</em>
					</Label>
				) : null}
				<StyledSelect
					notched={false}
					label="User Name"
					labelId="test-select-label"
					value={selected}
					onChange={selectionChangeHandler}
					// Gets rid of UI bug with window scroll bar
					MenuProps={{ disableScrollLock: true }}
				>
					{users.map((username, i) => (
						<Item key={`select_${username.id}_${i}`} value={username.id}>
							{t(username.displayName)}
						</Item>
					))}
				</StyledSelect>
			</StyledFormControl>
			<StyledButton onClick={handleClear}>{t("clear")}</StyledButton>
		</MainContainer>
	)
}

export default UserNamesDropdown

const MainContainer = styled.div`
	min-width: 360px;
`

const StyledFormControl = styled(FormControl)`
	width: 300px;
	margin-bottom: 20px;
	${mediaQuery("sm")`
		width: 220px;
		`}
`
const Label = styled(InputLabel)`
	color: ${({ theme }) => theme.text} !important;
`

const StyledSelect = styled(Select)`
	font-size: 1rem !important;
	font-weight: bold;
	background-color: ${props => props.theme.background} !important;
	color: ${({ theme }) => theme.text} !important;

	> svg {
		color: ${props => props.theme.text} !important;
	}
`
const Item = styled(MenuItem)`
	color: ${({ theme }) => theme.text} !important;
	background-color: ${({ theme }) => theme.background} !important;
`

const StyledButton = styled.button`
	background-color: ${({ theme }) => theme.cardColor} !important;
	border: none;
	color: ${({ theme }) => theme.primaryButtonColor};
	padding: 10px 10px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 14px;
	margin: 6px 0;
	cursor: pointer;
`
