import useConfirmClose from "../hooks/useConfirmClose"
import { createContext } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { toast } from "react-toastify"
import { StringLiteral } from "typescript"
import useAxios from "../hooks/useAxios"
import useGetVenues from "../hooks/useVenues"

export interface Personnel {
	id: string
	type: string
	attachedToType: Venue
	attachedToId: string
	role: string
	personId: string
	companyId: StringLiteral
	createdDate: Date
	createdBy: number
	modifiedDate: Date
	modifiedBy: number
	isDeleted: number
	metadata: {
		includedInTechPack: boolean
		order: number
	}
	person: {
		id: string
		name: {
			displayName: string
		}
		email: string
		phone: string
		metadata: string
		claimedByUserId: string
		userProfileId: string
		searchVisibility: string
		createdDate: Date
		createdBy: number
		modifiedDate: Date
		modifiedBy: number
		isDeleted: number
	}
	company: {
		id: string
		name: {
			displayName: string
		}
		metadata: string
		claimedByUserId: string
		searchVisibility: string
		createdDate: Date
		createdBy: number
		modifiedDate: Date
		modifiedBy: number
		isDeleted: number
	}
	imageUrl?: string
}

export interface PersonnelContextType {
	isLoading: boolean
	personnel: Personnel[]
	shouldPreventClose: boolean
	setShouldPreventClose: (shouldPreventClose: boolean) => void
	refetch: () => void
}

export const PersonnelContext = createContext<PersonnelContextType>({
	isLoading: true,
	personnel: [],
	shouldPreventClose: false,
	setShouldPreventClose: () => null,
	refetch: () => null,
})

export const PersonnelContextProvider = ({
	children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
	const { selectedVenue } = useGetVenues()
	const axios = useAxios()
	const { t } = useTranslation()
	const { shouldPreventClose, setShouldPreventClose } = useConfirmClose()

	const { data, isLoading, refetch } = useQuery(
		[`personnel-${selectedVenue?.id}`],
		() => {
			return axios.post(`/venues/v1/personnel/general/Venue/${selectedVenue!.id}`)
		},
		{
			enabled: !shouldPreventClose && !!selectedVenue?.id,
			onError: (error: string) => {
				toast.error(t("errorTryAgain"))
			},
		},
	)

	return (
		<PersonnelContext.Provider
			value={{
				personnel: data?.data?.data || [],
				isLoading,
				shouldPreventClose,
				setShouldPreventClose,
				refetch,
			}}
		>
			{children}
		</PersonnelContext.Provider>
	)
}
