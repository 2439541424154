import React, { FC } from "react"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import FormControl from "@mui/material/FormControl"
import InputLabel from "@mui/material/InputLabel"
import { Popper, PopperProps, Theme } from "@mui/material"
import styled, { useTheme } from "styled-components"

interface Item {
	id: string
	name: string
}

type SelectTypes = {
	backgroundColor?: string
	items: Item[]
	label?: string
	labelPadding?: string
	onChange: (value: string) => void
	onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void
	selected: string | undefined
	selectedColor?: string
	textColor?: string
	title?: string
	placeholder?: string
	padding?: string
}

const CustomPopper = (props: PopperProps) => {
	return <Popper {...props} style={{ width: "inherit" }} placement="bottom-start" />
}

interface StyledFormControlProps {
	padding?: string
	title?: string
}

interface StyledInputLabelProps {
	labelPadding?: string
	textColor?: string
}

interface TypeFilterProps {
	theme: Theme
	textColor?: string
	backgroundColor?: string
}

const FilteredSelect: FC<SelectTypes> = ({
	backgroundColor,
	items,
	onChange,
	onBlur,
	selected,
	label,
	labelPadding,
	selectedColor,
	textColor,
	title,
	placeholder,
	padding,
	...rest
}) => {
	const theme = useTheme()

	return (
		<StyledFormControl
			variant="standard"
			padding={padding}
			title={title}
			data-cy-component="Component-Select"
			fullWidth
		>
			{label && (
				<StyledInputLabel
					variant="standard"
					shrink
					textColor={textColor || theme.text}
					labelPadding={labelPadding}
				>
					{label}
				</StyledInputLabel>
			)}
			<Autocomplete
				options={items}
				fullWidth
				getOptionLabel={(option: Item) => option.name}
				value={items.find(item => item.id === selected) || null}
				onChange={(event: any, newValue: Item | null) => {
					onChange(newValue ? newValue.id : "")
				}}
				onBlur={onBlur}
				PopperComponent={CustomPopper}
				renderInput={params => (
					<TypeFilter
						{...params}
						fullWidth
						variant="standard"
						textColor={textColor}
						backgroundColor={backgroundColor}
					/>
				)}
				data-cy-component="Component-Select_Autocomplete"
				ListboxProps={{
					// @ts-ignore
					sx: {
						"& .MuiAutocomplete-option": {
							color: textColor || theme.text,
							backgroundColor: `${backgroundColor || theme.textNavigationSelectBackground} !important`,
							"&:hover": {
								backgroundColor: "rgba(255, 255, 255, 0.1) !important",
							},
						},
						backgroundColor: `${backgroundColor || theme.textNavigationSelectBackground} !important`,
					},
				}}
				{...rest}
			/>
		</StyledFormControl>
	)
}

const StyledFormControl = styled(FormControl)<StyledFormControlProps>`
	&& {
		padding-top: ${({ padding }) => padding || "0"};
		padding-bottom: ${({ padding }) => padding || "0"};
		width: 100%;
	}
`
const StyledInputLabel = styled(InputLabel)<StyledInputLabelProps>`
	&& {
		color: ${({ textColor, theme }) => textColor || theme.text};
		position: absolute;
		top: ${({ labelPadding }) => labelPadding || "0"};
		left: 0;
	}
`
const TypeFilter = styled(TextField)<TypeFilterProps>`
	&& {
		.MuiInputBase-root {
			color: ${({ theme, textColor }) => (textColor ? textColor : theme.text)};

			& .MuiInputBase-input::placeholder {
				color: ${({ theme, textColor }) => (textColor ? textColor : theme.text)} !important;
				opacity: 1; // Ensure placeholder is fully visible
			}
		}

		.MuiInput-underline:after,
		.MuiInput-underline:before {
			border-bottom: 1px solid ${({ theme, textColor }) => (textColor ? textColor : theme.text)} !important;
		}

		.MuiInputBase-input::placeholder {
			${({ theme, textColor }) => (textColor ? textColor : theme.text)} !important;
			opacity: 1;
		}

		// Since you're using the theme from styled-components, adjust as needed
		.MuiAutocomplete-option {
			color: ${({ textColor, theme }) => textColor || theme.text};
			background-color: ${({ backgroundColor, theme }) =>
				backgroundColor || theme.textNavigationSelectBackground} !important;

			&:hover {
				background-color: rgba(255, 255, 255, 0.1) !important;
			}
		}

		.MuiAutocomplete-clearIndicator,
		.MuiAutocomplete-popupIndicator {
			color: ${({ theme }) => theme.text};
		}

		.MuiInput-underline:before {
			border-bottom-color: ${({ theme }) => theme.text};
		}

		.MuiInput-underline:after {
			border-bottom-color: ${({ theme }) => theme.text};
		}
	}
`

export default FilteredSelect
