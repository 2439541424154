import { useEffect, useRef } from "react"

/**
 * useful for adding submitRef to enter button to submit form on keydown "Enter"
 *   instead of attaching to whole form
 */

export default function useSubmitWithRef(onSubmit: () => void) {
	const submitRef = useRef(null)

	const checkSubmit = (e: KeyboardEvent) => {
		if (e.key === "Enter") {
			if (document.activeElement === submitRef?.current) {
				onSubmit()
			}
		}
	}

	useEffect(() => {
		window.addEventListener("keydown", checkSubmit)
		return () => window.removeEventListener("keydown", checkSubmit)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return [submitRef]
}
