import { useState, useEffect, FC } from "react"
import FormField from "../atoms/FormField"
import Button from "../atoms/Button"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import useUserContext from "../../hooks/useUserContext"
import Select from "../atoms/Select"
import useSubmitWithRef from "../../hooks/useSubmitWithRef"
import compare from "../../util/compare"
import useConfirmClose from "../../hooks/useConfirmClose"
import usePreviousValue from "../../hooks/usePreviousValue"
import { isEntryInvalid } from "../../util/formValidation"

const ProfileForm: FC = () => {
	const { t } = useTranslation()
	const { user, saveUser, userContextIsLoading } = useUserContext()

	const [holdUser, setHoldUser] = useState(user)

	const [firstName, setFirstName] = useState(user.firstName)
	const [lastName, setLastName] = useState(user.lastName)
	const [phone, setPhone] = useState(user.metadata.phone)
	const [nickName, setNickName] = useState(user.nickName)
	const preferredNamesOptions = [
		firstName,
		lastName,
		nickName,
		`${firstName} ${lastName}`,
		`${lastName} ${firstName}`,
		nickName ? `${nickName} ${lastName}` : "",
	]
	const [preferredName, setPreferredName] = useState(user.preferredName)
	const { shouldPreventClose, setShouldPreventClose } = useConfirmClose()
	const isFirstNameInvalid = isEntryInvalid(firstName)
	const isLastNameInvalid = isEntryInvalid(lastName)
	const isPhoneInvalid = isEntryInvalid(phone || "")
	const disableSubmit =
		isFirstNameInvalid || isLastNameInvalid || isPhoneInvalid || userContextIsLoading

	//handle preferred name when a field gets edited
	const [previousPreferredName] = usePreviousValue(preferredName)
	const [previousPreferredNamesOptions] = usePreviousValue(preferredNamesOptions)
	useEffect(() => {
		if (preferredNamesOptions.indexOf(preferredName) === -1) {
			if (previousPreferredName === undefined || previousPreferredNamesOptions === undefined) {
				return
			}

			// @ts-ignore
			const lastIndex = previousPreferredNamesOptions.indexOf(previousPreferredName)
			setPreferredName(preferredNamesOptions[lastIndex])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstName, lastName, nickName])

	useEffect(() => {
		const isEqual = compare(holdUser, user)
		if (!isEqual && !shouldPreventClose) {
			setShouldPreventClose(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstName, lastName, phone, nickName])

	//user clicks save or keypress enter
	const handleSubmit = () => {
		if (disableSubmit) {
			return
		}

		setHoldUser({
			firstName,
			lastName,
			nickName,
			preferredName,
			metadata: { phone, email: user.metadata.email },
			userId: user.userId,
		})

		saveUser({
			firstName,
			lastName,
			nickName,
			preferredName,
			phone,
		})

		setShouldPreventClose(false)
	}

	const [submitRef] = useSubmitWithRef(() => handleSubmit())

	return (
		<FormContainer>
			<h4>{t("contactInformation")}</h4>
			<FormField
				data-cy="profile-EmailField"
				variant="standard"
				label={t("email")}
				type="text"
				id="email"
				fullWidth
				disabled
				value={user?.metadata.email || ""}
			/>
			<FormField
				data-cy="profile-FirstNameField"
				error={isFirstNameInvalid}
				variant="standard"
				placeholder={t("firstName")}
				label={t("firstName")}
				type="text"
				required
				id="firstName"
				fullWidth
				value={firstName}
				onChange={e => setFirstName(e.target.value)}
			/>
			<FormField
				data-cy="profile-LastNameField"
				error={isLastNameInvalid}
				variant="standard"
				placeholder={t("lastName")}
				label={t("lastName")}
				type="text"
				required
				id="lastName"
				fullWidth
				value={lastName}
				onChange={e => setLastName(e.target.value)}
			/>
			<FormField
				// error={nickName.length < 1}
				data-cy="profile-NicknameField"
				variant="standard"
				placeholder={t("nickname")}
				label={t("nickname")}
				type="text"
				// required
				id="nickName"
				fullWidth
				value={nickName}
				onChange={e => setNickName(e.target.value)}
			/>
			<Select
				data-cy="profile-PreferredNameField"
				label={t("preferredName")}
				items={preferredNamesOptions.map((n, i) => ({
					id: n,
					name: n,
				}))}
				onChange={e => setPreferredName(e.target.value)}
				selected={preferredNamesOptions.indexOf(preferredName) === -1 ? "" : preferredName}
			/>
			<FormField
				data-cy="profile-PhoneNumberField"
				error={isEntryInvalid(phone || "")}
				variant="standard"
				placeholder={t("phoneNumber")}
				label={t("phoneNumber")}
				type="text"
				required
				id="phone"
				fullWidth
				value={phone || ""}
				onChange={e => setPhone(e.target.value)}
			/>
			<div className="button-container">
				<Button
					data-cy="profile-SaveButton"
					ref={submitRef}
					color="primary"
					variant="contained"
					onClick={handleSubmit}
					className="default-button"
					loading={userContextIsLoading}
					disabled={disableSubmit}
				>
					{t("save")}
				</Button>
			</div>
		</FormContainer>
	)
}

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	& .button-container {
		align-self: center;
		margin-top: 34px;
	}
`
export default ProfileForm
