import { Box, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import mediaQuery from "../../util/mediaQuery"
import Button from "../atoms/Button"
import FormField from "../atoms/FormField"
import Card from "../atoms/Card"
import { toast } from "react-toastify"
import PasswordChecklist, { RuleNames } from "react-password-checklist"
import { useMutation } from "@tanstack/react-query"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { KeyboardEvent } from "react"
import LoadingPage from "../views/LoadingPage"
import axios from "axios"
import { getAnalytics, logEvent } from "firebase/analytics"

// This is the step AFTER 'reset password has been submitted'

const { REACT_APP_API_URL } = process.env

interface RuleTypes {
	match: boolean
	uppercase?: boolean
	lowercase?: boolean
	number?: boolean
	symbol?: boolean
	minLength?: number
}

const ChangePassword: React.FC = () => {
	const [password, setPassword] = useState("")
	const [confirmPassword, setConfirmPassword] = useState("")
	const [isPassValid, setIsPassValid] = useState(false)
	const [rules, setRules] = useState<RuleTypes>({ match: true })
	const { t } = useTranslation()
	const params = useParams()
	const navigate = useNavigate()
	const { code } = params
	const location = useLocation()
	const analytics = getAnalytics()

	useEffect(() => {
		logEvent(analytics, "page_view", {
			page_title: "Update Password",
			page_location: window.location.href,
			page_path: location.pathname,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	const { mutate: getRules, isLoading: isLoadingRules } = useMutation(
		() => {
			return axios.post(`${REACT_APP_API_URL}/venues/v1/password/reset/validate`, { code })
		},
		{
			onSuccess: res => {
				const { data } = res.data
				const newRules = Object.keys(data.passwordRequirements).reduce(
					(obj, value: string) => {
						if (value === "uppercase" && data.passwordRequirements[value]) {
							return { ...obj, capital: true }
						} else if (value === "lowercase" && data.passwordRequirements[value]) {
							return { ...obj, lowercase: true }
						} else if (value === "number" && data.passwordRequirements[value]) {
							return { ...obj, number: true }
						} else if (value === "symbol" && data.passwordRequirements[value]) {
							return { ...obj, specialChar: true }
						} else if (value === "length" && data.passwordRequirements[value]) {
							return { ...obj, minLength: data.passwordRequirements[value] }
						} else return obj
					},
					{ match: true },
				)
				setRules(newRules)
			},
			onError: (err: any) => {
				// Handles invalid code or expired code
				if (err.response.status === 400) {
					toast.error(t("passwordResetLinkExpired"), { toastId: "error-toast" })
					goToLogin()
				} else {
					toast.error(t("tryAgain"), { toastId: "error-toast" })
				}
			},
		},
	)

	useEffect(() => {
		// get password rules
		code && getRules()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [code])

	const { mutate: updatePassword, isLoading: isLoadingUpdate } = useMutation(
		async () => {
			return await axios.post(`${REACT_APP_API_URL}/venues/v1/password/reset/complete`, {
				code,
				password,
				passwordConfirmation: confirmPassword,
			})
		},
		{
			onSuccess: () => {
				setPassword("")
				setConfirmPassword("")
				toast.success(t("credentialsInvalid"))
				goToLogin()
			},

			onError: err => {
				console.error({ err })
				toast.error(t("errorTryAgain"))
			},
		},
	)

	const goToLogin = () => {
		navigate("/login")
	}

	const isLoading = isLoadingRules

	return (
		<LoadingPage isLoading={isLoading} initialLoading={true}>
			<PageContainer className="changePassword-background">
				<Grid item />
				<BackgroundContainer>
					<PasswordFormContainer>
						<Box
							component="form"
							onKeyDown={(e: KeyboardEvent) => {
								if (e.key === "Enter") {
									e.preventDefault()
									password && confirmPassword && !isLoading && updatePassword()
								}
							}}
						>
							<Grid item>
								<h3>{t("resetPassword")}</h3>
								<FormField
									style={{ marginBottom: "10px" }}
									variant="standard"
									label={t("password")}
									type="password"
									required
									id="passwordTextField"
									fullWidth
									value={password}
									onChange={e => setPassword(e.target.value)}
								/>
							</Grid>
							<Grid item>
								<FormField
									variant="standard"
									label={t("confirmPassword")}
									type="password"
									required
									id="confirmPasswordTextField"
									fullWidth
									value={confirmPassword}
									onChange={e => setConfirmPassword(e.target.value)}
								/>
							</Grid>

							<StyledChecklist
								rules={Object.keys(rules) as RuleNames[]}
								minLength={rules.minLength ? rules.minLength : undefined}
								value={password}
								valueAgain={confirmPassword}
								onChange={setIsPassValid}
								iconSize={17}
								messages={{
									capital: t("passwordMustContainCapitalLetter"),
									lowercase: t("passwordMustContainLowercaseLetter"),
									match: t("passwordMustMatch"),
									minLength: t("passwordMustBeAtLeastCharacters", { count: rules.minLength }),
									number: t("passwordMustContainNumber"),
									specialChar: t("passwordMustContainSpecialCharacter"),
								}}
							/>

							<StyledButton
								fullWidth
								color="primary"
								variant="contained"
								onClick={() => updatePassword()}
								disabled={!isPassValid || isLoadingUpdate}
							>
								{t("update")}
							</StyledButton>
						</Box>
					</PasswordFormContainer>
				</BackgroundContainer>
			</PageContainer>
		</LoadingPage>
	)
}

const PasswordFormContainer = styled(Card)`
	padding-left: 8%;
	padding-right: 8%;
	padding-top: 15%;
	padding-bottom: 15%;
	text-wrap: wrap;
	overflow: hidden;
	width: 300px;
`

const BackgroundContainer = styled(Grid)`
	display: flex;
	justify-content: right;
	padding-right: 3%;
	padding-left: 3%;
	background-color: ${({ theme }) => theme.primaryColor};
	height: 100vh;
	align-items: center;
	box-shadow: 2px 3px 12px 6px rgba(0, 0, 0, 0.14);
	${mediaQuery("sm")`
		width:  550px;
		justify-content: center;
	`}
`

const PageContainer = styled(Grid)`
	display: flex;
	justify-content: right;
	flex-direction: row;
	flex-wrap: no-wrap;
	align-content: stretch;
	background-color: ${({ theme }) => theme.body};
	height: 100vh !important;
	background-size: 40vh;
	background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 418 765'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:none%3B%7D.cls-2%7Bfill:%23e8eff7%3B%7D.cls-3%7Bopacity:.3%3B%7D%3C/style%3E%3C/defs%3E%3Crect class='cls-1' width='418' height='765'/%3E%3Cg class='cls-3'%3E%3Cpath class='cls-2' d='M97 204h0c53.6 0 97 43.4 97 97v171c0 53.6-43.4 97-97 97h0c-53.6 0-97-43.4-97-97v-171c0-53.6 43.4-97 97-97Z'/%3E%3Cpath class='cls-2' d='M0 765v-86c0-53.6 43.4-97 97-97h0c53.6 0 97 43.4 97 97v86'/%3E%3Cpath class='cls-2' d='M194 0V94c0 53.6-43.4 97-97 97h0C43.4 191 0 147.6 0 94V0'/%3E%3Cpath class='cls-2' d='M305 8.5h0c53.6 0 97 43.4 97 97v171c0 53.6-43.4 97-97 97h0c-53.6 0-97-43.4-97-97V105.5c0-53.6 43.4-97 97-97Z'/%3E%3Cpath class='cls-2' d='M305 400h0c53.6 0 97 43.4 97 97v171c0 53.6-43.4 97-97 97h0c-53.6 0-97-43.4-97-97v-171c0-53.6 43.4-97 97-97Z'/%3E%3C/g%3E%3C/svg%3E");
	background-repeat: repeat;
`
const StyledButton: typeof Button = styled(Button)`
	margin-top: 1rem !important;
`
const StyledChecklist = styled(PasswordChecklist)`
	margin-top: 1rem !important;
	font-size: 0.8rem;
	svg {
		flex: none !important;
	}
`

export default ChangePassword
